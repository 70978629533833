import React from 'react';
import queryString, { ParsedQuery } from 'query-string';

import {
	S3_AVATAR_CDN_URL,
	S3_UPLOADS_CDN_URL,
	USE_IMGIX,
	AVATAR_CDN_URL,
	ImageProps,
	OLD_S3_UPLOADS_CDN_URL,
} from '.';

interface ImageTemplateProps
	extends Pick<
		ImageProps,
		| 'alt'
		| 'aspectRatio'
		| 'cdnUrl'
		| 'className'
		| 'crop'
		| 'draggable'
		| 'format'
		| 'height'
		| 'imgRef'
		| 'onError'
		| 'onLoad'
		| 'quality'
		| 'src'
		| 'width'
	> {}

interface ImageTemplateComponent {
	(props: ImageTemplateProps): JSX.Element;
}

type OptionsImage = {
	src: string;
	cdnUrl: string;
	width?: number;
	aspectRatio?: number;
	devicePixelRatio?: number;
	format?: ImageProps['format'];
	height?: number;
	crop?: boolean;
	quality?: number;
};

/**
 * Parse params object and build final Url
 */
const buildImgUrl = (cdnUrl: string, src: string, params: ParsedQuery) =>
	queryString.stringifyUrl(
		{
			url: `${cdnUrl}${src}`,
			query: params,
		},
		{ skipNull: true }
	);

/**
 * Transform Image params into imgix custom parameters
 */
export const getImageUrl = ({
	src,
	cdnUrl,
	width,
	aspectRatio,
	devicePixelRatio,
	format,
	height,
	crop,
	quality,
}: OptionsImage) => {
	if (src.startsWith('data:')) {
		return src;
	}

	const isAvatarCdn = cdnUrl === AVATAR_CDN_URL;

	const { url, query } = queryString.parseUrl(
		// Clean dirty base urls from the database extracting the path from it
		src
			.replace(S3_AVATAR_CDN_URL, '')
			.replace(S3_UPLOADS_CDN_URL, '')
			.replace(OLD_S3_UPLOADS_CDN_URL, '')
	);

	if (USE_IMGIX) {
		Object.assign(query, {
			w: width ? `${width}` : null,
			ar: aspectRatio ? `${aspectRatio}` : null,
			fit: crop || aspectRatio ? 'crop' : null,
			dpr: devicePixelRatio ? `${devicePixelRatio}` : null,
			h: height ? `${height}` : null,
			source: isAvatarCdn ? 'imgix' : null,
			q: quality,
			fm: format,
			auto: 'format',
		});
	}

	return buildImgUrl(cdnUrl, url, query);
};

const ImageTemplate: ImageTemplateComponent = ({
	cdnUrl,
	src,
	width,
	aspectRatio,
	format,
	height,
	imgRef,
	crop,
	quality,
	...imgProps
}) => (
	// eslint-disable-next-line jsx-a11y/alt-text
	<img
		srcSet={[1, 2, 3]
			.map(
				dpr =>
					`${getImageUrl({
						src,
						cdnUrl,
						width,
						aspectRatio,
						devicePixelRatio: dpr,
						format,
						height,
						crop,
						quality,
					})} ${dpr}x`
			)
			.join(',')}
		src={getImageUrl({ src, cdnUrl, width, aspectRatio })}
		ref={imgRef}
		{...imgProps}
	/>
);

export default ImageTemplate;

import { InitialUserSettingFormValues } from '.';

/**
 * SettingsFormValues is exported to be used as a way of sharing
 * the updated form values in real time.  This is currently being used
 * in place of Yup.schema({}) since our Formik context is not set up
 * to use it.  It is currently being used to compare the values between
 * the Email field and the Confirm Email field.
 */
const SettingsFormValues: InitialUserSettingFormValues = {
	allowsThirdPartyCookies: false,
	bio: '',
	city: '',
	country: {
		code: 0,
		title: '',
		label: '',
		value: '',
	},
	email: '',
	stateProvinceRegion: {
		code: 0,
		title: '',
		label: '',
		value: '',
	},
	website: '',
	facebook: '',
	twitter: '',
	instagram: '',
	tumblr: '',
	youtube: '',
};

export default SettingsFormValues;

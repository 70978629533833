import React, { ComponentProps } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { NoInfer } from 'src/common/typescript';
import selectInputStylingProps from './selectInputStylingProps';
import styles from './selectInputStylingProps.module.scss';

/**
 * A single option that can be provided to the select.
 */
export interface SelectInputOption<TValue> {
	value: TValue;
	label: string;
	isDisabled?: boolean;
}

/**
 * A group of options that can be provided to the select.
 */
interface SelectInputOptionGroup<TValue> {
	label: string;
	options: SelectInputOption<TValue>[];
}

/**
 * A list of options or groups that can be provided to the select.
 */
type SelectInputOptions<TValue> =
	| ReadonlyArray<SelectInputOption<TValue>>
	| ReadonlyArray<SelectInputOptionGroup<TValue>>;

/**
 * Props that can be passed to the `SelectInput` template component.
 */

type OptionType = {
	[key: string]: string;
};
export interface SelectInputTemplateProps<TValue = unknown> {
	/**
	 * The placeholder to show when no items are selected.
	 * @default ''
	 */
	placeholder?: string;

	/**
	 * Whether or not to allow the user to type to find options.
	 * @default false
	 */
	isSearchable?: boolean;

	/**
	 * The available options to search from. If not provided, the dropdown will
	 * show no options.
	 *
	 * @example
	 * <Select options={[
	 *   { value: 1, label: 'First' },
	 *   { value: 2, label: 'Second' },
	 * ]} />
	 *
	 * @example
	 * // With Groups
	 * <Select options={[{
	 *   label: 'Group A',
	 *   options: [{ value: 1, label: 'First' }],
	 * }, {
	 *   label: 'Group B',
	 *   options: [{ value: 2, label: 'Second' }],
	 * }]} />
	 */
	options?: SelectInputOptions<TValue>;

	/**
	 * The current value of the select. If provided, the input is controlled and
	 * will not change unless the value is updated. If missing, the input is
	 * uncontrolled.
	 */
	value?: SelectInputOption<NoInfer<TValue>>;

	/**
	 * Called when the value is updated. This should do something with the new
	 * value and update `value`, if provided.
	 */
	onChange?: (value: SelectInputOption<NoInfer<TValue>>) => void;

	/**
	 * Called when the select is focused.
	 */
	onFocus?: () => void;

	/**
	 * Called when the select value is blurred.
	 */
	onBlur?: () => void;

	/**
	 * Whether or not the input is currently loading data asynchronously. This
	 * will show an indicator to the user if `true`.
	 * @default false
	 */
	isLoading?: boolean;

	/**
	 * Whether or not the input is currently disabled. This will prevent user
	 * input.
	 * @default false
	 */
	isDisabled?: boolean;

	invalid?: boolean;
	fixed?: boolean;
	containerOpen?: boolean;
	menuIsOpen?: boolean;
	defaultInputValue?: string;
	defaultValue?: string;
	onInputChange?: (value: string) => void;
	inputValue?: string;
	getOptionValue?: (option: any) => string;
	getOptionLabel?: (option: any) => string;
	formatOptionLabel?: ComponentProps<typeof Select>['formatOptionLabel'];
	name?: string;
}

/**
 * `SelectInput` component interface. This includes all possible overloads.
 */
interface SelectInputTemplateComponent {
	<TValue>(props: SelectInputTemplateProps<TValue>): JSX.Element;
}

const SelectInputTemplate: SelectInputTemplateComponent = ({
	isSearchable = false,
	onChange,
	placeholder = '',
	invalid,
	fixed,
	containerOpen,
	defaultInputValue,
	defaultValue,
	onInputChange,
	inputValue,
	getOptionValue,
	value,
	formatOptionLabel,
	...props
}) => (
	<Select
		{...selectInputStylingProps}
		{...props}
		className={classNames(styles.container, {
			[styles['container-invalid']]: invalid,
			[styles['container-fixed']]: fixed,
			[styles['container-open']]: containerOpen,
		})}
		isClearable={false}
		placeholder={placeholder}
		isSearchable={isSearchable}
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		//@ts-ignore //TODO We need to fix the typing for this in the SelectInputTemplateProps
		onChange={value => onChange?.(value)}
		defaultInputValue={defaultInputValue}
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		//@ts-ignore //TODO We need to fix the typing for this in the SelectInputTemplateProps
		defaultValue={defaultValue}
		onInputChange={value => onInputChange?.(value)}
		inputValue={inputValue}
		getOptionValue={getOptionValue}
		value={value}
		formatOptionLabel={formatOptionLabel}
	/>
);

export default SelectInputTemplate;

import createEventService from 'src/common/factories/createEventService';

type Event = { type: 'LIST_MODIFIED' };

/**
 * Event service for all application modals.
 */
const modalEventService = createEventService<Event>();

export default modalEventService;

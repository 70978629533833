import tw from 'tailwind.macro';

import { css, styled } from 'src/theme';

interface TextareaProps {
	fixed?: boolean;
	invalid?: boolean;
}

export const TextareaBaseCss = css`
	transition: 150ms;
	box-sizing: border-box;
	line-height: 1.79;
	resize: vertical;
	${tw`
		block
		w-full
		appearance-none
		border border-solid
		bg-white border-blue
		text-black
		p-6
		rounded
		outline-none
	`}
	&:focus {
		${tw`
			border-blue
		`}
	}
`;

export const Textarea = styled.textarea.attrs({ rows: 4 })<TextareaProps>`
	${TextareaBaseCss}
	${props =>
		props.fixed &&
		css`
			&,
			&:focus {
				${tw`border-green`}
			}
		`}
	${props =>
		props.invalid &&
		css`
			&,
			&:focus {
				${tw`border-red`}
			}
		`}
`;

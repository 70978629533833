import isPromise from './isPromise';
import LoggerScope from './logger/LoggerScope';
import logger from './logger';

/**
 * Run a code block, logging any errors that occur, and re-throwing the errors.
 * This handles both synchronous and asynchronous errors, so if the code block
 * returns a promise that throws an error, that error will be logged.
 */
export default function runWithErrorLogger<TReturn>(
	logContext: LoggerScope['context'],
	fn: () => TReturn,
	shouldLogError = (error: unknown) => true
): TReturn {
	try {
		const result = fn();
		if (isPromise(result)) {
			result.then(null, error => {
				if (shouldLogError(error)) {
					logger.error(error, { context: logContext });
				}
			});
		}
		return result;
	} catch (error) {
		if (shouldLogError(error)) {
			logger.error(error, { context: logContext });
		}
		throw error;
	}
}

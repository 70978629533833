/**
 * Pass in a function to return a function that will call the passed in function
 * only one time, no matter how many times the wrapped function is called.
 */
const callOnce = <TFn extends (...args: any[]) => any>(
	fn: TFn
): ((...args: Parameters<TFn>) => ReturnType<TFn> | void) => {
	let called = false;
	return (...args) => {
		if (!called) {
			called = true;
			return fn(...args);
		}
	};
};

export default callOnce;

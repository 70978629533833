import { Flatten } from 'src/common/typescript';
import { User } from 'src/schema/generated/User';
import { World } from 'src/schema/generated/World';
import { AccessPermission } from 'src/schema/generated/globalTypes';

/**
 * Entities, constants, and interfaces for domain-centric authentication/authorization concepts.
 * IMPORTANT: Because we want the backend to generally be the root of trust, we want to keep
 * frontend access controls to a minimum and instead rely on the backend to deny access to
 * actions and data.
 */

/**
 * User Roles. While not use for RBAC, we will likely use these for other purposes
 * (e.g. badging, identifying moderators)
 * These map one-to-one into backend User roles.
 */
export enum Role {
	ClientPurchaser = 'ROLE_CLIENT_PURCHASER',
	ClientAdmin = 'ROLE_CLIENT_ADMIN',
	UserManager = 'ROLE_USER_MANAGER',
	ProductionSupport = 'ROLE_PRODUCTION_SUPPORT',
	CommunitySupport = 'ROLE_COMMUNITY_SUPPORT',
	Moderator = 'ROLE_MODERATOR',
	PaperworkManager = 'ROLE_PAPERWORK_MANAGER',
	PaymentManager = 'ROLE_PAYMENT_MANAGER',
}

/**
 * Responses from frontend access grant requests.
 */
export enum Access {
	Granted,
	Denied,
}

interface PermissionMap {
	[key: string]: {
		[key: string]: AccessPermission;
	};
}

const ensurePermissionMap = <T extends PermissionMap>(map: T): T => map;

/**
 * User permissions. Matches values expected from the backend.
 *
 * As more permissions from backend are needed, related permissions should be encapsulated
 * in their own objects (see Permissions.AdminPagesPage as an example).
 */
export const Permissions = ensurePermissionMap({
	AdminPagesPage: {
		READ: 'ADMIN_PAGES_PAGE__READ',
	},
	HiddenWorlds: {
		READ: 'HIDDEN_WORLDS__READ',
	},
	PurchasedWorlds: {
		READ: 'PURCHASED_WORLDS__READ',
	},
	RemovedWorlds: {
		READ: 'REMOVED_WORLDS__READ',
	},
	UserAccounts: {
		DELETE: 'USER_ACCOUNTS__DELETE',
	},
	WorldPurchases: {
		WRITE: 'WORLD_PURCHASES__WRITE',
	},
	Worlds: {
		WRITE: 'WORLDS__WRITE',
		DELETE: 'WORLDS__DELETE',
	},
	WorldPrivacy: {
		WRITE: 'WORLD_PRIVACY__WRITE',
	},
	CreativePrompts: {
		WRITE: 'CREATIVE_PROMPTS__WRITE',
	},
} as const);

/**
 * Permission type to provide stronger typing to permission-related functions
 */
export type Permission = Flatten<
	{
		[K in keyof typeof Permissions]: Flatten<typeof Permissions[K]>;
	}
>;

/**
 * Determines whether a given User has access to data or action that requires a
 * specific permission. Currently, access is derived based on the User's roles.
 * @param user the User seeking access
 * @param permission the Permission that controls the access
 */
export function userHasPermission(
	user: Pick<User, 'permissions'> | undefined,
	permission: Permission
): boolean {
	if (!user || user.permissions.length === 0) return false;

	return user.permissions.includes(permission);
}

/**
 * Determines whether a given User has access to data or action that requires a specific permission.
 * Currently, access is derived based on the User's roles.
 * @param user the User seeking access
 * @param permission the Permission that controls the access
 */
export function requestAccessForUser(
	user: Pick<User, 'permissions'>,
	permission: Permission
): Access {
	return userHasPermission(user, permission) ? Access.Granted : Access.Denied;
}

/**
 * Determines whether a given User is the architect of a world.
 * @param world the World in question
 * @param user the User to check if they're the architect
 */
export function userIsArchitect(
	world: Pick<World, 'author'> | undefined,
	user: Pick<User, 'id'> | undefined
): boolean {
	if (!user || !world) return false;
	return world.author.id === user.id;
}

export interface SandBoxUser extends User {}

export function convertToSandBoxUser(
	user: User | undefined | null
): SandBoxUser | undefined {
	if (user) {
		return {
			...user,
		};
	} else {
		return makeSandBoxUser(null);
	}
}

export function makeSandBoxUser(
	id: API.UUID | undefined | null
): SandBoxUser | undefined {
	return {
		__typename: 'User',
		id: id ?? '',
		email: 'sandbox@tongal.com',
		name: '',
		hasPayments: false,
		cookieConsent: false,
		isActive: true,
		permissions: [],
		biography: null,
		city: null,
		countryName: null,
		stateOrProvince: null,
		websiteUrl: null,
		facebookUrl: null,
		twitterUrl: null,
		instagramUrl: null,
		tumblrUrl: null,
		youtubeUrl: null,
	};
}

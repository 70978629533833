import { StateListener } from 'xstate/lib/interpreter';

import React, { useContext, useRef, useEffect } from 'react';
import {
	EventObject,
	ActionObject,
	ActionTypes,
	StateMachine,
	StateSchema,
	BuiltInEvent,
} from 'xstate';

/**
 * Helper to keep schemas as DRY as possible. All this does is inject the key
 * `states` into each level of the heirarchy.
 *
 * For example: `{ state: { substate: {} } }`
 *
 * Transforms into: `{ states: { state: { states: { substate: {} } } } }`
 */
export type MakeSchema<
	T,
	U = { [K in keyof T]: Pick<T, K> }
> = T extends Partial<T> & U[keyof U]
	? {
			states: {
				[K in keyof T]: MakeSchema<T[K]>;
			};
	  }
	: T;

/**
 * Polyfill for the missing events created from XState internals. All event
 * types defined should union with this event to keep types accurate.
 *
 * For example: `type MyEvent = { type: 'OTHER' } | BaseEvent`
 * @see https://xstate.js.org/docs/guides/communication.html#promise-rejection
 */
export type BaseEvent = BuiltInEvent<any>;

/**
 * Helper for extracting context, schema, and event types from a state machine.
 */
type MachineGenerics<T> = T extends StateMachine<infer C, infer S, infer E>
	? [C, S, E]
	: never;

/**
 * Helper to transform a `StateSchema` into a potential XState `StateValue`
 * object. This has far more type-safety than the built-in XState `StateValue`,
 * which allows any strings.
 * @todo Restrict impossible configurations, with respect to parallel states.
 */
type StateValue<
	TSchema extends StateSchema = StateSchema,
	S = TSchema['states'] & {}
> =
	| keyof S
	| Partial<
			{
				[K in keyof S]: StateValue<S[K] & {}>;
			}
	  >;

/**
 * Defines initialization options to be used by `useMachine`. This can be
 * injected by means of React Context using the `MachineInitContext`. The most
 * common use case for this is for automated testing and showcasing UI elements
 * without needing the user to enter specific states manually.
 */
export interface MachineInitOptions<
	TStateMachine extends StateMachine<TContext, TSchema, TEvent>,
	TContext = MachineGenerics<TStateMachine>[0],
	TSchema extends StateSchema = MachineGenerics<TStateMachine>[1],
	TEvent extends EventObject = MachineGenerics<TStateMachine>[2]
> {
	/**
	 * Initializes the state machine to a specific state, not to the machine's
	 * default initial state. This uses a `StateValue` to initialize to either a
	 * root-level state via `'stateName'` or a substate via `{ stateName:
	 * 'substateName' }`
	 */
	initial?: StateValue<TSchema>;

	/**
	 * How often to re-mount the component tree, re-creating all components,
	 * including the affected machine. `always` will remount on every render of
	 * this component. `changed` will remount every time the `context` or
	 * `initial` have changed values (checked with stringifying both), and `never`
	 * will not trigger a remount, so remounting will only happen if the provider
	 * itself is remounted.
	 *
	 * Defaults to `changed`
	 */
	remount?: 'always' | 'changed' | 'never';

	/**
	 * Disables the use of the `send` method returned from `useMachine`. This does
	 * not disable straight `service.send` calls. This can be useful to stop a
	 * component from updating itself.
	 */
	disableSend?: boolean | Partial<Record<TEvent['type'], boolean>>;

	/**
	 * Initial context to be injected into the machine.
	 * @see https://xstate.js.org/docs/guides/context.html#initial-context
	 */
	context?: Partial<TContext>;

	/**
	 * Passthru to the XState service onTransition method. This will be called
	 * every time a service state transitions whether or not the state actually
	 * changed.
	 * @see https://xstate.js.org/docs/guides/interpretation.html#transitions
	 */
	onTransition?: StateListener<TContext, TEvent>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BareMachineInitOptions = MachineInitOptions<any, any, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BareMachine = StateMachine<any, any, any>;

interface MachineInitContextProviderProps<TStateMachine extends BareMachine> {
	machine: TStateMachine;
	value: MachineInitOptions<TStateMachine>;
	children: React.ReactNode;
}

const MachineInitContext = React.createContext<
	Record<number, BareMachineInitOptions>
>({});

const machineIdStore = new WeakMap<BareMachine, number>();
let machineCount = 0;

/**
 * Allows injection of `MachineInitOptions` into a `useMachine` call. You may
 * use this by adding this component to the component tree above a component
 * with a `useMachine` call. The `value` of the provider is a
 * `MachineInitOptions` object.
 * @see https://reactjs.org/docs/context.html#contextprovider
 */
export const MachineInitContextProvider = <TStateMachine extends BareMachine>({
	value,
	machine,
	children,
}: MachineInitContextProviderProps<TStateMachine>): React.ReactElement => {
	const context = useContext(MachineInitContext);
	const renderKeyRef = useRef(0);

	if (!machineIdStore.has(machine)) {
		machineCount = (machineCount + 1) % Number.MAX_SAFE_INTEGER;
		machineIdStore.set(machine, machineCount);
	}

	// Ensure machine is removed from store on unmount or machine swap
	useEffect(() => {
		const oldMachine = machine;
		return () => {
			machineIdStore.delete(oldMachine);
		};
	}, [machine]);

	const machineId = machineIdStore.get(machine);
	if (!machineId) {
		throw new Error('Error getting ID of state machine');
	}

	let key: number | string | undefined;
	if (value.remount === 'always') {
		renderKeyRef.current = (renderKeyRef.current + 1) % Number.MAX_SAFE_INTEGER;
		key = renderKeyRef.current;
	} else if (value.remount === 'changed' || !value.remount) {
		key = JSON.stringify([value.context, value.initial]);
	}

	return (
		<MachineInitContext.Provider
			value={{
				...context,
				[machineId]: value,
			}}
			key={key}
		>
			{children}
		</MachineInitContext.Provider>
	);
};

/**
 * Fetch the `MachineInitOptions` given a `machine`. If no options have been
 * provided ahead of time via a `MachineInitContextProvider`, this will return
 * `{}`.
 */
export const useMachineInitContext = <TStateMachine extends BareMachine>(
	machine: TStateMachine
): MachineInitOptions<TStateMachine> => {
	const context = useContext(MachineInitContext);

	const machineId = machineIdStore.get(machine);
	if (machineId) {
		return context[machineId];
	} else {
		return {};
	}
};

/**
 * Helper to assign data coming from an event onto a context
 * @param type The event type that data will be pulled from
 * @param prop The property to assign data onto the context and from the event.
 */
function assignFromEvent<
	C extends {},
	E extends EventObject,
	T extends Extract<E, { type: string }>['type']
>(type: T, prop: keyof C & keyof Extract<E, { type: T }>): ActionObject<C, E>;

/**
 * Helper to assign data coming from an event onto a context
 * @param type The event type that data will be pulled from
 * @param prop The property to assign data onto the context
 * @param from The property to pull data from on the event
 */
function assignFromEvent<
	C extends {},
	E extends EventObject,
	T extends Extract<E, { type: string }>['type']
>(
	type: T,
	prop: keyof C,
	from: keyof Omit<Extract<E, { type: T }>, 'type'>
): ActionObject<C, E>;

/**
 * Helper to assign data coming from an event onto a context
 * @todo Add type safety for if context and event type differ
 * @param type The event type that data will be pulled from
 * @param prop The property to assign data onto the context. Data will be pulled
 * from the event by this name unless `from` is defined.
 * @param from The property to pull data from on the event. Data will be pulled
 * from the context's `prop` unless this is defined.
 */
function assignFromEvent<
	C extends {},
	E extends EventObject,
	T extends Extract<E, { type: string }>['type']
>(type: T, prop: keyof E, from?: keyof E): ActionObject<C, E> {
	return {
		type: ActionTypes.Assign,
		assignment: (context: C, event: E) => {
			if (type !== event.type) {
				throw new Error(
					`Event type mismatch. Expected: ${type}, received: ${event.type}.`
				);
			}
			return {
				[prop]: event[from || prop],
			};
		},
	};
}

export { assignFromEvent };

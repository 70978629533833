import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export type KeyboardEventType =
	| React.KeyboardEvent<HTMLButtonElement>
	| React.MouseEvent<HTMLButtonElement, MouseEvent>
	| Event;

interface SkipToContentInterface {
	(event: KeyboardEventType): void;
}

interface UseSkipToContentInterface {
	skipToContent: (event: KeyboardEventType) => void;
	setTargetContent: (targetId: string) => void;
	focusSkipToContentButton: () => void;
}

type AcceptedEvents = 'Enter' | 'click' | 'touchend' | 'Tab';

type ScrollEnded = number;

/**
 * @since 1.0.0
 * @module useSkipToContent module
 * @summary Scrolls to the main content.  Sets the lister for
 * focusing on the skipPreviousSibling element, sibling to the
 * "Skip to Content button" on route change.
 * This is for use with screen readers
 */

const useSkipToContent = (): UseSkipToContentInterface => {
	const history = useHistory();
	const [targetId, setTargetId] = useState<string>('main');
	const main = document.getElementById(targetId);
	const skipPreviousSibling = document.getElementById('skip-previous-sibling');

	/**
	 * @summary Optional: Overrides the default id, 'main', as the target for the content
	 * @param targetId {String}
	 */
	const setTargetContent = (targetId: string) => {
		setTargetId(targetId);
	};

	/**
	 * @summary Sets up a route change listener which will set the focus on the
	 * skipPreviousSibling element.  This will ensure that pressing tab will
	 * reveal the 'Skip to Content' button, only when the path is changed
	 */
	const focusSkipToContentButton = history.listen(({ search }) => {
		if (!search) skipPreviousSibling?.focus();
	});

	/**
	 * @summary This scrolls the target into view and sets the focus on the
	 * targeted content so that screen readers start reading from
	 * this point in the DOM tree.
	 */
	const scrollAndFocus = (): void => {
		main?.scrollIntoView({
			behavior: 'smooth',
		});

		main?.focus();
	};

	/**
	 * @summary This function acts as a 'scrollEnd' event since currently
	 * there is no native scroll end event. It allows the animation from
	 * scrollIntoView to complete before setting the target content
	 * for the screen reader users
	 */
	const scrollEnd = () => {
		let scrollTimeout: ScrollEnded;

		// eslint-disable-next-line prefer-const
		scrollTimeout = setTimeout(() => {
			clearInterval(scrollTimeout);
			window.removeEventListener('scroll', scrollEnd);
			window.location.href = `#${targetId}`;
		}, 100);
	};

	/**
	 * @function skipToContent
	 * @summary This is called on click or keyDown.  It calls the
	 * scrollAndFocus function.  This is normally just called from
	 * within this component but it is exposed if it is needed
	 * elsewhere.
	 * @param event Enter or click
	 */
	const skipToContent: SkipToContentInterface = event => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		const eventKeyOrType: AcceptedEvents = event.key || event.type;

		window.addEventListener('scroll', scrollEnd);

		switch (eventKeyOrType) {
			case 'Enter':
				scrollAndFocus();
				break;
			case 'click':
				scrollAndFocus();
				break;
			case 'touchend':
				scrollAndFocus();
				break;

			default:
				return;
		}
	};
	return {
		skipToContent,
		setTargetContent,
		focusSkipToContentButton,
	};
};

export default useSkipToContent;

import { useState, useMemo } from 'react';

/**
 * Take an async function and use it in a component by adding `loading` and
 * `error` keys.
 *
 * It's important to add a `useCallback` if you're defining the function inline.
 *
 * @example
 * const { data, loading, error } = useAsync(someAsyncFunction)
 *
 * @example
 * const { data, loading, error } = useAsync(
 *   useCallback(
 *     async () => {},
 *     []
 *   )
 * )
 */
function useAsync<T>(
	fn: () => Promise<T>
):
	| { loading: true; data?: undefined; error?: undefined }
	| { loading: false; data: T; error?: undefined }
	| { loading: false; data?: undefined; error: unknown } {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<unknown>();
	const [data, setData] = useState<T>();

	useMemo(async () => {
		setLoading(true);
		try {
			setData(await fn());
		} catch (err) {
			setError(err);
		}
		setLoading(false);
	}, [fn]);

	if (!loading && error) return { loading, error };
	if (loading || !data) return { loading: true };
	if (!data) return { loading, error: 'Unknown' };
	return { loading, data };
}

export default useAsync;

import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { Button } from 'src/elements';
import cookiesImg from 'src/assets/images/cookie.svg';
import styles from './CookieBanner.module.scss';

interface CookieBannerTemplateProps {
	signUp: () => void;
}

const CookieBanner = ({ signUp }: CookieBannerTemplateProps) => (
	<CookieConsent
		location={'bottom'}
		buttonText={'ok, got it!'}
		containerClasses={styles['container-class']}
		contentClasses={styles['content-class']}
		buttonClasses={styles['button-accept']}
	>
		<img src={cookiesImg} alt="cookie" />
		<div>
			We use cookies to enhance your website browsing experience and measure
			website performance.
			<br />
			You can opt in or out of cookies that help personalize social media
			features and content ads by{' '}
			<Button
				className={styles['register-button']}
				onClick={signUp}
				aria-label="registering"
			>
				registering
			</Button>{' '}
			and going to your settings.
			<br />
			You can also review our{' '}
			<Link to="/termsofservice" className={styles['link']}>
				terms of service
			</Link>{' '}
			for more information.
		</div>
	</CookieConsent>
);

export default CookieBanner;

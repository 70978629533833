import React, { useEffect } from 'react';

import authService from 'src/auth/authService';

/**
 * A blank page whose sole purpose is redirecting to the register page.
 */
const AuthRegisterPage: React.FC = () => {
	useEffect(() => {
		authService.signUp();
	}, []);

	return <></>;
};

export default AuthRegisterPage;

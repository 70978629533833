import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'src/elements';
import SkipToContent from '../SkipToContent';
import { NavLink } from './NavLink';
import {
	DesktopDiv,
	GenericNavControl,
	HomeSection,
	HorizontalSeparator,
	LinkList,
	MobileMenu,
	MobileOverlayBackdrop,
	NavBar,
	NavContainer,
} from './PageNavigation.style';
import { ProfileNavLink } from './ProfileNavLink';
import { PageNavigationInterface, genericNavLinks } from '.';

interface PageNavigationComponent {
	(props: PageNavigationInterface): JSX.Element;
}

/**
 * Main header at the top of all pages of the site.
 */
const PageNavigation: PageNavigationComponent = ({
	AnimatedMobileOverlay,
	AnimatedIcon,
	closeMenu,
	closedIcon,
	logOut,
	logoRef,
	mobileOverlayTransitions,
	openedIcon,
	profileNavLinkProps,
	adminNavLinks,
	toggleMenu,
	overlayRef,
	currentUser,
}) => {
	return (
		<>
			<SkipToContent />
			<NavContainer>
				<Container>
					<NavBar>
						<HomeSection>
							<Link to="/" ref={logoRef}>
								<img
									src={require('src/assets/images/full-logo.svg')}
									alt="Homepage"
								/>
							</Link>
						</HomeSection>

						<GenericNavControl>
							<Button nav onClick={toggleMenu} title="Open menu">
								{AnimatedIcon && (
									<AnimatedIcon icon={closedIcon} size="2x" fixedWidth />
								)}
							</Button>
						</GenericNavControl>

						<DesktopDiv>
							<LinkList>
								{genericNavLinks.map(navLink => (
									<NavLink key={navLink.label} navLink={navLink} />
								))}
								{adminNavLinks && <NavLink navLink={adminNavLinks} />}
							</LinkList>
						</DesktopDiv>

						<DesktopDiv>
							{profileNavLinkProps && (
								<ProfileNavLink {...profileNavLinkProps} />
							)}
						</DesktopDiv>
					</NavBar>
				</Container>

				<HorizontalSeparator />

				{mobileOverlayTransitions?.map(
					({ item, key, props }) =>
						item &&
						AnimatedMobileOverlay && (
							<AnimatedMobileOverlay key={key} style={props} ref={overlayRef}>
								<MobileOverlayBackdrop onClick={closeMenu} />

								<MobileMenu>
									<NavContainer as="div">
										<Container>
											<NavBar>
												<HomeSection>
													<Link to="/" ref={logoRef}>
														<img
															src={require('src/assets/images/full-logo.svg')}
															alt="Homepage"
														/>
													</Link>
												</HomeSection>

												<GenericNavControl>
													<Button nav onClick={toggleMenu} title="Close menu">
														{AnimatedIcon && (
															<AnimatedIcon
																icon={openedIcon}
																size="2x"
																fixedWidth
															/>
														)}
													</Button>
												</GenericNavControl>
											</NavBar>
										</Container>

										<HorizontalSeparator />
									</NavContainer>

									<Container>
										{profileNavLinkProps && (
											<ProfileNavLink {...profileNavLinkProps} />
										)}

										<LinkList>
											{genericNavLinks.map(navLink => (
												<NavLink key={navLink.label} navLink={navLink} />
											))}
											{adminNavLinks && <NavLink navLink={adminNavLinks} />}

											{!!currentUser && (
												<li key="logOut">
													<Button
														data-qa="logout-navbar-link"
														nav
														onClick={logOut}
													>
														Log Out
													</Button>
												</li>
											)}
										</LinkList>
									</Container>
								</MobileMenu>
							</AnimatedMobileOverlay>
						)
				)}
			</NavContainer>
		</>
	);
};

export default PageNavigation;

import createEventService from 'src/common/factories/createEventService';
import createStateService from 'src/common/factories/createStateService';
import runWithErrorLogger from 'src/common/runWithErrorLogger';
import routerHistory from 'src/router/routerHistory';

import logger from 'src/common/logger';
import auth0Client from './auth0Client';
import AuthEvent from './AuthEvent';
import AuthRedirectState from './AuthRedirectState';
import AuthState from './AuthState';

const { on, send } = createEventService<AuthEvent>();

const { state, setState } = createStateService<AuthState>({
	isAuthenticated: false,
	loading: true,
	user: undefined,
	tokenOverride: undefined,
});

/**
 * Local helper to make sure a state object is the correct shape. If not, it
 * will throw an error.
 */
function assertUserValid(user: unknown): asserts user is AuthState['user'] {
	const requiredKeys: (keyof Exclude<AuthState['user'], undefined>)[] = [
		'https://worldbuilder.tongal.com/uuid',
	];
	if (
		typeof user !== 'object' ||
		!user ||
		!requiredKeys.every(key => key in user)
	) {
		throw new Error('Invalid auth user');
	}
}

/**
 * Local helper to make sure a state object is the correct shape. If not, it
 * will throw an error.
 */
function assertStateValid(state: unknown): asserts state is AuthRedirectState {
	const TARGET_PATH: keyof Pick<AuthRedirectState, 'targetPath'> = 'targetPath';
	if (typeof state !== 'object' || !state || !(TARGET_PATH in state)) {
		throw new Error('Invalid auth redirect state');
	}
}

/**
 * Local helper to get the properties to be packaged up into the auth state.
 */
function getAppState(): AuthRedirectState {
	return {
		targetPath: routerHistory.location.pathname,
		targetQuery: routerHistory.location.search,
		objectLink: routerHistory.location.hash,
	};
}

function hasKey<K extends string>(
	key: K,
	object: object
): object is { [Key in K]: unknown } {
	return key in object;
}

/**
 * Helper to determine if we should log these errors when they occur during
 * background operations, such as token fetch and initialization.
 */
function shouldLogBackgroundError(error: unknown) {
	if (typeof error !== 'object' || error === null) {
		return true;
	}
	if (hasKey('error', error) && error.error === 'login_required') {
		return false;
	}
	if (
		hasKey('error_description', error) &&
		error.error_description === 'EMAIL NOT VERIFIED'
	) {
		return false;
	}
	return true;
}

interface AuthService {
	/**
	 * Call this to initialize the current user.
	 */
	init(): Promise<void>;

	/**
	 * Call this to be redirected to the sign-in form.
	 */
	signIn(prefillEmail?: string): Promise<void>;

	/**
	 * Call this to be redirected to the sign-up form.
	 */
	signUp(): Promise<void>;

	/**
	 * Call this to log out and redirect to the homepage.
	 */
	signOut(): Promise<void>;

	/**
	 * Call this to log out and redirect to the email verification message page.
	 */
	signOutEmailConfirmation(): Promise<void>;

	/**
	 * Provision's the current user.  If the current user is not authenticated, this
	 * will not return anything
	 */
	provision(): Promise<AuthState['user']>;

	/**
	 * Handle a callback from the auth provider. This should be called from the
	 * `/callback` page.
	 */
	handleCallback(): Promise<AuthRedirectState>;

	/**
	 * Get the current auth token. If the current user is not authenticated, this
	 * will not return anything. This token may be passed in the Authorization
	 * HTTP header.
	 */
	getToken(): Promise<string | void>;

	/**
	 * Trigger a callback to be ran when the auth service recieves an event. This
	 * will return a function to deregister the listener.
	 */
	on: typeof on;

	/**
	 * Send an event to the auth service.
	 */
	send: typeof send;

	/**
	 * The current state of the auth service.
	 */
	state: typeof state;
}

const authService: AuthService = {
	on,
	send,
	state,

	init: () =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Initialization' },
			async () => {
				try {
					const qaUser = (function() {
						try {
							return localStorage.getItem('qaUser');
						} catch (e) {}
					})();

					if (qaUser) {
						setState(JSON.parse(qaUser));
					} else {
						await auth0Client.getTokenSilently();
						const user = await auth0Client.getUser();
						assertUserValid(user);
						setState({
							isAuthenticated: !!user,
							user: user || undefined,
						});
					}
				} finally {
					setState({ loading: false });
					send('STATE_CHANGE');
				}
			},
			shouldLogBackgroundError
		),

	signUp: () =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Sign up redirect' },
			async () => {
				await auth0Client.loginWithRedirect({
					login_hint: 'HIDE_SIGN_IN',
					redirect_uri: `${process.env.REACT_APP_URL}/callback`,
					max_age: 0,
					appState: getAppState(),
				});
			}
		),

	signIn: (prefillEmail?: string) =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Sign in redirect' },
			async () => {
				await auth0Client.loginWithRedirect({
					login_hint: 'HIDE_SIGN_UP',
					prefill_email: prefillEmail,
					redirect_uri: `${process.env.REACT_APP_URL}/callback`,
					max_age: 0,
					appState: getAppState(),
				});
			}
		),

	signOut: () =>
		runWithErrorLogger({ Source: 'authService', Step: 'Logout' }, async () => {
			auth0Client.logout({ returnTo: process.env.REACT_APP_URL });
		}),

	signOutEmailConfirmation: () =>
		runWithErrorLogger({ Source: 'authService', Step: 'Logout' }, async () => {
			auth0Client.logout({
				returnTo: `${process.env.REACT_APP_URL}/auth/unverified`,
			});
		}),

	provision: () =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Provision' },
			async () => {
				// pre-cache the token in the session before fetching the user
				await auth0Client.checkSession();
				const user = await auth0Client.getUser();
				assertUserValid(user);

				await fetch(`${process.env.REACT_APP_BE_CORE_API}/provision`, {
					method: 'POST',
					cache: 'no-cache',
					headers: {
						Authorization: `Bearer ${await auth0Client.getTokenSilently()}`,
					},
				});

				return user;
			}
		),

	handleCallback: () =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Callback' },
			async () => {
				setState({ loading: true });
				send('STATE_CHANGE');
				// Sorry for the 3-level try blocks. The first doesn't have a catch,
				// just simply ensures we turn loading off and trigger a state change.
				// The other two are for a potential fix to the "Invalid State" issue we
				// keep running into.
				try {
					let state;
					try {
						const { appState } = await auth0Client.handleRedirectCallback();
						state = appState;
					} catch (err) {
						try {
							// The user may have been logged in, but the state was invalid. We
							// will try fetching the new user as described here:
							// https://github.com/auth0/auth0-spa-js/issues/515#issuecomment-663732036
							await auth0Client.getTokenSilently();
							logger.info('Callback failed but token fetch was successful', {
								context: { 'Callback Error': `${err}` },
							});
						} catch (_) {
							logger.info('Callback failed and token fetch was unsuccessful');

							// Throw the original error. The above was just a check to see if
							// the session exists but something happened when attempting to
							// get the state. If the token fetch here failed, we're more
							// interested in the redirect error than the token fetch error.
							throw err;
						}
					}

					const user = await authService.provision();
					assertUserValid(user);

					// First set the auth state, we need a valid user object for this,
					// otherwise we should not set that the user is authenticated.
					setState({
						isAuthenticated: !!user,
						user,
					});

					// Then check the app state, which is required for the redirect
					// callback to succeed.
					assertStateValid(state);
					return state;
				} finally {
					setState({ loading: false });
					send('STATE_CHANGE');
				}
			},
			shouldLogBackgroundError
		),

	getToken: () =>
		runWithErrorLogger(
			{ Source: 'authService', Step: 'Token fetch' },
			async () => {
				if (state.isAuthenticated) {
					return state.tokenOverride || (await auth0Client.getTokenSilently());
				}
			},
			shouldLogBackgroundError
		),
};

export default authService;

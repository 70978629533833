import React, { useContext, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { DeepPartial } from 'src/common/typescript';
import deepAssign from 'src/common/utils/deepAssign';
import useAuthState from 'src/auth/hooks/useAuthState';
import { GetCurrentUserQuery } from 'src/schema/user';
import { User } from 'src/schema/generated/User';
import { send } from 'src/tracking/trackingService';

/**
 * A context comprising information about the currently logged-in user.
 */
export interface CurrentUserResponse {
	/**
	 * the currently logged-in User. If no user is logged in, then undefined.
	 */
	currentUser?: User;

	/**
	 *  true when the authentication system is in the process of authenticating.
	 * Used to check whether currentUser is ready for consumption
	 */
	loading: boolean;

	/**
	 * An error string; present if there was an error during the GraphQL query.
	 * NOTE: This error does not currently fire on a 404 Not Found status code. This is
	 * an issue with the GraphQL library and will require custom code on our part to work around.
	 */
	// TODO: this should be fleshed out into a fuller error.
	error?: string;
}

const CurrentUserContext = React.createContext<CurrentUserResponse>({
	loading: false,
});

/**
 * a hook that provides a CurrentUserContext.
 */
export const useCurrentUserContext = (): CurrentUserResponse => {
	const currentUserContext = useContext(CurrentUserContext);
	if (!currentUserContext) {
		throw new Error('CurrentUserContext not found');
	}
	return currentUserContext;
};

/**
 * Encapsulates logic for determining whether the current user data has finished loading.
 * Returns true if the loading of data in underway. False otherwise.
 * @param isDataLoading true when fetching current user data is underway
 * @param isAuthenticationLoading true when authentication is underway
 */
function isUserLoading(
	isDataLoading: boolean,
	isAuthenticationLoading: boolean
): boolean {
	return isDataLoading || isAuthenticationLoading;
}

// todo: maybe better typing for our data i.e. typing in graphql
function extractUser(data: any): User {
	return data ? data.me : undefined;
}

/**
 * Encapsulates data related to the current user.
 */
export const CurrentUserContextProvider: React.FC<{}> = ({ children }) => {
	// todo: create roles: const [roles, setRoles] = useState('');
	const { isAuthenticated, loading: authLoading } = useAuthState();

	const { data, error, loading: queryLoading } = useQuery(GetCurrentUserQuery, {
		skip: authLoading || !isAuthenticated,
	});

	// memoize the context information to reduce renders of hook's consumers.
	const memoizedUserLoading = useMemo(
		() => isUserLoading(queryLoading, authLoading),
		[queryLoading, authLoading]
	);

	const memoizedError = useMemo(() => (error ? error.message : undefined), [
		error,
	]);

	const currentUser = extractUser(data);

	useEffect(() => {
		if (!memoizedUserLoading && currentUser) {
			if (currentUser?.cookieConsent) {
				send({
					type: 'INIT',
					userId: currentUser?.id,
				});
			} else {
				send({ type: 'DENY_CONSENT' });
			}
		}
	}, [currentUser, memoizedUserLoading]);

	const memoizedContext = useMemo(
		() => ({
			currentUser,
			loading: memoizedUserLoading,
			error: memoizedError,
		}),
		[currentUser, memoizedUserLoading, memoizedError]
	);

	return (
		<CurrentUserContext.Provider value={memoizedContext}>
			{children}
		</CurrentUserContext.Provider>
	);
};

/**
 * Use this to mock the CurrentUserContext. It will be initialized to a default
 * value, but any data can be provided to override the default user. If a value
 * is provided, it will be merged recursively.
 */
export const MockCurrentUserContextProvider: React.FC<{
	response?: DeepPartial<CurrentUserResponse>;
}> = ({ response, children }) => {
	const fullResponse = useMemo(
		() =>
			deepAssign(
				{
					loading: false,
					currentUser: {
						__typename: 'User',
						id: 'userXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX',
						name: 'John Doe',
						email: 'john.doe@example.com',
						isActive: true,
						hasPayments: false,
						permissions: [],
						biography: 'This is just about anyone',
						city: 'Anytown',
						countryName: 'United States',
						stateOrProvince: 'Idaho',
						cookieConsent: false,
						websiteUrl: null,
						facebookUrl: null,
						twitterUrl: null,
						instagramUrl: null,
						tumblrUrl: null,
						youtubeUrl: null,
					},
				},
				response || {}
			),
		[response]
	);

	return (
		<CurrentUserContext.Provider value={fullResponse}>
			{children}
		</CurrentUserContext.Provider>
	);
};

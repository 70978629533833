interface PrerenderIOIAgentnterface {
	agent: string;
	isBot: (agentString?: string) => boolean;
}

const PrerenderIoAgent: PrerenderIOIAgentnterface = {
	agent: 'Prerender (+https://github.com/prerender/prerender)',
	isBot: agentString => {
		const { userAgent } = window.navigator;
		const agentToMatch = agentString || PrerenderIoAgent.agent;

		return userAgent.includes(agentToMatch);
	},
};

export default PrerenderIoAgent;

import useImmediateEffect from 'src/hooks/useImmediateEffect';
import { useForceRender } from 'src/hooks';
import AuthState from '../AuthState';
import authService from '../authService';

/**
 * A hook to inject the current auth state into a react component. Any
 * modifications to the state will cause a re-render.
 *
 * @example
 * const { user, loading } = useAuthState();
 */
export default function useAuthState(): AuthState {
	const forceRender = useForceRender();

	useImmediateEffect(() => {
		const off = authService.on('STATE_CHANGE', forceRender);
		return () => {
			off();
		};
	}, [forceRender]);

	return authService.state;
}

import React from 'react';
import styles from './SkipToContent.module.scss';
import { SkipToContentProps } from '.';

/**
 * Props that can be passed to the `SkipToContent` template component.
 */
interface SkipToContentTemplateProps extends SkipToContentProps {
	/**
	 * @since 1.0.0
	 * @module SkipToContent module
	 * @summary Allows users to skip past the navigation elements while
	 * using screen readers
	 */
	// children: React.ReactNode;
}

/**
 * `SkipToContent` component interface. This includes all possible overloads.
 */
interface SkipToContentTemplateComponent {
	(props: SkipToContentTemplateProps): JSX.Element;
}

const SkipToContentTemplate: SkipToContentTemplateComponent = ({
	skipToContent,
}) => (
	<>
		{/*
		This button is only used to ensure that on route change it gets focus
		though it is always off screen.  On a fresh route change,
		this allows the user to press tab once and show the
		'Skip to Content' button.
		*/}
		<button
			tabIndex={-1}
			id="skip-previous-sibling"
			className={styles['skip-button-previous-sibling']}
			aria-hidden="true"
		/>
		<button
			tabIndex={0}
			onKeyDown={skipToContent}
			onClick={skipToContent}
			className={styles['skip-to-content']}
			id="skip"
		>
			Skip to Content
		</button>
	</>
);

export default SkipToContentTemplate;

import { faCaretDown, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'tailwind.macro';

import styled, { css, screens } from 'src/theme';

export const MobileDiv = styled.div`
	${tw`sm:hidden`}
`;

export const MobileOverlay = styled.div`
	${tw`
		fixed
		inset-0
		w-full
		h-full
		overflow-auto
		sm:hidden
	`}
`;

export const MobileOverlayBackdrop = styled.div`
	${tw`
		fixed
		inset-0
		z-0
	`}
`;

export const MobileMenu = styled.div`
	${tw`
		bg-white
		shadow-lg
		relative
		z-10
	`}
`;

export const DesktopDiv = styled.div`
	${tw`hidden sm:block`}
`;

export const DesktopLi = styled.li`
	${tw`hidden sm:block`}
`;

export const CaretIcon = styled(FontAwesomeIcon).attrs({
	icon: faCaretDown,
	size: 'sm',
})`
	transition: transform 0.25s;
`;

export const CaretIconContainer = styled.div`
	${tw`
		text-xl
		ml-2
		-my-1
		text-blue-light
	`}
`;

export const DefaultUserIcon = styled(FontAwesomeIcon).attrs({
	icon: faUserCircle,
})`
	${tw`
		inline-block
		text-4xl
		pr-3
		align-middle
	`}
`;

export const NavContainer = styled.nav`
	${tw`
		sticky
		top-0
		z-20
		bg-white
	`}
`;

export const NavBar = styled.div`
	${tw`
		py-4
		select-none
		flex
		justify-between

		sm:p-0
	`}
`;

export const HorizontalSeparator = styled.div`
	${tw`
		w-full
		border
		border-solid
		border-gray-light
	`}
	box-sizing: border-box;
`;

export const HomeSection = styled.div`
	display: flex;
	align-items: center;

	img {
		width: 209px;
	}

	@media (min-width: ${screens.lg}) {
		img {
			width: 331px;
		}
	}
`;

export const ProfileSection = styled.div`
	min-width: 200px;
	${tw`
		pt-8
		sm:pt-0
	`}
`;

export const UnauthenticatedProfile = styled.div`
	& > div {
		/* For default (mobile view) */
		& > div {
			box-sizing: border-box;
			${tw`
				w-1/2
				inline-block
			`}
			& > button {
				${tw`w-full`}
			}
		}
		& > div:nth-of-type(1) {
			${tw`pr-3`}
		}
		& > div:nth-of-type(2) {
			${tw`pl-3`}
		}

		/* For screens larger than mobile (screens.sm) */
		& > ul {
			& > span {
				${tw`
					border-black
					border-solid
					border-r
					border-t-0
					border-b-0
					border-l-0
					py-1
				`}
			}
			& > li > button {
				${tw`
					font-medium
					text-blue
					py-12
					w-full
				`}
			}
		}
	}
`;

export const GenericNavControl = styled.div.attrs({
	'data-qa': 'toggle-menu-button',
})`
	${tw`
		flex
		flex-column
		content-center
		-my-4

		sm:hidden
	`}
	& svg {
		${tw`align-middle`}
	}
`;

const Link = css`
	${tw`
		font-medium
		uppercase
		inline-block
		cursor-pointer
		no-underline
		appearance-none
		outline-none
		bg-transparent
		border-0
		p-0

		sm:block
		sm:px-0
		sm:py-3
	`}
	&:hover {
		&,
		& > svg {
			${tw`sm:text-blue-light`}
		}
	}
`;

const List = css`
	letter-spacing: 1.45px;
	${tw`
		list-none
		m-0 p-0
	`}
	& > li {
		${tw`sm:inline-block`}
		& > a {
			${Link}
			${tw`
				whitespace-no-wrap
				w-full
				sm:py-4
			`}
			${DesktopDiv} > & {
				${tw`sm:py-12`}
			}
		}
	}
`;

export const LinkList = styled.ul`
	${List}
	& > li {
		&:first-of-type {
			${tw`
				pt-8
				sm:pt-0
			`}
		}
		&:last-of-type {
			${tw`
				pb-8
				sm:pb-0
			`}
		}
		${tw`
			py-4
			sm:py-0

			sm:px-2
			md:px-4
			lg:px-6
		`}
		${ProfileSection} & {
			${tw`
				md:px-4
				lg:px-4
			`}
		}

		& > button {
			${Link}
			line-height: inherit;
			${tw`
				whitespace-no-wrap
				sm:py-4
				w-full
				tracking-normal
				text-left
			`}
		}
	}
`;

export const UserPictureLabel = styled.div`
	display: inline-block;
	margin: -15px 10px;
	vertical-align: middle;
`;

interface DropdownContentProps {
	shown: boolean;
}

export const DropdownContainer = styled.div<DropdownContentProps>`
	${tw`
		sm:inline-block
		sm:relative
	`}
	& > button {
		line-height: inherit;
		${Link}
		${tw`
			flex
			justify-between
			w-full

			sm:w-auto
			sm:py-12
		`}
		${ProfileSection} & {
			padding: 3rem 0 !important;
			span {
				${tw`align-middle`}
			}
		}
		${props =>
			props.shown &&
			tw`
			text-blue
		`}
		& > div {
			${tw`sm:inline-block`}
		}
	}
`;

export const DropdownContent = styled.div<DropdownContentProps>`
	${tw`
		pl-6
		pointer-events-none
		overflow-hidden

		sm:absolute
		sm:bg-gray-lightest
		sm:shadow
		sm:-mt-1
		sm:border-blue-light
		sm:border-solid
		sm:border-4
		sm:border-r-0
		sm:border-b-0
		sm:border-l-0
		sm:p-6
	`}

	${props =>
		props.shown &&
		tw`
		visible
		opacity-100
		pointer-events-auto

		sm:block
	`}

	& > ul > li {
		${tw`
			pt-6
			pb-0

			sm:block
			sm:whitespace-no-wrap
		`}
		& > a {
			${tw`
				w-full

				sm:py-0
			`}
		}
		& > button {
			${Link}
			${tw`
				w-full
				tracking-normal
				text-left
				-mt-2

				sm:py-0
			`}
		}
	}

	@media (min-width: ${screens.sm}) {
		left: 50%;
	}
`;

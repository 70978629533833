module.exports = {
	theme: {
		extend: {},
		screens: {
			sm: '768px',
			md: '992px',
			lg: '1200px',
		},
		colors: {
			transparent: 'transparent',
			black: '#000000',
			white: '#FFFFFF',
			gray: {
				light: '#EBEEED',
				lighter: '#F3F3F3',
				lightest: '#FBFBFB',
				default: '#919191',
				dark: '#707372', // This is not from the styleguide, need to verify
			},
			blue: {
				light: '#00A2DB',
				default: '#0075BF',
			},
			red: '#DC0014',
			green: '#6CB33F',
			yellow: '#B7BF00',
		},
		fontFamily: {
			title: '"Passion One", sans-serif',
			heading: 'Khand, sans-serif',
			body: 'Roboto, sans-serif',
			mono:
				'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
		},
		borderRadius: {
			none: 0,
			sm: '2.5px',
			default: '5px',
			lg: '10px',
			full: '9999px',
		},
	},
	variants: {},
	plugins: [],
};

import React, { useEffect, useMemo, HTMLAttributes, useCallback } from 'react';
import { faTimes, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Key } from 'src/common/keyboard';
import ModalRenderProps from 'src/modal/ModalRenderProps';
import styles from './Modal.module.scss';

/**
 * Props that can be passed to the `Modal` component.
 */
interface ModalProps {
	/**
	 * @summary styleOverrides will be used to override the
	 * default styles. The children property is the object
	 * react provides to inject any elements into the modal.
	 */
	styleOverrides?: Partial<typeof styles>;

	/**
	 * The body of the modal. This may be provided in place of `content`.
	 */
	children?: React.ReactNode;

	/**
	 * The body of the modal. This may be provided in place of `children`. If both
	 * are provided, this overwrites `children`.
	 */
	content?: React.ReactNode;

	/**
	 * Determines whether or not to show the "X" icon in the top right. If a
	 * boolean, it shows/hides the icon. If an icon, it overwrites the "X".
	 * @default true
	 */
	closeButton?: IconDefinition | boolean;

	/**
	 * Whether or not the overlay should be able to be clicked to close it. If a
	 * form is in the modal, usually we would set this to `false`.
	 * @default true
	 */
	interactiveOverlay?: boolean;

	/**
	 * Function to close the current modal.
	 */
	closeModal: ModalRenderProps['closeModal'];

	/**
	 * Ref to the scrollable section of the modal. This can be used to disable
	 * body scrolling but enable scrolling on the modal.
	 */
	scrollRef?: React.Ref<HTMLDivElement>;

	/**
	 * Hides the overlay completely, this will trump `interactiveOverlay` because
	 * something cannot be seen or clicked if it's gone completely. 😲
	 */
	hideOverlay?: boolean;

	/**
	 * If true, the modal will gain focus when it mounts. This may be useful for
	 * screen reader users so the focus is set to the new content.
	 * @default true
	 */
	autoFocus?: boolean;
}

/**
 * Helper to unwrap the `closeButton` prop and build the full SVG node from it.
 */
function getCloseButtonNode(
	closeButton: ModalProps['closeButton'] = true
): React.ReactNode {
	if (closeButton === true) {
		closeButton = faTimes;
	}

	return closeButton && <FontAwesomeIcon icon={closeButton} />;
}

/**
 * Helper to generate an event listener that calls a function if specific keys
 * were pressed.
 */
function callIfKeys(
	fn: () => void,
	...keys: Key[]
): ({ which }: Pick<KeyboardEvent, 'which'>) => void {
	return ({ which }) => {
		if (keys.includes(which)) {
			fn();
		}
	};
}

/**
 * `Modal` component interface. This includes all possible overloads.
 */
interface ModalComponent {
	(props: ModalProps): JSX.Element;
}

/**
 * @summary Allows you to show content in a modal dialog. This is used by
 * <ModalRenderer> to render all active modals into the DOM, and does not need
 * to be added elsewhere manually.
 *
 * @example
 * import Modal from 'src/components/Modal/Modal';
 * import modalService from 'src/modal/modalService';
 * import createModalRenderer from 'src/modal/createModalRenderer';
 *
 * const LaunchButton = (): JSX.Element => {
 * 	const openModal = useCallback(() => {
 *   modalService.openModal({
 *    render: () => 'Hello!',
 *   })
 *  }, []);
 * 	return <button onClick={openModal}>Open Modal</button>;
 * };
 */
const Modal: ModalComponent = ({
	children,
	content,
	styleOverrides,
	interactiveOverlay = true,
	hideOverlay,
	closeButton,
	closeModal,
	scrollRef,
	autoFocus = true,
}: ModalProps) => {
	const closeIfSelectKeys = useMemo(() => {
		if (interactiveOverlay) {
			return callIfKeys(closeModal, Key.ENTER, Key.SPACE);
		}
	}, [closeModal, interactiveOverlay]);

	const contentRef: React.Ref<HTMLDivElement> = useCallback(
		elem => {
			if (autoFocus) {
				elem?.focus();
			}
		},
		[autoFocus]
	);

	useEffect(() => {
		if (interactiveOverlay) {
			const onKeyUp = callIfKeys(closeModal, Key.ESC);
			window.addEventListener('keyup', onKeyUp);
			return () => window.removeEventListener('keyup', onKeyUp);
		}
	}, [closeModal, interactiveOverlay]);

	/**
	 * NOTE TO ALL WHO ATTEMPT TO OVERRIDE THE STYLES!!
	 * The override does not work due to a rerendering desyncronization between the parent
	 * this modal. The value for styleOverrides is undefined when the modal opens.
	 */
	const styling: typeof styles = {
		...styles,
		...styleOverrides,
	};

	const overlayProps: HTMLAttributes<HTMLDivElement> = interactiveOverlay
		? {
				role: 'button',
				onClick: closeModal,
				onKeyUp: closeIfSelectKeys,
				title: 'Close dialog',
				tabIndex: 0,
		  }
		: {};

	const closeNode = getCloseButtonNode(closeButton);

	return (
		<div className={styling['modal']}>
			<div className={styling['modal-scrollable']} ref={scrollRef}>
				<div className={styling['modal-center']}>
					{!hideOverlay && (
						<div
							className={classNames(styling['modal-overlay'], {
								[styling['modal-overlay-interactive']]: interactiveOverlay,
							})}
							{...overlayProps}
						></div>
					)}
					<div className={styling['modal-content']} ref={contentRef}>
						{closeNode && (
							<button
								className={styling['modal-close-btn']}
								onClick={closeModal}
								title="Close dialog"
							>
								{closeNode}
							</button>
						)}
						<div className={styles['modal-child-content']}>
							{content || children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Modal;

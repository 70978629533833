import React from 'react';
import { faKeyboard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'yup';

import classNames from 'classnames';
import { OneMaxOf } from 'src/common/react';
import { forArrayOrSingle } from 'src/common/utils';
import CharacterLimitMessage from 'src/components/CharacterLimitMessage';
import Field, { BaseFieldProps } from 'src/components/Field';
import { Input } from 'src/elements';

import { FieldInfoRemaining } from './TextField.style';
import styles from './TextField.module.scss';

type TextFieldProps = BaseFieldProps<string> & {
	/**
	 * Adjust how the browser handles autocomplete. `off` is the most common
	 * value. A list of values can be found here:
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
	 */
	autoComplete?: React.InputHTMLAttributes<unknown>['autoComplete'];

	/**
	 * The type of keyboard shown on mobile devices, when supported. This is often
	 * preferred to the `type` as it doesn't change anything about the input
	 * except the on-screen keyboard that displays. For example, if entering a
	 * telephone number, we wouldn't want to use `type=number` as it'll show a
	 * counter, but we'd want to display the numeric keypad when typing.
	 */
	inputMode?:
		| 'none'
		| 'text'
		| 'decimal'
		| 'numeric'
		| 'tel'
		| 'search'
		| 'email'
		| 'url';

	/**
	 * Placeholder text that will display when the field is empty
	 */
	placeholder?: string;

	/**
	 * Used to add a note to the bottom of the TextField
	 */
	info?: string;
} & OneMaxOf<
		{
			/**
			 * The exact length, in characters, required. Multi-byte characters will take
			 * up multiple characters. Two 4-byte characters would be a length of 8. This
			 * may not be used in tandem with `minLength` or `maxLength`.
			 */
			length: number;

			/**
			 * The minimum length, in characters, required. Multi-byte characters will
			 * take up multiple characters. Two 4-byte characters would be a length of 8.
			 * This may not be used in tandem with `length`.
			 */
			minLength: number;

			/**
			 * The maximum length, in characters, required. Multi-byte characters will
			 * take up multiple characters. Two 4-byte characters would be a length of 8.
			 * This may not be used in tandem with `length`.
			 */
			maxLength: number;

			/**
			 * This is used to allow you to define a css subclass in a separate scss file
			 * and pass the value of the class into this component.  This is useful when
			 * you need to define unique styles for multiple TextFields
			 */
			cssSubClass?: string;
		},
		[['minLength', 'maxLength']]
	> &
	OneMaxOf<{
		/**
		 * Whether this field must be formatted as a valid email address. This may not
		 * be used in tandem with `url` or `pattern`.
		 */
		email: true;

		/**
		 * Whether this field must be formatted as a valid URL. This may not be used
		 * in tandem with `email` or `pattern`.
		 */
		url: true;

		/**
		 * A regular expression this field must be match. This may not be used in
		 * tandem with `email` or `url`.
		 */
		pattern: RegExp;
	}>;

/**
 * A `Field` used for single-line text values.
 */
export default function TextField({
	name,
	noun = name,
	email,
	label,
	inputMode,
	length,
	maxLength,
	minLength,
	pattern,
	placeholder,
	required,
	test,
	url,
	autoComplete = 'off',
	info,
	cssSubClass,
}: TextFieldProps): JSX.Element {
	let schema = string().label(noun);
	if (email) schema = schema.email();
	if (url) schema = schema.url();
	if (pattern) schema = schema.matches(pattern);
	if (required) schema = schema.trim().required();
	forArrayOrSingle(test, t => {
		schema = schema.test(t);
	});
	if (typeof length !== 'undefined') schema = schema.length(length);
	if (typeof minLength !== 'undefined') schema = schema.min(minLength);
	if (typeof maxLength !== 'undefined') schema = schema.max(maxLength);

	return (
		<div
			className={classNames(
				styles['textfield-wrap'],
				cssSubClass && cssSubClass
			)}
		>
			<Field
				name={name}
				noun={noun}
				label={label}
				required={required}
				schema={schema}
				muteErrors={['max']}
				render={({ field, lastError, error }) => (
					<Input
						inputMode={inputMode}
						{...field}
						fixed={lastError && !error}
						invalid={!!error}
						autoComplete={autoComplete}
						placeholder={placeholder}
						data-qa={name}
					/>
				)}
				renderInfo={({ value, error }) => {
					const valueLength = typeof value === 'string' ? value.length : 0;
					const remaining = (maxLength || length || 0) - valueLength;
					return (
						<div>
							{maxLength && (
								<FieldInfoRemaining
									tooMany={error && remaining < 0}
									data-qa={`${name}-hint`}
								>
									<FontAwesomeIcon fixedWidth icon={faKeyboard} />
									<CharacterLimitMessage remaining={remaining} />
								</FieldInfoRemaining>
							)}
							{info && (
								<span>
									<em>{info}</em>
								</span>
							)}
						</div>
					);
				}}
			/>
		</div>
	);
}

import React from 'react';
import { Helmet } from 'react-helmet';
import {
	UPLOADS_CDN_URL,
	AVATAR_CDN_URL,
	getImageUrl,
} from 'src/components/Image';

interface ImageMetaTagTemplateProps {
	title?: string;
	type?: string;
	siteName?: string;
	url?: string;
	src?: string;
	isAvatar?: boolean;
}

interface ImageMetaTagTemplateComponent {
	(props: ImageMetaTagTemplateProps): JSX.Element;
}

const ImageMetaTagTemplate: ImageMetaTagTemplateComponent = ({
	title = 'LEGO® World Builder',
	type = 'website',
	siteName = 'LEGO® World Builder',
	url = '',
	src = 'static/assets/images/meta/Homepage.png',
	isAvatar = false,
}) => {
	const cdnUrl = isAvatar ? AVATAR_CDN_URL : UPLOADS_CDN_URL;
	const image = getImageUrl({ src, cdnUrl, width: 800 });

	return (
		<Helmet>
			<meta name="og:title" content={title} />
			<meta name="og:type" content={type} />
			<meta name="og:url" content={`${process.env.REACT_APP_URL}/${url}`} />
			<meta name="og:image" content={image} />
			<meta name="og:site_name" content={siteName} />
		</Helmet>
	);
};

export default ImageMetaTagTemplate;

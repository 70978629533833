import React, { useCallback } from 'react';

import authService from 'src/auth/authService';

import { Button } from 'src/elements';
import legoHead401 from './lego-head-401.svg';
import ErrorPage from './ErrorPage';
import styles from './ErrorPage.module.scss';

export const ErrorPage401: React.FC = () => {
	const signIn = useCallback(async () => {
		await authService.signIn();
	}, []);

	const signUp = useCallback(async () => {
		await authService.signUp();
	}, []);

	return (
		<ErrorPage
			subtitle="Have we met?"
			image={legoHead401}
			content={
				<>
					<p>To view this page, please sign up or log in to your account</p>

					<div className={styles['action-buttons']}>
						<Button onClick={signIn}>Log In</Button>
						<Button onClick={signUp} white>
							Register
						</Button>
					</div>
				</>
			}
		/>
	);
};

export default ErrorPage401;

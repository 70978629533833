import { ComponentPropsWithoutRef } from 'react';
import { withHooks } from 'src/common/utils';
import { mapHooksToProps } from 'src/common/utils/withHooks';
import SkipToContentTemplate from './SkipToContent';
import useSkipToContent, { KeyboardEventType } from './useSkipToContent';

/**
 * Props that can be passed to the `SkipToContent` component.
 */
export interface SkipToContentProps {
	skipToContent?: (event: KeyboardEventType) => void;
}

/**
 * @since 1.0.0
 * @module SkipToContent module
 * @summary Allows users to skip past the navigation elements while
 * using screen readers
 * @example
 * import useSkipToContent from './useSkipToContent';
 * import React, { useEffect } from 'react';
 *
 * const MyPageComponent = ():JSX.Element => {
 * 	const { focusSkipToContentButton, setTargetContent } = useSkipToContent();
 * 	useEffect(() => {
 * 		//(optional) If you need to target an ID other than 'main'
 * 		setTargetContent('a_different_id');
 * 		focusSkipToContentButton();
 * 	});
 * }
 */
const SkipToContent = withHooks(
	SkipToContentTemplate,
	mapHooksToProps<
		SkipToContentProps,
		ComponentPropsWithoutRef<typeof SkipToContentTemplate>
	>(props => {
		const { skipToContent } = useSkipToContent();

		return {
			skipToContent: skipToContent,
		};
	})
);

export default SkipToContent;

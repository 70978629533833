import React from 'react';

import legoHead404 from './lego-head-404.svg';
import ErrorPage from './ErrorPage';

export const ErrorPage404: React.FC = () => (
	<ErrorPage
		subtitle="There's a brick missing."
		image={legoHead404}
		showHomeButton
		content={
			<p>
				The page you’re looking for was not found.
				<br />
				You may have mistyped the address or it may have been deleted or moved.
			</p>
		}
	/>
);

export default ErrorPage404;

import { PageErrorRouteTest } from 'src/common/PageErrorRoute';
import { useCurrentUserContext } from 'src/hooks';

const useUpdateCreativePromptsTest: PageErrorRouteTest<{}> = () => {
	const { loading, error, currentUser } = useCurrentUserContext();

	if (loading) {
		return { loading };
	} else if (error) {
		return 'UNKNOWN';
	} else if (!currentUser) {
		return 'UNAUTHENTICATED';
	} else if (!currentUser?.permissions.includes('CREATIVE_PROMPTS__WRITE')) {
		return 'UNAUTHORIZED';
	}
};

export default useUpdateCreativePromptsTest;

import { on } from '../trackingService';

// Show gtag in the console when not sending to GA (local/dev)
const gtag: typeof window.gtag = (...args: unknown[]) => {
	if (
		(process.env.REACT_APP_GA_ID && window.gtag) ||
		process.env.NODE_ENV === 'test'
	) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
		// @ts-ignore
		window.gtag(...args);
	} else {
		// eslint-disable-next-line no-console
		console.debug(
			'%cgtag',
			'background: #444; color: #4fcad1; padding: 1px 3px; border-radius: 4px',
			...args
		);
	}
};

on(
	'ELEMENT_CLICK',
	({ elementCategory, elementName, actionName = 'Click' }) => {
		gtag('event', actionName, {
			event_category: elementCategory,
			event_label: elementName,
		});
	}
);

on(
	'FORM_ABANDONED',
	({
		formName,
		touchedFieldNames,
		actionName = `Form Abandoned > ${touchedFieldNames.join(' > ')}`,
		itemName = formName,
	}) => {
		gtag('event', actionName, {
			event_category: formName,
			event_label: itemName,
		});
	}
);

on(
	'FORM_COMPLETE',
	({ formName, actionName = 'Form Complete', itemName = formName }) => {
		gtag('event', actionName, {
			event_category: formName,
			event_label: itemName,
		});
	}
);

on('INIT', ({ userId }) => {
	gtag('set', { user_id: userId });
});

on('DENY_CONSENT', () => {
	gtag('consent', 'update', {
		ad_storage: 'denied',
		analytics_storage: 'denied',
	});
});

on('PAGEVIEW', () => {
	gtag('config', process.env.REACT_APP_GA_ID, {
		page_location: window.location.pathname,
	});
});

on('SCROLL_DEPTH', ({ depth, pageName }) => {
	gtag('event', `${depth}%`, {
		event_category: 'Scroll Depth',
		event_label: pageName,
	});
});

on('VIDEO_COMPLETE', ({ videoName }) => {
	gtag('event', 'Completed', {
		event_category: 'Video',
		event_label: videoName,
	});
});

on('VIDEO_PAUSE', ({ videoName }) => {
	gtag('event', 'Pause', {
		event_category: 'Video',
		event_label: videoName,
	});
});

on('VIDEO_PLAY', ({ videoName }) => {
	gtag('event', 'Play', {
		event_category: 'Video',
		event_label: videoName,
	});
});

on('VIDEO_PROGRESS', ({ videoName, progress }) => {
	gtag('event', `${progress}%`, {
		event_category: 'Video',
		event_label: videoName,
	});
});

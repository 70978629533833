import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
	faFacebook,
	faInstagram,
	faTwitter,
	IconDefinition as BrandIconDefinition,
	faDiscord,
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import legoLogo from 'src/assets/images/lego-logo.svg';
import tongalLogo from 'src/assets/images/tongal-logo.svg';

import { Access, Permissions, requestAccessForUser } from 'src/domain/auth';
import { useCurrentUserContext, useMachine } from 'src/hooks';

import useClickTracking from 'src/tracking/hooks/useClickTracking';
import { Container } from 'src/elements';
import stateMachine from './Footer.state';
import styles from './Footer.module.scss';

interface TrackedLinkProps {
	name: string;
	to: string;
	children: React.ReactNode;
	external?: boolean;
	className?: string;
}

const TrackedLink = ({
	name,
	to,
	external,
	...commonProps
}: TrackedLinkProps): JSX.Element => {
	const elemRef = useClickTracking({
		elementCategory: 'Footer',
		elementName: name,
		actionName: 'Navigation',
	});
	return external ? (
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		<a
			href={to}
			target="_blank"
			rel="noopener noreferrer"
			ref={elemRef}
			{...commonProps}
		/>
	) : (
		<Link to={to} ref={elemRef} {...commonProps} />
	);
};

const SocialIcon = ({ icon }: { icon: BrandIconDefinition }) => (
	<FontAwesomeIcon size={'2x'} icon={icon as IconDefinition} />
);

/**
 * Document the component w/ usage here.
 */
const Footer: React.FC = () => {
	const { currentUser } = useCurrentUserContext();
	const [state, send] = useMachine(stateMachine);

	useEffect(() => {
		if (
			currentUser &&
			requestAccessForUser(currentUser, Permissions.AdminPagesPage.READ) ===
				Access.Granted
		) {
			send({ type: 'GRANT_ROLE' });
		} else {
			send({ type: 'REVOKE_ROLE' });
		}
	}, [currentUser, send, state]);

	return (
		<footer className={styles['footer']}>
			<Container>
				<div className={styles['footer-wrapper']}>
					<TrackedLink
						name="Tongal logo"
						to="https://welcome.tongal.com"
						className={styles['tongal-logo-wrapper']}
						external
					>
						<img src={tongalLogo} alt="Tongal logo" />
					</TrackedLink>
					<TrackedLink
						name="LEGO logo"
						to="https://www.lego.com/"
						className={styles['lego-logo-wrapper']}
						external
					>
						<img src={legoLogo} alt="LEGO logo" />
					</TrackedLink>
					<ul className={styles['footer-links']}>
						<li>
							<TrackedLink
								name="Help Center"
								to="https://help.worldbuilder.tongal.com"
								external
							>
								Help center
							</TrackedLink>
						</li>
						<li>
							<TrackedLink name="Feedback" to="/feedback">
								Feedback
							</TrackedLink>
						</li>
						<li>
							<TrackedLink name="Terms of Service" to="/termsofservice">
								Terms of Service
							</TrackedLink>
						</li>
					</ul>
				</div>
				<ul className={styles['footer-social-links']}>
					<li>
						<TrackedLink
							name="Discord"
							to="https://discord.gg/XxkVjGauMX"
							external
						>
							<SocialIcon icon={faDiscord} />
						</TrackedLink>
					</li>
					<li>
						<TrackedLink
							name="Facebook"
							to="https://www.facebook.com/LEGOWorldBuilder"
							external
						>
							<SocialIcon icon={faFacebook} />
						</TrackedLink>
					</li>
					<li>
						<TrackedLink
							name="Instagram"
							to="https://www.instagram.com/legoworldbuilder/"
							external
						>
							<SocialIcon icon={faInstagram} />
						</TrackedLink>
					</li>
					<li>
						<TrackedLink
							name="Twitter"
							to="https://twitter.com/LEGOworldbuildr"
							external
						>
							<SocialIcon icon={faTwitter} />
						</TrackedLink>
					</li>
				</ul>
			</Container>
			<div className={styles['footer-text']}>
				LEGO and the LEGO logo are trademarks of the LEGO Group.
			</div>
		</footer>
	);
};

export default Footer;

import * as styledComponents from 'styled-components';

import tailwindConfig from 'src/tailwind.config';

export interface Theme {
	primaryColor: string;
}

const {
	default: styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export const theme: Theme = {
	primaryColor: 'rgb(20, 20, 34)',
};

const { screens, colors } = tailwindConfig.theme;

export default styled;
export {
	styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
	screens,
	colors,
};

import React from 'react';
import TooltipTrigger, { TooltipTriggerProps } from 'react-popper-tooltip';

import 'react-popper-tooltip/dist/styles.css';

interface TooltipProps
	extends Omit<Partial<TooltipTriggerProps>, 'children' | 'tooltip'> {
	children: React.ReactNode;
	/**
	 * The content of the tooltip
	 * If nothing is passed, the tooltip will render the children without any wrapper
	 */
	tooltip?: React.ReactNode;
	hideArrow?: boolean;
}

interface TooltipComponent {
	(props: TooltipProps): JSX.Element;
}

/**
 * Generic tooltip component. You can choose positioning and what will trigger
 * it to show. It will stay on-screen as much as possible.
 *
 * @example
 * <Tooltip tooltip="World">
 *   Hello
 * </Tooltip>
 */
const Tooltip: TooltipComponent = ({
	children,
	tooltip,
	hideArrow,
	tooltipShown,
	...props
}) => {
	if (!tooltip) {
		return <>{children}</>;
	}

	return (
		<TooltipTrigger
			{...props}
			tooltipShown={tooltipShown ?? tooltip ? undefined : false}
			tooltip={({
				arrowRef,
				tooltipRef,
				getArrowProps,
				getTooltipProps,
				placement,
			}) => (
				<div
					{...getTooltipProps({
						ref: tooltipRef,
						className: 'tooltip-container',
					})}
				>
					{!hideArrow && (
						<div
							{...getArrowProps({
								ref: arrowRef,
								className: 'tooltip-arrow',
								'data-placement': placement,
							})}
						/>
					)}
					{tooltip}
				</div>
			)}
		>
			{({ getTriggerProps, triggerRef }) => (
				<span
					{...getTriggerProps({
						ref: triggerRef,
						className: 'trigger',
						style: { display: 'inline-block' },
					})}
				>
					{children}
				</span>
			)}
		</TooltipTrigger>
	);
};

export default Tooltip;

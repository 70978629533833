import tw from 'tailwind.macro';

import styled from 'src/theme';

export const MainTitle = styled.h1`
	${tw`
		my-6
		sm:my-16
		md:my-24
		text-center
	`}
`;

export const CreatePageContent = styled.div`
	width: 800px;
	margin-bottom: 3rem;
	${tw`
		max-w-full
		mx-auto
	`}
`;

export const ErrorMessage = styled.p`
	${tw`
		text-red
	`}
`;

export const FieldInfo = styled.div`
	margin: 0;
	margin-bottom: 0.5rem;

	svg {
		margin-right: 0.25rem;
	}
`;

import { Flatten } from '../typescript';

/**
 * The core event that all events must extend. All events must have a string
 * `type`.
 */
type BaseEvent = { type: string };

/**
 * Helper to get all dataless event types extracted out of an event object.
 *
 * @example
 * type Event = { type: 'ON'; data: string } | { type: 'OFF' };
 * type Test = DatalessEventTypes<Event>;
 * // Type: 'OFF'
 */
type DatalessEventTypes<TEvent extends BaseEvent> = Flatten<
	{
		[K in TEvent['type']]: keyof Extract<TEvent, { type: K }> extends 'type'
			? K
			: never;
	}
>;

/**
 * Helper to map events to an object of event type to a set of callbacks. This
 * is used to add new callbacks or iterate to call existing callbacks.
 *
 * @example
 * type Event = { type: 'ON' | 'OFF' };
 * type Test = EventListenersMap<Event>;
 * // Test: { ON: Set, OFF: Set }
 */
type EventListenersMap<TEvent extends BaseEvent> = {
	[K in TEvent['type']]: Set<(event: Extract<TEvent, { type: K }>) => void>;
};

/**
 * A generic event service. The `TEvent` generic parameter should contain a type
 * for all events.
 */
export interface EventService<TEvent extends BaseEvent> {
	/**
	 * Send an event to the service.
	 * @param event An event object, or just event type for simple events.
	 */
	// Generic for better error messaging when type is not provided.
	send(event: TEvent): void;
	send(event: DatalessEventTypes<TEvent>): void;

	/**
	 * Register a callback to run synchronously when the event is triggered. A
	 * callback may only be registered one time max per event type.
	 * @param eventType The event type to listen for.
	 * @param callback The callback to run when the event is found.
	 * @returns Degregister function to remove this listener.
	 */
	on<TEventType extends TEvent['type']>(
		eventType: TEventType,
		callback: (event: Extract<TEvent, { type: TEventType }>) => void
	): () => boolean;
}

/**
 * Creates an EventService.
 *
 * @example <caption>Simple ON/OFF</caption>
 * type Event = { type: 'ON' | 'OFF' };
 * const eventService = createEventService<Event>();
 *
 * @example <caption>ON/OFF with data</caption>
 * type Event = { type: 'ON'; data: string } | { type: 'OFF' };
 * const eventService = createEventService<Event>();
 */
export default function createEventService<
	TEvent extends BaseEvent
>(): EventService<TEvent> {
	const events: Partial<EventListenersMap<TEvent>> = {};
	return {
		send(eventOrType: TEvent | DatalessEventTypes<TEvent>) {
			type LocalEvent = Extract<TEvent, { type: TEvent['type'] }>;
			let event: LocalEvent;
			let eventType: TEvent['type'];
			if (typeof eventOrType === 'string') {
				eventType = eventOrType;
				event = { type: eventType } as LocalEvent;
			} else {
				eventType = eventOrType.type;
				event = eventOrType as LocalEvent;
			}

			events[eventType]?.forEach(callback => callback(event));
		},
		on(eventType, callback) {
			const eventSet: EventListenersMap<TEvent>[typeof eventType] =
				events[eventType] ?? new Set();
			if (!events[eventType]) {
				events[eventType] = eventSet;
			}
			eventSet.add(callback);
			return () => eventSet.delete(callback);
		},
	};
}

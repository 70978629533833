import useGetGenericUser from 'src/schema/profile/useGetGenericUser';
import { PageErrorRouteTest } from 'src/common/PageErrorRoute';

const useProfileViewTest: PageErrorRouteTest<{ username: string }> = ({
	match: {
		params: { username },
	},
}) => {
	const { data, loading, error } = useGetGenericUser({
		username,
	});

	if (loading) {
		return { loading };
	} else if (!data || !data?.userByUsername) {
		return 'NOT_FOUND';
	} else if (error) {
		return 'UNKNOWN';
	}
};

export default useProfileViewTest;

import { Ref, useCallback, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type DOMRectReadOnly = ResizeObserverEntry['contentRect'];
type UseMeasureTuple<E> = [{ ref: Extract<Ref<E>, Function> }, DOMRectReadOnly];

export function useMeasure<E extends Element = Element>(): UseMeasureTuple<E> {
	const [bounds, setBounds] = useState<DOMRectReadOnly>({
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		x: 0,
		y: 0,
		width: 0,
		height: 0,
	});

	const [resizeObserver] = useState(
		() => new ResizeObserver(([entry]) => entry && setBounds(entry.contentRect))
	);

	/**
	 * Track element that currently has the `ref` attribute.
	 */
	const ref = useCallback(
		(element: E | null): void => {
			resizeObserver.disconnect();
			if (element) {
				resizeObserver.observe(element);
			}
		},
		[resizeObserver]
	);

	return [{ ref }, bounds];
}

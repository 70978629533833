import React, { ComponentPropsWithRef, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { ValidationError } from 'yup';
import { Button } from 'src/elements';
import Tooltip from '../Tooltip';

import styles from './SubmitButton.module.scss';

interface SubmitButtonProps extends ComponentPropsWithRef<typeof Button> {
	/**
	 * Formik field value to set to `true` when the button is clicked.
	 */
	setFlag?: string;

	/**
	 * Formik field value to set to `false` when the button is clicked.
	 */
	unsetFlag?: string;

	disableWhenPristine?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
	children,
	setFlag,
	unsetFlag,
	onClick,
	disableWhenPristine,
	...props
}) => {
	const {
		setFieldValue,
		isSubmitting,
		errors,
		isValid,
		dirty,
	} = useFormikContext<
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		any
	>();
	const { disabled } = props;

	const combinedOnClick = useCallback<Exclude<typeof onClick, undefined>>(
		event => {
			if (setFlag) {
				setFieldValue(setFlag, true, false);
			}
			if (unsetFlag) {
				setFieldValue(unsetFlag, false, false);
			}
			return onClick?.(event);
		},
		[onClick, setFlag, setFieldValue, unsetFlag]
	);

	const errorList = useMemo(
		() =>
			!!Object.keys(errors).length && (
				<div className={styles['errors']}>
					<strong>Please fix the following before submitting:</strong>
					<ul>
						{((Object.entries(errors) as unknown) as [
							string,
							ValidationError
						][]).map(([prop, { message }]) => (
							<li key={prop}>{message}</li>
						))}
					</ul>
				</div>
			),
		[errors]
	);

	return (
		<Tooltip placement="top" tooltip={errorList}>
			<Button
				{...props}
				type="submit"
				onClick={combinedOnClick}
				disabled={
					disabled ||
					isSubmitting ||
					!isValid ||
					(disableWhenPristine && !dirty)
				}
			>
				{children}
			</Button>
		</Tooltip>
	);
};

export default SubmitButton;

import { LogLevel } from './Logger';
import possibleLogLevels from './possibleLogLevels';

const isLogLevel = (value: string): value is LogLevel =>
	(possibleLogLevels as ReadonlyArray<string>).includes(value);

const sentryLoggingThreshold =
	(process.env.REACT_APP_SENTRY_LOGGING_THRESHOLD &&
		process.env.REACT_APP_SENTRY_LOGGING_THRESHOLD.split(' ')) ||
	[];

const enabledSentryLogLevels: ReadonlyArray<LogLevel> = sentryLoggingThreshold.filter(
	isLogLevel
);

export default enabledSentryLogLevels;

// After exporting, validate the logging. We need to do this after so that we
// can access the logger since it will be a circular dependency.

const invalidLogLevels = sentryLoggingThreshold.filter(
	logLevel => !isLogLevel(logLevel)
);

if (invalidLogLevels.length) {
	// eslint-disable-next-line no-console
	console.error(
		`Invalid log level${
			invalidLogLevels.length === 1 ? '' : 's'
		} found: ${invalidLogLevels}`
	);
}

import { Machine, State as XState } from 'xstate';

import { BaseEvent, MakeSchema } from 'src/common/xstate';

export type Schema = MakeSchema<{
	noFileSpecified: {};
	usingUploadedFile: {};
	fileUploadError: {};
	uploadInProgress: {};
	uploadFinished: {};
}>;

export interface Context {}

export type Event =
	| {
			type:
				| 'REMOVE_FILE'
				| 'USE_UPLOADED_FILE'
				| 'FILE_UPLOAD_ERROR'
				| 'UPLOAD_IN_PROGRESS'
				| 'UPLOAD_FINISHED';
	  }
	| BaseEvent;

export type State = XState<Context, Event>;

export default Machine<Context, Schema, Event>({
	initial: 'noFileSpecified',
	states: {
		noFileSpecified: {
			on: {
				USE_UPLOADED_FILE: 'usingUploadedFile',
				UPLOAD_IN_PROGRESS: 'uploadInProgress',
				UPLOAD_FINISHED: 'uploadFinished',
			},
		},
		usingUploadedFile: {
			on: {
				REMOVE_FILE: 'noFileSpecified',
			},
		},
		fileUploadError: {
			on: {
				FILE_UPLOAD_ERROR: 'fileUploadError',
			},
		},
		uploadInProgress: {
			on: {
				UPLOAD_FINISHED: 'uploadFinished',
			},
		},
		uploadFinished: {
			on: {
				UPLOAD_IN_PROGRESS: 'uploadInProgress',
			},
		},
	},
});

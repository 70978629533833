import { useEffect, useMemo, useRef, useState } from 'react';
import {
	EventObject,
	interpret,
	Interpreter,
	InterpreterOptions,
	State,
	StateMachine,
	StateSchema,
	StateValue,
} from 'xstate';

import { useMachineInitContext } from 'src/common/xstate';

export type UseMachineTuple<
	TContext,
	TSchema extends StateSchema,
	TEvent extends EventObject
> = [
	State<TContext, TEvent>,
	Interpreter<TContext, TSchema, TEvent>['send'],
	Interpreter<TContext, TSchema, TEvent>
];

/**
 * Use a state machine in a funcitonal component. The machine can be augmented
 * by using the MachineInitContext.
 * @see MachineInitContext
 * @param machine The machine you will be using.
 * @param overrideContext Allows you to override the context directly, using a
 * shallow merge strategy.
 * @param options interpreter options when creating the service
 */
export default function useMachine<
	TContext,
	TSchema extends StateSchema,
	TEvent extends EventObject
>(
	machine: StateMachine<TContext, TSchema, TEvent>,
	overrideContext?: Partial<TContext>,
	options?: Partial<InterpreterOptions>
): UseMachineTuple<TContext, TSchema, TEvent> {
	const serviceRef = useRef<
		Interpreter<TContext, TSchema, TEvent> | undefined
	>();
	const {
		initial,
		disableSend,
		context: initContext,
		onTransition,
	} = useMachineInitContext(machine);

	const firstRun = !serviceRef.current;
	// Create the service only once
	if (!serviceRef.current) {
		serviceRef.current = interpret(machine, options);

		let context: TContext | undefined;
		if (machine.context || overrideContext || initContext) {
			context = {
				...machine.context,
				...(overrideContext as TContext),
				...(initContext as TContext),
			};
		}

		let initialState: StateValue;
		if (initial) {
			initialState = initial as StateValue;
		} else {
			initialState = machine.initialState.value;
		}

		serviceRef.current.initialState = machine.getInitialState(
			initialState,
			context
		);
	}

	const service = serviceRef.current;
	const [state, setState] = useState(service.initialState);

	if (firstRun) {
		service
			.onTransition((newState, event) => {
				if (newState.changed) {
					setState(newState);
				}
				if (onTransition) {
					onTransition(newState, event);
				}
			})
			.start(state);
	}

	useEffect(
		() => () => {
			service.stop();
		},
		[service]
	);

	// Allow `state` to be used in the `useMemo` without adding a dependency
	const stateRef = useRef(state);
	stateRef.current = state;

	const send = useMemo<Interpreter<TContext, TSchema, TEvent>['send']>(
		() =>
			disableSend
				? (event, payload) => {
						if (typeof disableSend === 'object') {
							if (event instanceof Array) {
								const events = event.filter(omniEvent => {
									const type =
										typeof omniEvent === 'object' ? omniEvent.type : omniEvent;
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									return (disableSend as any)[type];
								});
								if (events.length) {
									return service.send(events, payload);
								}
							} else {
								const type = typeof event === 'object' ? event.type : event;
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								if (!(disableSend as any)[type]) {
									return service.send(event, payload);
								}
							}
						}
						return stateRef.current;
				  }
				: service.send,
		[disableSend, service]
	);

	return [state, send, service];
}

import { useState, useEffect } from 'react';
import logger from 'src/common/logger';

type CountriesData = { [key: string]: string };
type RestrictedType = { [key: string]: string };

interface GetCountriesInterface {
	countriesData: CountriesData[] | undefined;
	error: string | undefined;
	getCountryData: (url?: string) => Promise<CountriesData[]>;
	loading: boolean;
}

/**
 * @since 1.0.0
 * @module useGetCountries
 * @summary Used to retrieve a list of countries and their respective codes
 * @example
 * const { countriesData, error, loading } = useGetCountries();
 */
const useGetCountries = (): GetCountriesInterface => {
	const [countries, setCountries] = useState<CountriesData[] | undefined>();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [dataFetchError, setDataFetchError] = useState<string | undefined>();

	/**
	 * @function getCountryData
	 * @param url {String}
	 * Used to fetch the countries from a given url or the supplied default
	 * @returns Promise<any>
	 */

	const getCountryData = async (url?: string) => {
		const endpoint =
			url || 'https://country-data-json.s3.amazonaws.com/countriesV2.json';

		try {
			setLoading(true);

			const response = await fetch(endpoint, {
				method: 'GET',
				cache: 'default',
			});

			const json = await response.json();

			return json;
		} catch (err) {
			setLoading(false);

			logger.error(err, {
				context: {
					'Operation Name': 'useGetCountries: getCountryData',
				},
			});

			return null;
		}
	};

	/**
	 * Called to set the country data on the countries state object,
	 * the loading state value and if a network failure occurs, the dataFetchError string
	 */
	useEffect(() => {
		const initCountryData = async () => {
			const restrictedCountryS3 =
				'https://country-data-json.s3.amazonaws.com/restricted-countries.json';

			try {
				const defaultCountriesList = await getCountryData();
				setCountries(defaultCountriesList);
				setLoading(false);

				const restrictedCountries = await getCountryData(restrictedCountryS3);
				setLoading(false);

				const restricted: RestrictedType = restrictedCountries.numericCode;

				const filteredCountryData: CountriesData[] =
					defaultCountriesList &&
					defaultCountriesList.filter(
						(countryJsonItem: CountriesData) =>
							countryJsonItem.numericCode !==
							restricted[countryJsonItem.numericCode]
					);

				setCountries(filteredCountryData);
			} catch (err) {
				setDataFetchError(err);
			}
		};

		initCountryData();
	}, []);

	return {
		countriesData: countries,
		error: dataFetchError,
		getCountryData,
		loading: isLoading,
	};
};

export default useGetCountries;

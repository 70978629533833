import React, { createContext, useCallback, useState } from 'react';
import { useMachine } from 'src/hooks';
import stateMachine from './FileUploaderProvider.state';

export interface FileUploaderProviderType {
	uploadInProgress: () => void;
	uploadFinished: () => void;
	uploadComplete: boolean | undefined;
	noUploadInitialized: boolean | undefined;
}

export const FileUploaderContext = createContext<FileUploaderProviderType>({
	noUploadInitialized: true,
	uploadComplete: false,
	uploadFinished: () => {
		throw new Error(
			'Attempted to call uploadFinished without FileUploaderProvider'
		);
	},
	uploadInProgress: () => {
		throw new Error(
			'Attempted to call uploadInProgress without FileUploaderProvider'
		);
	},
});

/**
 * @since 1.0.0
 * @module FileUploaderProvider
 * @summary This provider allows you to gain access to the uploadFinished, uploadInProgress
 * and uploadComplete state when using the FileUploaderField.  You can
 * gain access to this state via the 'uploadComplete' variable.
 * This value is predetermined within the onUpload and onComplete
 * methods of the FileUploaderField index.ts file: To use, see example below
 * @param {children} React.children
 * @example
 * const { uploadComplete } = useFileUploader();
 */
const FileUploaderProvider: React.FC = ({ children }) => {
	const [state, send] = useMachine(stateMachine);

	const [noUploadInitialized, setNoUploadInitialized] = useState<boolean>(true);

	const uploadComplete = state.matches('uploadFinished');

	const uploadFinished = useCallback(() => {
		send({
			type: 'UPLOAD_FINISHED',
		});
		setNoUploadInitialized(!state.matches('uploadFinished'));
	}, [send, state]);

	const uploadInProgress = useCallback(() => {
		send({
			type: 'UPLOAD_IN_PROGRESS',
		});
		setNoUploadInitialized(!state.matches('uploadFinished'));
	}, [send, state]);

	const fileUploaderContextProperties = {
		uploadComplete,
		uploadFinished,
		uploadInProgress,
		noUploadInitialized,
	};

	return (
		<FileUploaderContext.Provider value={fileUploaderContextProperties}>
			{children}
		</FileUploaderContext.Provider>
	);
};

export default FileUploaderProvider;

import { useCallback } from 'react';
import { mapHooksToProps } from 'src/common/utils/withHooks';
import authService from 'src/auth/authService';
import withHooks from 'src/common/utils/withHooks';
import CookieBannerTemplate from './CookieBanner';

const CookieBanner = withHooks(
	CookieBannerTemplate,
	mapHooksToProps(() => {
		const signUp = useCallback(async () => {
			await authService.signUp();
		}, []);

		return {
			signUp,
		};
	})
);

export default CookieBanner;

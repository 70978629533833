import { ApolloClient } from '@apollo/client';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import {
	PostAuthAdditionalInfo,
	PostAuthAdditionalInfoVariables,
} from 'src/schema/generated/PostAuthAdditionalInfo';
import {
	GetAuthAdditionalInfoInitialFormState,
	GetAuthAdditionalInfoInitialFormStateVariables,
} from 'src/schema/generated/GetAuthAdditionalInfoInitialFormState';
import GetAuthAdditionalInfoInitialFormStateQuery from './schema/GetAuthAdditionalInfoInitialFormStateQuery';
import PostAuthAdditionalInfoMutation from './schema/PostAuthAdditionalInfoMutation';

export interface AuthAdditionalInformationPageFormData {
	userName: string;
	emailAddress: string;
	birthdate: string;
	country: { value: number; label: string } | null;
	receiveNewsletters: boolean;
	acceptCookies: boolean;
	acceptTerms: boolean;
}

class AuthAdditionalInformationPageService {
	constructor(private apolloClient: ApolloClient<{}>) {}

	async getInitialData(
		sessionToken: string,
		state: string
	): Promise<AuthAdditionalInformationPageFormData> {
		const {
			data: { getAuthAdditionalInfoInitialFormState: data },
		} = await this.apolloClient.query<
			GetAuthAdditionalInfoInitialFormState,
			GetAuthAdditionalInfoInitialFormStateVariables
		>({
			query: GetAuthAdditionalInfoInitialFormStateQuery,
			variables: { sessionToken: sessionToken, state: state },
		});

		return {
			acceptCookies: false,
			acceptTerms: false,
			receiveNewsletters: false,
			birthdate: data.birthdate || '',
			country:
				data.countryName && data.countryNumericCode
					? {
							label: data.countryName,
							value: data.countryNumericCode,
					  }
					: null,
			userName: data.userName || '',
			emailAddress: data.emailAddress || '',
		};
	}

	async submitData(
		sessionToken: string,
		state: string,
		{ country, ...input }: AuthAdditionalInformationPageFormData
	) {
		const { data } = await this.apolloClient.mutate<
			PostAuthAdditionalInfo,
			PostAuthAdditionalInfoVariables
		>({
			mutation: PostAuthAdditionalInfoMutation,
			variables: {
				sessionToken: sessionToken,
				state: state,
				input: {
					...input,
					countryName: country!.label,
					countryNumericCode: country!.value,
				},
			},
		});

		return data!.postAuthAdditionalInfo;
	}
}

export default AuthAdditionalInformationPageService;

import React, { ComponentPropsWithRef } from 'react';

import { Button } from './Button';

type NavButtonProps = ComponentPropsWithRef<typeof Button> & {
	navigate: () => void;
};

/**
 * A wrapped <Button> component for use with the `<Link>` component.
 * @example
 * <Link component={NavButton} to="/somewhere">Go Somewhere</Link>
 */
export const NavButton = React.forwardRef(
	({ navigate, ...props }: NavButtonProps, ref): JSX.Element => {
		return React.createElement(Button, {
			...props,
			onClick: navigate,
			ref,
		});
	}
);

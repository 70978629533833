import React from 'react';

import { Button } from 'src/elements';
import { useCurrentUserContext } from 'src/hooks';

import UserAvatar from '../UserAvatar';
import { DropdownList } from './DropdownList';
import NavLink, { NavLinkItem } from './NavLink';
import {
	DesktopDiv,
	DesktopLi,
	LinkList,
	MobileDiv,
	ProfileSection,
	UnauthenticatedProfile,
	UserPictureLabel,
} from './PageNavigation.style';

interface ProfileNavLinkProps {
	navLinks: NavLinkItem[];
	signIn: () => Promise<void>;
	signUp: () => Promise<void>;
	logOut: () => Promise<void>;
	loading: boolean;
}

export const ProfileNavLink: React.FC<ProfileNavLinkProps> = ({
	navLinks,
	signIn,
	signUp,
	logOut,
	loading,
}) => {
	const { currentUser } = useCurrentUserContext();

	return (
		<ProfileSection>
			{!currentUser && (
				<UnauthenticatedProfile>
					<DesktopDiv>
						<LinkList>
							<li key="signUp">
								<Button nav onClick={signUp} aria-label="Register">
									Register
								</Button>
							</li>
							<span />
							<li key="signIn">
								<Button
									data-qa="sign-in-button"
									aria-label="Log In"
									nav
									onClick={signIn}
									disabled={loading}
								>
									{loading ? 'Logging In…' : 'Log In'}
								</Button>
							</li>
						</LinkList>
					</DesktopDiv>

					<MobileDiv>
						<div>
							<Button white onClick={signUp} aria-label="Register">
								Register
							</Button>
						</div>
						<div>
							<Button
								onClick={signIn}
								disabled={loading}
								aria-label="Log In"
								data-qa="sign-in-button-mobile"
							>
								{loading ? 'Logging In…' : 'Log In'}
							</Button>
						</div>
					</MobileDiv>
				</UnauthenticatedProfile>
			)}

			{!!currentUser && (
				<DropdownList
					label={
						<>
							<UserPictureLabel>
								{currentUser ? (
									<UserAvatar userId={currentUser.id} variant={'small'} />
								) : null}
							</UserPictureLabel>
							<span data-qa="current-user" aria-label="User Name">
								{currentUser && currentUser.name}
							</span>
						</>
					}
				>
					<LinkList>
						{navLinks.map(navLink => (
							<NavLink key={navLink.label} navLink={navLink} />
						))}

						<DesktopLi key="logOut">
							<Button data-qa="logout-navbar-link" nav onClick={logOut}>
								Log Out
							</Button>
						</DesktopLi>
					</LinkList>
				</DropdownList>
			)}
		</ProfileSection>
	);
};

export default ProfileNavLink;

import { useEffect } from 'react';
import { useForceRender } from 'src/hooks';
import modalEventService from '../modalEventService';
import { activeModals } from '../modalService';

/**
 * Helper to use the list of active modals. This will trigger re-renders in
 * React when the list is modified.
 */
const useActiveModals = () => {
	const forceRender = useForceRender();
	useEffect(() => {
		const off = modalEventService.on('LIST_MODIFIED', () => {
			// Re-render to show changes in the modal list
			forceRender();
		});
		return () => {
			off();
		};
	}, [forceRender]);

	return activeModals;
};

export default useActiveModals;

import { useCallback, useRef, DependencyList } from 'react';

/**
 * A hook which returns a `ref` to use on an element which will call a callback
 * function when the element mounts. Return an teardown function to call when
 * the element changes or unmounts.
 *
 * @param fn The callback
 * @param deps The list of dependencies for the callback
 *
 * @example
 * ```tsx
 * // For demonstration purposes. `onClick` is preferred for this scenario.
 * const buttonRef = useElementEffect<HTMLButtonElement>(button => {
 *   const onClickHandler = () => console.log('clicked!');
 *   button.addEventListener('click', onClickHandler);
 *   return () => {
 *     button.removeEventListener('click', onClickHandler);
 *   };
 * }, []);
 *
 * return <button ref={buttonRef}>Click me, I dare you.</button>;
 * ```
 */
export default function useElementEffect<T extends HTMLElement>(
	fn: (elem: T) => void | (() => void),
	deps: DependencyList
): (elem: T | null) => void {
	const cleanupRef = useRef<() => void>();
	return useCallback(elem => {
		cleanupRef.current?.();
		cleanupRef.current = (elem && fn(elem)) || undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

import React from 'react';

import { boolean } from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { CheckBoxCopyData } from 'src/pages/Settings/settingsCopy';
import { Checkbox } from 'src/elements';
import Field, { FieldLabel } from '../Field';
import styles from './CheckboxFieldGroup.module.scss';

/**
 * Props that can be passed to the `CheckboxFieldGroup` template component.
 */
interface CheckboxFieldGroupTemplateProps {
	/**
	 * Document each property w/ usage here.
	 */
	// children: React.ReactNode;
	list: CheckBoxCopyData[];
	label?: string;
	message?: string;
}

/**
 * `CheckboxFieldGroup` component interface. This includes all possible overloads.
 */
interface CheckboxFieldGroupTemplateComponent {
	(props: CheckboxFieldGroupTemplateProps): JSX.Element;
}

/**
 * @since 1.0.0
 * @module CheckboxFieldGroup
 * @summary This component creates a <Field /> component with checkboxes
 * for use within a Formik form. You must feed it a list of data that comforms to
 * the following shape:
 * @example
 * const myList = {
 * 	 [key:string]: {
 * 			title: string, // Text for title of the component
 * 			message: string, // Any text that should follow the title
 * 			checkboxes: [
 * 				title: string; // Any bold text before the body of the checkbox text
 * 				body: string; // The body of the checkbox text
 * 				help: string; // If you wish to display some helpfult text on hover
 * 				inputName: string; // Important!  The name of the checkbox for use with Formik
 * 			]
 * 	}
 * }
 * @example Usage
 * const pageCopy = {
 *	...SettingsPageCopy, // This object conforms to the example myList format above
 *  };
 *
 *  const schema = string().label('name');
 *
 *  export const defaultStory: Story = () => (
 *	<CheckboxFieldGroup
 *		label={pageCopy.marketingEmails.title}
 *	  name="marketingEmails"
 *		schema={schema}
 *		list={pageCopy.marketingEmails.checkboxes}
 * 		message={pageCopy.marketingEmails.message}
 *		onChange={value => {
 *			console.log('checked value', value);
 *		}}
 * 	/>
 * );
 *
 */
const CheckboxFieldGroup: CheckboxFieldGroupTemplateComponent = ({
	list,
	label,
	message,
}) => (
	<div className={styles['checkbox-field-wrap']}>
		{label && <FieldLabel>{label}</FieldLabel>}
		{message && <div className={styles['message-text']}>{message}</div>}
		<ul className={styles['checkbox-list']}>
			{list &&
				list.map((item, idx) => {
					let schema = boolean().label(item.inputName || '');
					if (item.required) {
						schema = schema.oneOf([true], item.required);
					}
					return (
						<Field
							label=""
							schema={schema}
							name={item.inputName}
							required={!!item.required}
							key={`checkbox_${idx}`}
							render={({ field }) => (
								<>
									<li key={`checkbox_${idx}`}>
										<div className={styles['checkbox-list-item']}>
											<Checkbox name={item.inputName} {...field} />
										</div>
										<div className={styles['checkbox-list-item']}>
											{item.title && <strong>{item.title}</strong>} {item.body}{' '}
											{item.help && (
												<span className={styles['checkbox-help-wrap']}>
													<FontAwesomeIcon icon={faQuestionCircle} />
													<span className={styles['checkbox-help']}>
														{item.help}
													</span>
												</span>
											)}
										</div>
									</li>
								</>
							)}
						></Field>
					);
				})}
		</ul>
	</div>
);

export default CheckboxFieldGroup;

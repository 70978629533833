import gql from 'graphql-tag';
import GenericUserFragment from './GenericUserFragment';

/**
 * Get User by name
 */

const GetGenericUserQuery = gql`
	query GetGenericUser($username: String!) {
		userByUsername(username: $username) {
			...GenericUser
		}
	}
	${GenericUserFragment}
`;

export default GetGenericUserQuery;

import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { useLocation } from 'react-router-dom';
import { Button } from 'src/elements';
import { useMeasure, useResponsive } from 'src/hooks';

import {
	CaretIcon,
	CaretIconContainer,
	DropdownContainer,
	DropdownContent,
} from './PageNavigation.style';

const AnimatedDropdownContent = animated(DropdownContent);

export const DropdownList: React.FC<{ label: React.ReactNode }> = ({
	children,
	label,
}) => {
	const [shown, setShown] = useState(false);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const isSmall = useResponsive('sm');

	const [bindHeight, { height }] = useMeasure();
	const styles = useSpring({
		from: {
			height: 0,
			opacity: 0,
			transform: `translate(${isSmall ? '-50%' : '0%'}, 0px)`,
		},
		to: {
			height: shown || isSmall ? height : 0,
			opacity: shown ? 1 : 0,
			transform: `translate(${isSmall ? '-50%' : '0%'}, ${
				shown || !isSmall ? '0px' : '8px'
			})`,
		},
	});

	// Hide menu if a click occurs anywhere except the button
	useEffect(() => {
		if (shown) {
			const hide = ({ target }: MouseEvent): void => {
				if (
					!buttonRef.current ||
					(buttonRef.current.parentNode &&
						!buttonRef.current.parentNode.contains(target as Node))
				) {
					setShown(false);
				}
			};
			window.addEventListener('click', hide, true);
			return () => window.removeEventListener('click', hide);
		}
		return undefined;
	}, [shown]);

	// Close dropdown after path change
	const { pathname } = useLocation();
	useEffect(() => () => setShown(false), [pathname]);

	return (
		<DropdownContainer shown={shown}>
			<Button nav onClick={() => setShown(!shown)} ref={buttonRef}>
				<div>{label}</div>
				<CaretIconContainer>
					<CaretIcon rotation={shown ? 180 : undefined} />
				</CaretIconContainer>
			</Button>

			<AnimatedDropdownContent shown={shown} style={styles}>
				<div {...bindHeight}>{children}</div>
			</AnimatedDropdownContent>
		</DropdownContainer>
	);
};

export default DropdownList;

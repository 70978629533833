import React from 'react';
import { useTransition, animated } from 'react-spring';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useElementEffect from 'src/hooks/useElementEffect';
import useActiveModals from '../hooks/useActiveModals';
import Modal from './Modal';

/**
 * This will render all active modals. Mount this at a high level in the app.
 */
const ModalRenderer = (): JSX.Element => {
	const activeModals = useActiveModals();
	const transitions = useTransition(activeModals, modal => modal.key, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0, pointerEvents: 'none' },
	});

	const activeModalRef: React.Ref<HTMLDivElement> = useElementEffect(elem => {
		disableBodyScroll(elem);
		return () => enableBodyScroll(elem);
	}, []);

	return !transitions.length ? (
		<></>
	) : (
		<div style={{ position: 'relative', zIndex: 999 }}>
			{transitions.map(
				({ item: { key, modal, closeModal }, props, state }, index) => {
					const isTop = key === activeModals[activeModals.length - 1]?.key;
					return (
						<animated.div
							key={key}
							style={{ ...props, zIndex: index, position: 'relative' }}
						>
							<Modal
								closeModal={closeModal}
								closeButton={modal.closeButton}
								interactiveOverlay={modal.interactiveOverlay}
								scrollRef={isTop ? activeModalRef : undefined}
								hideOverlay={!isTop && state !== 'leave'}
							>
								{modal.render({ closeModal })}
							</Modal>
						</animated.div>
					);
				}
			)}
		</div>
	);
};

export default ModalRenderer;

import gql from 'graphql-tag';

/**
 * Full generic user fragment containing all properties
 */
const GenericUserFragment = gql`
	fragment GenericUser on User {
		id
		name
		biography
		websiteUrl
		facebookUrl
		instagramUrl
		tumblrUrl
		twitterUrl
		youtubeUrl
		followCount
	}
`;

export default GenericUserFragment;

import React from 'react';

import classNames from 'classnames';
import { Container } from 'src/elements';
import styles from './SectionLayout.module.scss';

interface LayoutTypeProps {
	HERO: HeroLayoutProps;
	HERO2: Hero2LayoutProps;
	NARROW: NarrowLayoutProps;
	SIDEBAR: SidebarLayoutProps;
}

/**
 * Type helper to unwrap a property `K` of a type `T` only if `K` is defined
 * (not all possible keys of `T`). This is useful for adding properties to an
 * object if a certain property is set. This will not work for types with only a
 * single property as there's no way to know if it's defined or not.
 *
 * This is useful for having a set of properties added to a type depending on
 * another property value, but not allowing the properties to be known before
 * the property is provided.
 *
 * @example
 * type PropMap = {
 *   first: { propFirst: boolean };
 *   second: { propSecond: boolean };
 * };
 * type Keys = keyof PropMap;
 *
 * type Props<K extends Keys> = { type: K } & UnwrapIfDefined<PropMap, K>;
 * const useProps = <K extends Keys>(props: Props<K>): void => {};
 *
 * useProps({ type: 'first', propFirst: true });
 * useProps({ type: 'second', propSecond: true });
 */
type UnwrapIfDefined<T, K extends keyof T> = keyof T extends K ? {} : T[K];

/**
 * Props that can be passed to the `SectionLayout` template component.
 */
type SectionLayoutProps<K extends keyof LayoutTypeProps> = {
	/**
	 * The template type. This will determine the other props needing to be
	 * passed.
	 *
	 * - HERO: For small, short blurbs of content. Think of a square box.
	 * - NARROW: For narrow pages that are long.
	 * - SIDEBAR: For pages that have a main section and an optional sidebar.
	 */
	type?: K;

	/**
	 * If the layout should have a brand like background
	 */
	background?: boolean;
	children: React.ReactNode;
} & UnwrapIfDefined<LayoutTypeProps, K>;

interface HeroLayoutProps {
	background?: boolean;
	children: React.ReactNode;
}

const heroLayout = ({
	children,
	background = true,
}: HeroLayoutProps): JSX.Element => {
	return (
		<div className={styles['hero']}>
			{!!background && (
				<>
					<div className={styles['hero-left-background']} />
					<div className={styles['hero-right-background']} />
				</>
			)}
			<div className={styles['hero-wrapper']}>{children}</div>
		</div>
	);
};

interface Hero2LayoutProps {
	background?: boolean;
	children: React.ReactNode;

	/**
	 * Where to place the bricks when viewed from a mobile device.
	 * @default 'bottom'
	 */
	mobileBrickPlacement?: 'top' | 'bottom' | 'none';
}

const hero2Layout = ({
	children,
	background = true,
	mobileBrickPlacement = 'bottom',
}: Hero2LayoutProps): JSX.Element => {
	return (
		<div
			className={classNames(styles['hero2'], {
				[styles['hero2-with-background']]: background,
				[styles['hero2-with-background-bottom']]:
					mobileBrickPlacement === 'bottom',
			})}
		>
			<div className={styles['hero-wrapper']}>{children}</div>
		</div>
	);
};

interface SidebarLayoutProps {
	background?: boolean;
	children: React.ReactNode;
	sidebar?: React.ReactNode;
}

const sidebarLayout = ({
	background = true,
	children,
	sidebar,
}: SidebarLayoutProps): JSX.Element => {
	return (
		<div className={styles['sidebar']}>
			<div className={styles['sidebar-side']}>
				{sidebar}
				{!!background && <div className={styles['sidebar-side-background']} />}
			</div>
			<div className={styles['sidebar-main']}>{children}</div>
		</div>
	);
};

interface NarrowLayoutProps {
	background?: boolean;
	children: React.ReactNode;
}

const narrowLayout = ({
	children,
	background = true,
}: NarrowLayoutProps): JSX.Element => {
	return (
		<div className={styles['narrow']}>
			{!!background && (
				<>
					<div className={styles['narrow-left-background']} />
					<div className={styles['narrow-right-background']} />
				</>
			)}
			<div className={styles['narrow-wrapper']}>{children}</div>
		</div>
	);
};

/**
 * Type guard for individual template props
 */
function ensurePropsType<T extends keyof LayoutTypeProps>(
	isType: T,
	type: keyof LayoutTypeProps,
	props: LayoutTypeProps[keyof LayoutTypeProps]
): props is LayoutTypeProps[T] {
	return type === isType;
}

/**
 * `SectionLayout` component interface. This includes all possible overloads.
 */
function SectionLayout<T extends keyof LayoutTypeProps>({
	type,
	...props
}: SectionLayoutProps<T>): JSX.Element {
	let content: React.ReactNode;
	if (!type) {
		content = props.children;
	} else if (ensurePropsType('HERO', type, props)) {
		content = heroLayout(props);
	} else if (ensurePropsType('HERO2', type, props)) {
		content = hero2Layout(props);
	} else if (ensurePropsType('NARROW', type, props)) {
		content = narrowLayout(props);
	} else if (ensurePropsType('SIDEBAR', type, props)) {
		content = sidebarLayout(props);
	}

	return (
		<div className={styles.content}>
			<Container>{content}</Container>
		</div>
	);
}

export default SectionLayout;

import { ImgHTMLAttributes } from 'react';
import { mapHooksToProps } from 'src/common/utils/withHooks';
import withHooks from 'src/common/utils/withHooks';
import ImageTemplate from './Image';

export const USE_IMGIX = process.env.REACT_APP_USE_IMGIX === 'true';

export const S3_AVATAR_CDN_URL = 'https://tng-idp-common.s3.amazonaws.com/';

export const VIDEO_CDN_URL =
	'https://cdn-static.worldbuilder.tongal.com/app/videos';

export const OLD_S3_UPLOADS_CDN_URL = 'https://lagnot-dev.s3.amazonaws.com/';
export const S3_UPLOADS_CDN_URL = `https://${process.env.REACT_APP_IMAGE_UPLOAD_BUCKET}.s3.us-east-2.amazonaws.com/`;

export const AVATAR_CDN_URL = `${process.env.REACT_APP_BE_CORE_API}/`;

export const UPLOADS_CDN_URL = USE_IMGIX
	? `https://${process.env.REACT_APP_IMAGE_CDN_DOMAIN}/`
	: S3_UPLOADS_CDN_URL;

export { getImageUrl } from './Image';

export interface ImageProps
	extends Pick<
		ImgHTMLAttributes<HTMLImageElement>,
		'className' | 'draggable' | 'onError' | 'onLoad'
	> {
	/**
	 * Final img alternative text
	 */
	alt: string;

	/**
	 * Base url of the cdn (imgix, S3, etc)
	 */
	cdnUrl: string;

	/**
	 * File key i.e `/images/minifig.jpg`
	 */
	src: string;

	/**
	 * The max width requested
	 */
	width?: number;

	/**
	 * The max height requested. You often do not need to pass this, and can opt
	 * for using `width` and `aspectRatio`, but this may be useful in some cases.
	 */
	height?: number;

	/**
	 * The aspect ratio requested
	 */
	aspectRatio?: number;

	/**
	 * Option for crop image
	 */
	crop?: boolean;

	/**
	 * The quality of the image for lossy image formats. If the image is served in
	 * a lossless format, this is ignored.
	 */
	quality?: number;

	/**
	 * Ref to be passed to the `<img>` element.
	 */
	imgRef?: React.Ref<HTMLImageElement>;

	/**
	 * Request an image in this format. May be useful for converting images to a
	 * lossy format for size reduction.
	 */
	format?: 'jpg' | 'png' | 'gif';
}

const Image = withHooks(
	ImageTemplate,
	mapHooksToProps(
		({ draggable = false, crop = false, ...passthruProps }: ImageProps) => {
			return {
				draggable,
				crop,
				...passthruProps,
			};
		}
	)
);

export default Image;

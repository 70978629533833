/**
 * General type-safe state service
 */
export interface StateService<TState extends {}> {
	readonly state: Readonly<TState>;

	/**
	 * A way to set the current state. This takes a partial object of the full
	 * state and will be patched onto the existing state object.
	 * @param newState
	 *
	 * @example <caption>Adding data and getters</caption>
	 * setState({
	 *   name: 'Something',
	 *   get currentTime() {
	 *     return Date.now();
	 *   }
	 * })
	 */
	setState(newState: Partial<TState> & ThisType<Readonly<TState>>): void;
}

/**
 * Utility to copy object properties from and object to a full object. This will
 * copy the descriptors so getters and setters will be applied to the `to`
 * object. This means `this` will no longer reference `from` when applied.
 * @param from The object to copy properties from
 * @param to The object to copy properties to
 */
function copyObject<TTo extends {}>(from: Partial<TTo>, to: TTo): TTo {
	for (const prop in from) {
		const descriptor = Object.getOwnPropertyDescriptor(from, prop);
		if (descriptor) {
			Object.defineProperty(to, prop, descriptor);
		}
	}
	return to;
}

/**
 * Creates a general state service. This is intended to mimic this capabilities
 * of Google Tag Manager variables which may be static values, but may be
 * getters only ran when fetched.
 * @param initialState The initial state
 * @example <caption>Adding data and getters</caption>
 * const stateServie = createStateService({
 *   name: 'Something',
 *   get currentTime() {
 *     return Date.now();
 *   }
 * })
 */
export default function createStateService<TState extends {}>(
	initialState: TState
): StateService<TState> {
	const state = copyObject(initialState, {} as TState);
	return {
		state,
		setState: newState => copyObject(newState, state),
	};
}

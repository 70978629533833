import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/types';

import safeJsonStringify from 'safe-json-stringify';
import { Logger, LogLevel } from './Logger';
import loggerThreshold from './loggerThreshold';
import LoggerScope from './LoggerScope';

/** Safe stringify to send info to Sentry */
function stringify(message: unknown): string {
	if (!(message instanceof Error) && typeof message === 'object' && message) {
		return safeJsonStringify(message);
	} else {
		return `${message}`;
	}
}

/**
 * Implementation of the Logger interface built on [sentry.io](https://sentry.io/)
 * browser-side logging service.
 * NOTE: sentry documentation advises initializing the Sentry logger as soon as possible.
 */
export class SentryLogger implements Logger {
	public constructor() {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			release: `lego-fe-web@${process.env.REACT_APP_SENTRY_RELEASE ||
				'unknown'}`,
			environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
			ignoreErrors: [
				/NotAllowedError/gi,
				/AbortError/gi,
				/ResizeObserver/gi,
				/Extension context invalidated/gi,
				/You can only upload: .png, .jpg, .jpeg/gi,
			],
		});
	}

	public debug: Logger['debug'] = (...args) => {
		this.logWithScope('debug', Severity.Debug, ...args);
	};

	public info: Logger['info'] = (...args) => {
		this.logWithScope('info', Severity.Info, ...args);
	};

	public warn: Logger['warn'] = (...args) => {
		this.logWithScope('warn', Severity.Warning, ...args);
	};

	public error: Logger['error'] = (...args) => {
		this.logWithScope('error', Severity.Error, ...args);
	};

	public setScope: Logger['setScope'] = loggerScope => {
		Sentry.configureScope(sentryScope => {
			if (loggerScope.tags) {
				sentryScope.setTags(loggerScope.tags);
			}
			if ('user' in loggerScope) {
				if (loggerScope.user) {
					sentryScope.setUser(loggerScope.user);
				} else {
					sentryScope.setUser(null);
				}
			}
			if (loggerScope.context) {
				sentryScope.setContext('Context', loggerScope.context);
			}
		});
	};

	private logWithScope = (
		level: LogLevel,
		severity: Severity,
		message: unknown,
		scope?: LoggerScope
	) => {
		loggerThreshold(
			level,
			() => {
				const additional = {
					contexts: {
						Context: scope?.context,
					},
					user: scope?.user,
					tags: scope?.tags,
					level: severity,
				};

				if (message instanceof Error) {
					Sentry.captureException(message, additional);
				} else {
					Sentry.captureMessage(stringify(message), additional);
				}
			},
			() =>
				// Temporary. Don't log debug messages since some things log crazy
				// amounts of messages, such as the components subscribing to Uppy
				// actions. Some of these log those messages hundreds of times while
				// filling out a form, so we are ignoring them here so we don't clutter
				// the breadcrumbs.
				level !== 'debug' &&
				Sentry.addBreadcrumb({
					message: stringify(message),
					data: scope?.context,
					level: severity,
				})
		);
	};
}

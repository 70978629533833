import { useEffect, useRef, EffectCallback, DependencyList } from 'react';

/**
 * Similar to useEffect, but will run the first effect synchronously. This is
 * useful for times that you need to
 */
export default function useImmediateEffect(
	fn: EffectCallback,
	deps?: DependencyList
) {
	const firstRenderCleanupRef = useRef<ReturnType<EffectCallback>>();
	const ranFirstRenderRef = useRef(false);
	if (!ranFirstRenderRef.current) {
		firstRenderCleanupRef.current = fn();
		ranFirstRenderRef.current = true;
	}

	const ranFirstEffectRef = useRef(false);
	const fnRef = useRef<EffectCallback>(fn);
	fnRef.current = fn;
	useEffect(() => {
		if (!ranFirstEffectRef.current) {
			ranFirstEffectRef.current = true;
			return firstRenderCleanupRef.current;
		}
		return fnRef.current();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

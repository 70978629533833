import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import logger from 'src/common/logger';

import { ErrorPage500 } from './Errors';

interface PageErrorBoundaryState {
	hasError?: boolean;
}

/**
 * PageErrorBoundary is an error boundary for catching PageErrors.
 * Rendering decisions are generally keyed around the statusCode of the 'caught' PageError. That
 * statusCode is provided by way of a StatusCoded interface on the PageError.
 * Currently returns various default placeholder components for
 * handling common HTTPStatus-style error codes.
 */
class PageErrorBoundary extends React.Component<
	RouteComponentProps,
	PageErrorBoundaryState
> {
	private unlisten: () => void;

	public constructor(props: RouteComponentProps) {
		super(props);

		// If an error was encountered, unset it so that other pages will
		// still display
		const { history } = this.props;
		this.unlisten = history.listen(() => {
			this.setState({ hasError: false });
		});
		this.state = { hasError: false };
	}

	public componentWillUnmount(): void {
		this.unlisten();
	}

	// a React class componsent lifecycle method (TypeScript can't detect it)
	public static getDerivedStateFromError(
		error: unknown
	): PageErrorBoundaryState {
		return { hasError: true };
	}

	// TODO: we may want to move the guts of getDerivedStateFromError into here to consolidate.
	public componentDidCatch(error: Error): void {
		// TODO: at some point, make sure we only log Errors we care about. Avoids log clutter.
		// There may be errors that occur but are handled just fine and don't need logging.
		logger.error(error);
	}

	public render(): React.ReactNode {
		const { hasError } = this.state;
		const { children } = this.props;

		return hasError ? <ErrorPage500 /> : children;
	}
}

/**
 * An error boundary wrapped in a Router.  Has access to a router's history
 * so errors can be unset as needed on page change.
 *
 * For more info about Error Boundaries, see
 * the official React docs (https://reactjs.org/docs/error-boundaries.html)
 */
export default withRouter(PageErrorBoundary);

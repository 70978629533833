import { ConsoleLogger } from './ConsoleLogger';
import { SentryLogger } from './SentryLogger';
import { Logger } from './Logger';

/**
 * In production, this initializes the sentry logger in production.
 * Per Sentry.io documentation, this should be carried out as early as possible.
 */
const logger: Logger =
	process.env.REACT_APP_LOGGER === 'sentry'
		? new SentryLogger()
		: new ConsoleLogger();

export default logger;

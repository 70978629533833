import { useContext } from 'react';
import {
	FileUploaderContext,
	FileUploaderProviderType,
} from './FileUploaderProvider';

/**
 * @since 1.0.0
 * @module useFileUploader
 * @summary This allows you to gain access to the
 * 'uploadComplete' state when using the FileUploaderField.
 * This value is predetermined within the onUpload and onComplete
 * methods of the FileUploaderField index.ts file: To use, see example below
 * @param {children} React.children
 * @example
 * const { uploadComplete } = useFileUploader();
 */
const useFileUploader = (): FileUploaderProviderType =>
	useContext(FileUploaderContext);

export default useFileUploader;

import React, { useEffect } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faBars,
	faCaretDown,
	faTimes,
	faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import CookieBanner from 'src/components/CookieBanner';
import logger from 'src/common/logger';
import Footer from 'src/components/Footer';
import PageNavigation from 'src/components/PageNavigation';
import SimpleMetaTag from 'src/components/MetaTags/SimpleMetaTag';
import ImageMetaTag from 'src/components/MetaTags/ImageMetaTag';
import { useCurrentUserContext } from 'src/hooks';
import Router from 'src/pages/Router';
import ModalRenderer from 'src/modal/components/ModalRenderer';

import { Page, PageBody } from './App.styles';

library.add(faBars);
library.add(faTimes);
library.add(faCaretDown);
library.add(faUserCircle);

export default function App(): React.ReactElement {
	const { currentUser } = useCurrentUserContext();

	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		if (currentUser) {
			logger.setScope({
				user: {
					id: currentUser.id,
					username: currentUser.name,
				},
			});
			logger.info('User was set');
		} else {
			logger.setScope({ user: undefined });
		}
	}, [currentUser]);

	return (
		<>
			<SimpleMetaTag />
			<ImageMetaTag />

			<Page>
				<PageNavigation />
				<PageBody id="main" aria-label="main">
					<Router />
				</PageBody>
				<Footer />
				<CookieBanner />
			</Page>

			<ModalRenderer />
		</>
	);
}

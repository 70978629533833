import createStateService from 'src/common/factories/createStateService';
import createEventService from 'src/common/factories/createEventService';
import TrackingEvent from './TrackingEvent';

interface TrackingState {}

const { state, setState } = createStateService<TrackingState>({});
const { on, send } = createEventService<TrackingEvent>();

const trackingService = { state, setState, on, send };
export default trackingService;
export { state, setState, on, send };

// Startup the tracking service with tags. We may want to explore the
// possibility of booting up the tracking service with different packages of
// triggers. That would allow for us to use a custom package when testing
// various things that use the tracking service. For now, we simply mock the
// service.
require('./triggers/gaTriggers');

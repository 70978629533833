import React, { ComponentType } from 'react';
import Modal from './Modal';

interface CreateModalRenderer {
	<P>(
		component: ComponentType<P>,
		props: Omit<P, 'closeModal'>
	): Modal['render'];
}

/**
 * Helper to create the `render` prop to be passed into an `openModal` call.
 * This simply takes a component and its props and injects `closeModal` into the
 * component's props.
 *
 * @example
 * const MyComponent = ({ closeModal, title }) => (
 *   <div>
 *     <h1>{title}</h1>
 *     <button onClick={closeModal}>Close</button>
 *   </div>
 * );
 *
 * modalService.openModal({
 *   render: createModalRenderer(MyComponent, { title: 'Title' }),
 * });
 */
const createModalRenderer: CreateModalRenderer = (component, props) => ({
	closeModal,
}) =>
	// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
	// @ts-ignore
	React.createElement(component, {
		...props,
		closeModal,
	});

export default createModalRenderer;

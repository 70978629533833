import {
	isBefore,
	parseISO,
	startOfTomorrow,
	subYears,
	toDate,
} from 'date-fns';
import { TestOptions } from 'yup';

/**
 * Factory to create a Yup test that will verify the date entered as a date of
 * birth means the user will be at least `minAge` years old today.
 * @param minAge Number of years old
 */
function createMinAgeTest(minAge: number): TestOptions {
	return {
		name: 'min-age',
		test: (value: unknown) => {
			if (!value) return true;
			let date: Date | undefined;
			if (typeof value === 'string') {
				date = parseISO(value);
			} else if (typeof value === 'number') {
				date = toDate(value);
			} else if (value instanceof Date) {
				date = value;
			}

			if (date instanceof Date) {
				return isBefore(date, subYears(startOfTomorrow(), minAge));
			} else {
				return false;
			}
		},
		message: () => `Sorry, you must be ${minAge} years or older to register`,
	};
}

export default createMinAgeTest;

import React from 'react';
import { Link } from 'react-router-dom';

import useClickTracking from 'src/tracking/hooks/useClickTracking';
import { DropdownList } from './DropdownList';
import { LinkList } from './PageNavigation.style';

export interface NavLinkItem {
	label: string;
	link?: string;
	subLinks?: NavLinkItem[];
}

export const NavLink: React.FC<{
	navLink: NavLinkItem;
	parent?: NavLinkItem;
}> = ({ navLink, parent }) => {
	const elemRef = useClickTracking({
		elementCategory: 'Main Menu',
		elementName: parent ? `${parent.label} - ${navLink.label}` : navLink.label,
		actionName: 'Navigation',
	});
	if (navLink.link) {
		return (
			<li key={navLink.label}>
				{navLink.link.match(/^(https?:)?\/\//) ? (
					<a
						href={navLink.link}
						ref={elemRef}
						data-qa={`${navLink.label.toLowerCase()}-navbar-link`}
						aria-label={navLink.label}
					>
						{navLink.label}
					</a>
				) : (
					<Link
						to={navLink.link}
						ref={elemRef}
						data-qa={`${navLink.label.toLowerCase()}-navbar-link`}
						aria-label={navLink.label}
					>
						{navLink.label}
					</Link>
				)}
			</li>
		);
	} else if (navLink.subLinks && navLink.subLinks.length) {
		return (
			<li key={navLink.label} aria-label={navLink.label}>
				<DropdownList label={navLink.label}>
					<LinkList>
						{navLink.subLinks.map(subLink => (
							<NavLink
								key={subLink.label}
								navLink={subLink}
								parent={navLink}
								aria-label={subLink.label}
							/>
						))}
					</LinkList>
				</DropdownList>
			</li>
		);
	}
	return <></>;
};

export default NavLink;

import gql from 'graphql-tag';

/**
 * Full user fragment containing all properties
 */
export const UserFragment = gql`
	fragment User on User {
		id
		email
		hasPayments
		isActive
		name
		permissions
		biography
		city
		countryName
		stateOrProvince
		# marketingEmailConsent
		cookieConsent
		websiteUrl
		facebookUrl
		twitterUrl
		instagramUrl
		tumblrUrl
		youtubeUrl
	}
`;

/**
 * Get Current user
 * @returns CurrentUser
 */
export const GetCurrentUserQuery = gql`
	query GetCurrentUser {
		me {
			...User
		}
	}
	${UserFragment}
`;

import React from 'react';
import { Helmet } from 'react-helmet';

interface SimpleMetaTagTemplateProps {
	title?: string;
	name?: string;
	content?: string;
}

interface SimpleMetaTagTemplateComponent {
	(props: SimpleMetaTagTemplateProps): JSX.Element;
}

const SimpleMetaTagTemplate: SimpleMetaTagTemplateComponent = ({
	title = 'LEGO® World Builder',
	name = 'description',
	content = '',
}) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name={name} content={content} />
		</Helmet>
	);
};

export default SimpleMetaTagTemplate;

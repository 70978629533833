import React, { useState } from 'react';
import { success, error as displayError } from 'toastr';
import { GenericError } from '@auth0/auth0-spa-js';
import authService from 'src/auth/authService';
import routerHistory from 'src/router/routerHistory';

/**
 * Props that can be passed to the `AuthCallbackPage` component.
 */
interface AuthCallbackPageProps {}

/**
 * `AuthCallbackPage` component interface. This includes all possible overloads.
 */
interface AuthCallbackPageComponent {
	(props: AuthCallbackPageProps): JSX.Element;
}

const isAuthError = (error: unknown): error is GenericError =>
	typeof error === 'object' &&
	error !== null &&
	(error as GenericError).error !== undefined &&
	(error as GenericError).error_description !== undefined &&
	(error as GenericError).message !== undefined;

/**
 * A blank page that a user should be redirected to after logging in or signing
 * up. It will parse out the targetPath from the redirect state and send the
 * user there, or send them to the homepage if there was an error.
 *
 * There is a possibility that this page will not run before the auth state has
 * been resolved. For that reason, this page should always be part of the main
 * app bundle, never loaded asynchronously.
 */
const AuthCallbackPage: AuthCallbackPageComponent = () => {
	// Run only once
	useState(async () => {
		try {
			const {
				targetPath,
				targetQuery,
				objectLink,
			} = await authService.handleCallback();
			routerHistory.replace({
				pathname: targetPath,
				search: targetQuery,
				hash: objectLink,
			});
		} catch (e) {
			if (isAuthError(e)) {
				if (
					(e.error === 'unauthorized' || e.error === 'access_denied') &&
					e.error_description === 'EMAIL NOT VERIFIED'
				) {
					// If unverified email error, go to email not verified page
					routerHistory.replace('/auth/unverified');
				} else {
					if (e.error_description === 'INVALID_CUSTOM_SSO_LOGIN') {
						displayError(
							'Sorry, we had trouble completing your registration with the information provided. Please try again or email info@worldbuilder.tongal.com for assistance.'
						);
					}
					routerHistory.replace('/');
				}
			} else {
				// On any other error, go to homepage
				routerHistory.replace('/');
			}
		} finally {
			if (
				authService.state.user?.[
					'https://worldbuilder.tongal.com/account_just_linked'
				]
			) {
				success("You've successfully linked your accounts!");
			}
		}
	});

	return <></>;
};

export default AuthCallbackPage;

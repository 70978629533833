import { useLazyQuery } from '@apollo/client';
import { GetSSOtoken, GetSSOtokenVariables } from './generated/GetSSOtoken';
import GetSSOtokenQuery from './GetSSOtokenQuery';

/**
 * A hook to get a Single Sign-On token
 */
const useGetSSOtoken = () =>
	useLazyQuery<GetSSOtoken, GetSSOtokenVariables>(GetSSOtokenQuery);

export default useGetSSOtoken;

import {
	RouteComponentProps,
	RouteProps,
	Route,
	RouteChildrenProps,
} from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';
import React, { useCallback, ComponentType } from 'react';

import {
	ErrorPage404,
	ErrorPage401,
	ErrorPage403,
	ErrorPage500,
} from 'src/pages/Errors';
import logger from 'src/common/logger';
import { PageErrorType } from './errors';

export const PageErrorRouteLoading = (): JSX.Element => <></>;

const isLoading = <TCustomKeys extends string>(
	testResult: ReturnType<PageErrorRouteProps<unknown, TCustomKeys>['test']>
): boolean => !!(typeof testResult === 'object' && testResult.loading);

const getErrorType = <TCustomKeys extends string>(
	testResult: PageErrorTypeResponse<TCustomKeys>
): PageErrorType | TCustomKeys | undefined =>
	typeof testResult === 'object' ? testResult.error : testResult;

type PageErrorTypeResponse<TCustomKeys extends string = never> =
	| PageErrorType
	| TCustomKeys
	| {
			loading?: boolean;
			error?: PageErrorType | TCustomKeys;
	  }
	| undefined;

type CustomErrorMap<
	P = unknown,
	TCustomKeys extends string = never
> = (string extends TCustomKeys
	? {} // Trickery to not allow `string` to have any keys
	: {
			[K in Exclude<TCustomKeys, PageErrorType>]: ComponentType<
				RouteComponentProps<P>
			>;
	  }) &
	{ [K in PageErrorType]?: ComponentType<RouteComponentProps<P>> };

export interface PageErrorRouteTest<
	P = {},
	TCustomKeys extends string = never
> {
	(props: RouteComponentProps<P>): PageErrorTypeResponse<TCustomKeys>;
}

/**
 * Props to pass to the PageErrorRoute
 * @todo Allow promise-based tests
 */
type PageErrorRouteProps<P = {}, TCustomKeys extends string = never> = Omit<
	RouteProps,
	'render' | 'children' | 'component'
> & {
	test: PageErrorRouteTest<P, TCustomKeys>;
	render?: (props: RouteComponentProps<P>) => React.ReactNode;
	children?:
		| ((props: RouteChildrenProps<P>) => React.ReactNode)
		| React.ReactNode;
	component?:
		| React.ComponentType<RouteComponentProps<P>>
		| LoadableComponent<unknown>;
} & ([Exclude<TCustomKeys, PageErrorType>] extends [never] // Require `errorPages` if custom errors are defined
		? { errorPages?: CustomErrorMap<P, TCustomKeys> }
		: string extends TCustomKeys
		? { errorPages?: CustomErrorMap<P, TCustomKeys> }
		: { errorPages: CustomErrorMap<P, TCustomKeys> });

const defaultErrorPages: { [K in PageErrorType]: ComponentType } = {
	NOT_FOUND: ErrorPage404,
	UNAUTHENTICATED: ErrorPage401,
	UNAUTHORIZED: ErrorPage403,
	UNKNOWN: ErrorPage500,
};

/**
 * Use this in place of the React Router `<Route>` component to add a test to
 * the route and display an error page, if necessary.
 *
 * @example
 * <PageErrorRoute
 *   test={() => user ? undefined : 'UNAUTHENTICATED'}
 *   component={MyPage}
 * />
 *
 * @example
 * <PageErrorRoute
 *   test={() => ({ loading: true })}
 *   component={MyPage}
 * />
 *
 * @todo Loading screens
 */
export default function PageErrorRoute<P, TCustomKeys extends string>({
	// Renamed to require it to adhere to rules of hooks
	test: useTest,
	component: Component,
	render,
	errorPages,
	...props
}: PageErrorRouteProps<P, TCustomKeys>): JSX.Element {
	const TestComponent = useCallback(
		function TestComponent(routeProps: RouteComponentProps<P>): JSX.Element {
			const testResult = useTest(routeProps);
			const loading = isLoading(testResult);
			const errorType = getErrorType(testResult);

			if (loading) {
				return <PageErrorRouteLoading />;
			}

			if (errorType && errorPages) {
				const errorComponentMap = {
					...defaultErrorPages,
					...errorPages,
				};
				// TODO: Need to figure out how to type this better
				// @ts-ignore
				const CustomComponent = errorComponentMap[errorType];
				return <CustomComponent {...routeProps} />;
			}

			switch (errorType) {
				case 'NOT_FOUND':
					return <ErrorPage404 />;
				case 'UNAUTHENTICATED':
					return <ErrorPage401 />;
				case 'UNAUTHORIZED':
					return <ErrorPage403 />;
				case 'UNKNOWN':
					return <ErrorPage500 />;
			}

			if (errorType) {
				logger.error(`Couldn't find template for error ${errorType}`);
				return <ErrorPage500 />;
			}

			if (Component) {
				return <Component {...routeProps} />;
			} else if (render) {
				return <>{render(routeProps)}</>;
			} else {
				return <></>;
			}
		},
		[errorPages, useTest, render, Component]
	);

	return <Route {...props} component={TestComponent} />;
}

import { ComponentPropsWithoutRef } from 'react';
import { withHooks } from 'src/common/utils';
import { mapHooksToProps } from 'src/common/utils/withHooks';
import useFileUploader from '../FileUploader/useFileUploader';
import UserAvatarTemplate from './UserAvatar';
import useGetUserAvatar from './useGetUserAvatar';

/**
 * Props that can be passed to the `UserAvatar` component.
 */
interface UserAvatarProps {
	/**
	 * The ID of the User
	 */
	userId: string;

	/**
	 * The size of the avatar. Full will take all the available space by default.
	 */
	variant?: 'small' | 'medium' | 'large' | 'full';

	/**
	 * Whether or not to show the loading indicator when a new avatar upload has
	 * begun, but before the new avatar is fully loaded. If using this, it's
	 * important to wrap your avatar form in `<FileUploaderProvider>` so that it
	 * isn't affected by other file uploads throughout the app.
	 * @default false
	 */
	showLoadingIndicator?: boolean;
}

/**
 * @since 1.0.0
 * @module UserAvatar
 * @summary Checks the User's avatar directory in AWS and sets a default image if the response is not 200
 * @description If an update is made to a user's avatar, call the UpdateUserAvatar mutation for the changes to be reflected across the site.
 */
const UserAvatar = withHooks(
	UserAvatarTemplate,
	mapHooksToProps<
		UserAvatarProps,
		ComponentPropsWithoutRef<typeof UserAvatarTemplate>
	>(({ userId, variant = 'large', showLoadingIndicator = false }) => {
		const { data } = useGetUserAvatar({
			variables: { userId },
		});

		/**
		 * Sets the loading state of the image that is being updated
		 */
		const { uploadComplete, noUploadInitialized } = useFileUploader();

		return {
			imageUrl: data?.userAvatar || '',
			variant,
			updateInProgress:
				!uploadComplete && !noUploadInitialized && showLoadingIndicator,
		};
	})
);

export default UserAvatar;

import React from 'react';
import styles from './Confirmation.module.scss';

/**
 * Props that can be passed to the `Information` component.
 */
interface InformationProps {
	/**
	 * Document each property w/ usage here.
	 */
	title: string;
	message: React.ReactNode;
}

/**
 * `Information` component interface. This includes all possible overloads.
 */
interface InformationComponent {
	(props: InformationProps): JSX.Element;
}

/**
 * Component holding the title and message of a Confirmation. This is useful for
 * only using the top portion without the buttons.
 */
const Information: InformationComponent = ({
	title,
	message,
}: InformationProps) => (
	<>
		<h2 className={styles['confirmation-title']}>{title}</h2>
		<div className={styles['confirmation-message']}>{message}</div>
	</>
);

export default Information;

import gql from 'graphql-tag';
import { addResolvers } from 'src/common/graphql';
import getUserAvatarUrl from './getUserAvatarUrl';

gql`
	extend type Query {
		userAvatar(userId: UUID!): String!
	}
`;

/**
 * Query for getting a user's avatar. This resolves locally.
 */
const GetUserAvatarQuery = gql`
	query GetUserAvatar($userId: UUID!) {
		userAvatar(userId: $userId) @client
	}
`;

export default GetUserAvatarQuery;

addResolvers({
	Query: {
		userAvatar: (_, { userId }) => getUserAvatarUrl(userId),
	},
});

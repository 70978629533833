import {
	RouteComponentProps,
	useHistory,
	useLocation,
	useRouteMatch,
} from 'react-router-dom';

/**
 * You can add type info here to `RouteComponentProps` so you don't have to
 * repeat it inline below.
 */
type TypedRouteComponentProps = RouteComponentProps;

/**
 * Polyfill for `RouteChildrenProps` which was not re-exported from
 * `react-router`. This makes it so we don't need to add the extra dependency.
 */
interface RouteChildrenProps {
	history: TypedRouteComponentProps['history'];
	location: TypedRouteComponentProps['location'];
	match: TypedRouteComponentProps['match'] | null;
}

/**
 * A React hook that provides unified information about the current route.
 *
 * You could also use each hook separately,
 * this is a helper that improves readability
 */
export const useRouter = (): RouteChildrenProps => {
	const match = useRouteMatch();
	const history = useHistory();
	const location = useLocation();

	return {
		match,
		history,
		location,
	};
};

import React, {
	ComponentPropsWithRef,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
	useMemo,
	ForwardRefExoticComponent,
} from 'react';
import {
	faBars,
	faTimes,
	IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { animated, useTransition, UseTransitionResult } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { useCurrentUserContext } from 'src/hooks';

import useClickTracking from 'src/tracking/hooks/useClickTracking';
import authService from 'src/auth/authService';
import withHooks, { mapHooksToProps } from 'src/common/utils/withHooks';
import { User } from 'src/schema/generated/User';
import { NavLinkItem } from './NavLink';
import { MobileOverlay } from './PageNavigation.style';
import { ProfileNavLink } from './ProfileNavLink';
import PageNavigationTemplate from './PageNavigation';

export const genericNavLinks: NavLinkItem[] = [
	{
		label: 'About',
		subLinks: [
			{
				label: 'How It Works',
				link: '/about/how-it-works',
			},
			{
				label: 'World Guidelines',
				link: '/about/world-guidelines',
			},
		],
	},
	{
		label: 'Discover',
		subLinks: [
			{
				label: 'Explore Worlds',
				link: '/discover',
			},
			{
				label: 'Now In Development',
				link: '/now-in-development',
			},
		],
	},
	{
		label: 'Create',
		subLinks: [
			{
				label: 'Create a World',
				link: '/create',
			},
			{
				label: 'Pitch to LEGO',
				link: '/pitch-to-lego',
			},
			{
				label: 'Tongal',
				link: 'https://tongal.com',
			},
			...(process.env.REACT_APP_ENABLE_COMICS_PROGRAM === 'true'
				? [
						{
							label: 'Comics Program',
							link: '/comics',
						},
				  ]
				: []),
		],
	},
];

export interface PageNavigationInterface {
	openedIcon?: IconDefinition | undefined;
	closedIcon?: IconDefinition;
	AnimatedMobileOverlay?: ForwardRefExoticComponent<any> | undefined;
	AnimatedIcon?: ForwardRefExoticComponent<any> | undefined;
	closeMenu?: () => void | undefined;
	toggleMenu?: () => void | undefined;
	logOut?: () => Promise<void> | undefined;
	mobileOverlayTransitions?:
		| UseTransitionResult<
				boolean,
				Pick<React.CSSProperties, Partial<'opacity'>>
		  >[]
		| undefined;
	profileNavLinkProps?: React.PropsWithChildren<
		ComponentPropsWithRef<typeof ProfileNavLink>
	>;
	adminNavLinks?: NavLinkItem;
	logoRef?: (instance: any) => void | undefined;
	currentUser?: User;
	menuOpened?: boolean;
	overlayRef?: React.RefObject<HTMLDivElement> | undefined;
}

const hooks = mapHooksToProps(
	(): PageNavigationInterface => {
		const openedIcon = faTimes;
		const closedIcon = faBars;

		const AnimatedMobileOverlay = animated(MobileOverlay);
		const AnimatedIcon = animated(I);

		const [menuOpened, setMenuOpened] = useState(false);

		const { currentUser, loading } = useCurrentUserContext();

		const openMenu = useCallback(() => {
			setMenuOpened(true);
		}, [setMenuOpened]);

		const closeMenu = useCallback(() => {
			setMenuOpened(false);
		}, [setMenuOpened]);

		const toggleMenu = useCallback(() => {
			if (menuOpened) {
				closeMenu();
			} else {
				openMenu();
			}
		}, [openMenu, closeMenu, menuOpened]);

		// Close menu after path change
		const { pathname } = useLocation();
		useEffect(() => {
			closeMenu();
		}, [pathname, closeMenu]);

		const logOut = useCallback(async () => {
			await authService.signOut();
		}, []);

		const signUp = useCallback(async () => {
			await authService.signUp();
		}, []);

		const signIn = useCallback(async () => {
			await authService.signIn();
		}, []);

		const mobileOverlayTransitions = useTransition(menuOpened, null, {
			from: { opacity: 0 },
			enter: { opacity: 1 },
			leave: { opacity: 0 },
		});

		const profileNavLinks: NavLinkItem[] = useMemo(
			() => [
				{
					label: 'Portfolio',
					link: `/portfolio/${currentUser?.name}`,
				},
				{
					label: 'Settings',
					link: '/settings',
				},
			],
			[currentUser]
		);

		const profileNavLinkProps: ComponentPropsWithRef<typeof ProfileNavLink> = useMemo(
			() => ({
				navLinks: profileNavLinks,
				signIn,
				signUp,
				logOut,
				loading,
			}),
			[loading, logOut, profileNavLinks, signIn, signUp]
		);

		const adminNavLinks = useMemo(() => {
			const navLinks: NavLinkItem[] = [];
			if (currentUser?.permissions.includes('CREATIVE_PROMPTS__WRITE')) {
				navLinks.push({
					label: 'Creative Prompts',
					link: '/creative-prompts',
				});
			}
			if (navLinks.length > 0) {
				const adminNavLink: NavLinkItem = {
					label: 'Admin',
					subLinks: navLinks,
				};
				return adminNavLink;
			} else {
				return undefined;
			}
		}, [currentUser]);

		const logoRef = useClickTracking({
			elementCategory: 'Main Menu',
			elementName: 'World Builder Logo',
			actionName: 'Navigation',
		});

		// Disable body scroll when the menu is open
		const overlayRef = useRef<HTMLDivElement>(null);
		useLayoutEffect(() => {
			const elem = overlayRef.current;
			if (elem && menuOpened) {
				disableBodyScroll(elem);
				return () => enableBodyScroll(elem);
			}
			return () => {};
		}, [menuOpened]);

		return {
			AnimatedIcon,
			AnimatedMobileOverlay,
			closeMenu,
			closedIcon,
			logOut,
			logoRef,
			mobileOverlayTransitions,
			openedIcon,
			menuOpened,
			profileNavLinkProps,
			adminNavLinks,
			toggleMenu,
			overlayRef,
			currentUser,
		};
	}
);

const PageNavigation = withHooks(PageNavigationTemplate, hooks);

export default PageNavigation;

import React, { useCallback, useEffect } from 'react';
import { useCurrentUserContext } from 'src/hooks';
import useGetSSOtoken from 'src/schema/useGetSSOtoken';
import authService from 'src/auth/authService';
import styles from './AuthCannyPage.module.scss';

/**
 * Props that can be passed to the `AuthCannyPage` component.
 */
interface AuthCannyPageProps {}

/**
 * `AuthCannyPage` component interface. This includes all possible overloads.
 */
interface AuthCannyPageComponent {
	(props: AuthCannyPageProps): JSX.Element;
}

const AuthCannyPage: AuthCannyPageComponent = () => {
	const { currentUser, loading } = useCurrentUserContext();
	const signIn = useCallback(async () => {
		await authService.signIn();
	}, []);

	const [getCannyToken, { data }] = useGetSSOtoken();

	useEffect(() => {
		if (currentUser) {
			getCannyToken({
				variables: {
					userId: currentUser.id,
				},
			});
		} else if (!loading) {
			signIn();
		}
	}, [currentUser, getCannyToken, loading, signIn]);

	useEffect(() => {
		if (data && data.cannyWebToken) {
			const getQueryParameterByName = (name: string) => {
				const pairStrings = window.location.search.slice(1).split('&');
				const pairs = pairStrings.map(function(pair) {
					return pair.split('=');
				});
				return pairs.reduce((value: any, pair) => {
					if (value) return value;
					return pair[0] === name ? decodeURIComponent(pair[1]) : null;
				}, null);
			};
			const getRedirectURL = (ssoToken: string) => {
				const redirectURL =
					getQueryParameterByName('redirect') ||
					'https://feedback.worldbuilder.tongal.com';
				if (redirectURL.indexOf('https://') !== 0) {
					return null;
				}
				return redirectURL.indexOf('?') === -1
					? `${redirectURL}?ssoToken=${ssoToken}`
					: `${redirectURL}&ssoToken=${ssoToken}`;
			};
			const redirectURL = getRedirectURL(data.cannyWebToken);
			if (redirectURL) {
				window.location.assign(redirectURL);
			}
		}
	}, [data]);

	return (
		<p className={styles['feedback-text']}>
			{loading
				? 'Loading'
				: `We are redirecting you to our ${
						!currentUser ? 'login' : 'feedback'
				  } page`}
			...
		</p>
	);
};

export default AuthCannyPage;

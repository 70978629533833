import { LogLevel } from './Logger';
import enabledSentryLogLevels from './enabledSentryLogLevels';

interface LoggerThreshold {
	(threshold: LogLevel, action: () => void, fallback?: () => void): void;
}

/**
 * @since 1.0.0
 * @author Mike Malone
 * @summary This middle layer service between the logger calls from SentryLogger
 * intercepts any log type that is not found in the env variable,
 * REACT_APP_SENTRY_LOGGING_THRESHOLD, within the .env.* files.  It works by
 * calling the logger function that is passed in (action), only if the condions
 * are met. If conditions are not met, it will call the passed in fallback, if
 * provided.
 * @description The REACT_APP_SENTRY_LOGGING_THRESHOLD variable should be
 * configured as a string of words separated by whitespace
 * @example
 * REACT_APP_SENTRY_LOGGING_THRESHOLD="error warn info debug"
 *
 * @param threshold
 * @param action
 * @param fallback
 * @function LoggerThreshold
 * @example
 * import LoggerThreshold from './loggerThreshold';
 *
 * // Pass the Sentry or Console function you want called along with the type of log
 * // (i.e debug, info etc.) and any tags with the following format.  Tags are optional.
 * const tags = { 'browser': 'Chrome 2.1', 'os.name': 'four, five, six' };
 *
 * loggerThreshold('debug', () => {
 *   console.log(message, tags);
 * });
 */
const loggerThreshold: LoggerThreshold = (threshold, action, fallback) => {
	const shouldPerformAction = enabledSentryLogLevels.includes(threshold);

	if (shouldPerformAction) {
		action();
	} else {
		fallback?.();
	}
};

export default loggerThreshold;

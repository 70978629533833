import { useApolloClient } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils';
import {
	useMemo,
	ComponentPropsWithoutRef,
	useCallback,
	useState,
} from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { withHooks } from 'src/common/utils';
import useAsync from 'src/hooks/useAsync';
import useGetCountries from 'src/hooks/useGetCountries';
import AuthAdditionalInformationPageTemplate from './AuthAdditionalInformationPage';
import AuthAdditionalInformationPageService from './AuthAdditionalInformationPageService';

type AuthAdditionalInfoResponse = {
	status: string;
	message: string;
	redirect: string;
};

function useAuthAdditionalInformationPageData(): ComponentPropsWithoutRef<
	typeof AuthAdditionalInformationPageTemplate
> {
	const apolloClient = useApolloClient();
	const authAdditionalInformationPageService = useMemo(
		() => new AuthAdditionalInformationPageService(apolloClient),
		[apolloClient]
	);

	const [sessionToken] = useQueryParam(
		'sessionToken',
		withDefault(StringParam, '')
	);
	const [state] = useQueryParam('state', withDefault(StringParam, ''));

	const [errorMessage, setErrorMessage] = useState<string>();

	const isApolloErrorResponse = (error: any): error is ErrorResponse =>
		(error as ErrorResponse).networkError !== undefined;

	const isApolloServerError = (error: any): error is ServerError =>
		(error as ServerError).result !== undefined;

	const isAdditionalInfoErrorResponse = (
		response: any
	): response is AuthAdditionalInfoResponse =>
		(response as AuthAdditionalInfoResponse).status !== undefined;

	const initialDataResponse = useAsync(
		useCallback(() => {
			return authAdditionalInformationPageService.getInitialData(
				sessionToken,
				state
			);
		}, [authAdditionalInformationPageService, sessionToken, state])
	);

	const onSubmit = useCallback<
		Extract<
			ComponentPropsWithoutRef<typeof AuthAdditionalInformationPageTemplate>,
			{ state: 'SUCCESS' }
		>['data']['onSubmit']
	>(
		async values => {
			try {
				const {
					redirect,
				} = await authAdditionalInformationPageService.submitData(
					sessionToken,
					state,
					values
				);
				if (redirect) window.location.href = redirect;
			} catch (error) {
				if (isApolloErrorResponse(error)) {
					if (isApolloServerError(error.networkError)) {
						if (isAdditionalInfoErrorResponse(error.networkError.result)) {
							console.log(error.networkError.result.message);
							setErrorMessage(error.networkError.result.message);
						}
					}
				}
			}
		},
		[authAdditionalInformationPageService, sessionToken, state]
	);

	const getCountriesResponse = useGetCountries();
	if (initialDataResponse.loading || getCountriesResponse.loading) {
		return { state: 'LOADING' };
	}

	if ('error' in initialDataResponse || getCountriesResponse.error) {
		return { state: 'ERROR', error: 'An unexpected error occurred' };
	}

	const { countriesData } = getCountriesResponse;

	return {
		state: 'SUCCESS',
		data: {
			initialValues: initialDataResponse.data,
			onSubmit,
			countriesOptions: countriesData!.map(key => ({
				label: key.name,
				value: parseInt(key.numericCode),
			})),
			errorMessage,
		},
	};
}

const AuthAdditionalInformationPage = withHooks(
	AuthAdditionalInformationPageTemplate,
	useAuthAdditionalInformationPageData
);

export default AuthAdditionalInformationPage;

import React from 'react';
import { Link } from 'react-router-dom';

import legoHead500 from './lego-head-500.svg';
import ErrorPage from './ErrorPage';

export const ErrorPage500: React.FC = () => (
	<ErrorPage
		subtitle="Oops! Our bad."
		image={legoHead500}
		showHomeButton
		content={
			<p>
				Our server is currently experiencing a problem.
				<br />
				Please try again later or <Link to="">contact support</Link> to report
				this issue.
			</p>
		}
	/>
);

export default ErrorPage500;

import React from 'react';

import ErrorPage from './ErrorPage';
import legoHead403 from './lego-head-403.svg';

export const ErrorPage403: React.FC = () => (
	<ErrorPage
		subtitle="Sorry, you don’t have access to this page."
		image={legoHead403}
		showHomeButton
		content={
			<p>
				This page has been hidden by its author or it is admin-only.
				<br />
				Please <a href="mailto:info@worldbuilder.tongal.com">
					contact support
				</a>{' '}
				if you feel you received this message in error.
			</p>
		}
	/>
);

export default ErrorPage403;

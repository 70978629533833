import { TestOptions } from 'yup';

/**
 * A test for fields to ensure only normal characters are accepted.
 * - Basic Latin: U+0020-U+007E
 * - Latin-1 Supplement: U+00A0-U+00FF
 * - Latin Extended-A: U+0100-U+017F
 * - Latin Extended-B: U+0180-U+024F
 * - Latin Extended Additional: U+1E00-U+1EFF
 * - LF: U+000A
 * - CR: U+000D
 *
 * @todo If we use this again, we'll want to expand the list to support things
 * like em dashes.
 * @see https://en.wikipedia.org/wiki/List_of_Unicode_characters
 */
export const normalCharTest: TestOptions = {
	// eslint-disable-next-line no-template-curly-in-string
	message: 'The ${label} must contain normal characters only',
	name: 'normal-chars',
	// Add code to make `value` type `unknown`
	test: (value: unknown) =>
		typeof value === 'string' &&
		// eslint-disable-next-line no-control-regex
		!/[^\u0020-\u007E\u00A0-\u024F\u1E00-\u1EFF\u2019\u201A\u000A\u000D]/g.test(
			value
		),
};

import tw from 'tailwind.macro';

import styled from 'src/theme';

export const Page = styled.div`
	${tw`
		overflow-hidden
		min-h-screen
		flex
		flex-col
	`}
`;

export const PageBody = styled.main`
	${tw`
		flex
		flex-1
		flex-col
		items-stretch
	`}

	> * {
		max-width: 100%;
	}
`;

import { useQuery, QueryResult } from '@apollo/client';
import {
	GetGenericUser,
	GetGenericUserVariables,
} from '../generated/GetGenericUser';

import GetGenericUserQuery from './GetGenericUserQuery';

/**
 * A hook to get a generic user by name.
 */
const useGetGenericUser = (
	variables: GetGenericUserVariables,
	onCompleted: (data: GetGenericUser) => void = () => {}
): QueryResult<GetGenericUser, GetGenericUserVariables> =>
	useQuery(GetGenericUserQuery, {
		variables,
		onCompleted,
		errorPolicy: 'all',
	});

export default useGetGenericUser;

import React from 'react';
import tw from 'tailwind.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { styled, css } from 'src/theme';
import styles from './Elements.module.scss';

const Label = styled.label<{ disabled?: boolean }>`
	${({ disabled }) =>
		disabled
			? tw`text-gray`
			: css`
					${tw`
				select-none
				cursor-pointer
			`}

					&:hover {
						${tw`text-blue-light`}
					}
			  `}
`;

const Input = styled.input.attrs({
	type: 'checkbox',
	className: styles['cb-hidden-input'],
})`
	${tw`appearance-none`}
`;

const FakeCheckbox = styled.span`
	transition: 150ms;
	${tw`
		inline-block
		w-4
		h-4
		border
		border-solid
		border-gray
		rounded-sm
		inline-flex
		flex-column
		items-center
		content-center
		justify-center
		text-transparent
		text-base
		align-top
	`}

	label:hover ${Input}:enabled + & {
		${tw`border-blue-light`}
	}

	${Input}:enabled:checked + & {
		${tw`text-black`}
	}

	${Input}:disabled:checked + & {
		${tw`text-gray`}
	}
`;

const LabelText = styled.span`
	transition: 150ms;
	${tw`
		ml-2
		align-middle
	`}
`;

type CheckboxProps = Omit<React.ComponentPropsWithRef<'input'>, 'type'> & {
	label?: React.ReactNode;
};

/**
 * A styled checkbox with an optional `label`. The `label`, `style`, and
 * `className` are all used by the wrapper, while the remainder of the props are
 * passed through to the HTML checkbox.
 */
export const Checkbox = ({
	label,
	style,
	className,
	...checkboxProps
}: CheckboxProps): JSX.Element => {
	const { disabled } = checkboxProps;
	return (
		<Label style={style} className={className} disabled={disabled}>
			<Input {...checkboxProps} />
			<FakeCheckbox>
				<FontAwesomeIcon icon={faCheck} />
			</FakeCheckbox>
			{label && <LabelText>{label}</LabelText>}
		</Label>
	);
};

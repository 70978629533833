import React from 'react';
import classNames from 'classnames';
import { Container } from 'src/elements';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
	children?: React.ReactNode;

	/**
	 * Use a custom background image component i.e: <Image />
	 */
	backgroundImage?: React.ReactNode;
	styleOverrides?: Partial<
		Pick<typeof styles, 'page-header-background' | 'page-header-text'>
	>;
}

interface PageHeaderComponent {
	(props: PageHeaderProps): JSX.Element;
}

const PageHeader: PageHeaderComponent = ({
	children,
	backgroundImage,
	styleOverrides,
}) => {
	const styling = {
		...styles,
		...styleOverrides,
	};

	return (
		<div
			className={classNames(
				styling['page-header-base'],
				styling['page-header-background'],
				styling['page-header-text']
			)}
		>
			{backgroundImage}
			<Container>{children}</Container>
		</div>
	);
};

export default PageHeader;

import { FormikConfig } from 'formik';
import React from 'react';
import { object } from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import CheckboxFieldGroup from 'src/components/CheckboxFieldGroup/CheckboxFieldGroup';
import DateField from 'src/components/DateField';
import Form from 'src/components/Form';
import PageHeader from 'src/components/PageHeader';
import SectionLayout from 'src/components/SectionLayout';
import SelectField from 'src/components/SelectField';
import SubmitButton from 'src/components/SubmitButton';
import TextField from 'src/components/TextField';
import { SelectInputOption } from 'src/components/SelectInput/SelectInput';
import { MainTitle, ErrorMessage } from '../Create/Create.style';
import { ErrorPage500 } from '../Errors';
import styles from './AuthAdditionalInformationPage.module.scss';
import { AuthAdditionalInformationPageFormData } from './AuthAdditionalInformationPageService';
import createMinAgeTest from './createMinAgeTest';

type Loadable<T> =
	| {
			state: 'LOADING';
			error?: undefined;
			errorMessage?: undefined;
			data?: undefined;
	  }
	| {
			state: 'ERROR';
			error: unknown;
			errorMessage?: undefined;
			data?: undefined;
	  }
	| { state: 'SUCCESS'; error?: undefined; errorMessage?: undefined; data: T };

interface AuthAdditionalInformationPageTemplateProps {
	initialValues: AuthAdditionalInformationPageFormData;
	onSubmit: FormikConfig<AuthAdditionalInformationPageFormData>['onSubmit'];
	errorMessage?: string | undefined;
	countriesOptions: SelectInputOption<number>[];
}

type LoadableAuthAdditionalInformationPageTemplateProps = Loadable<
	AuthAdditionalInformationPageTemplateProps
>;

interface AuthAdditionalInformationPageTemplateType {
	(props: LoadableAuthAdditionalInformationPageTemplateProps): JSX.Element;
}

const fieldNames: { [k in keyof AuthAdditionalInformationPageFormData]: k } = {
	userName: 'userName',
	emailAddress: 'emailAddress',
	birthdate: 'birthdate',
	country: 'country',
	receiveNewsletters: 'receiveNewsletters',
	acceptCookies: 'acceptCookies',
	acceptTerms: 'acceptTerms',
};

const AuthAdditionalInformationPageTemplate: AuthAdditionalInformationPageTemplateType = props =>
	props.state === 'LOADING' ? (
		<div className={styles.loader}>
			<FontAwesomeIcon icon={faCircleNotch} spin />
		</div>
	) : props.state === 'ERROR' ? (
		<ErrorPage500 />
	) : (
		<>
			<PageHeader>
				<MainTitle>Welcome to LEGO World Builder</MainTitle>
			</PageHeader>
			<SectionLayout type={'NARROW'} background={false}>
				<p className={styles.prompt}>
					We just need a few more details from you before we can make it
					official.
				</p>
				<Form
					initialValues={props.data.initialValues}
					onSubmit={props.data.onSubmit}
				>
					<TextField
						label="Username*"
						name={fieldNames.userName}
						placeholder="Your username"
						required
						maxLength={15}
						test={{
							test: value => !value || /^[a-zA-Z0-9_.+-]+$/.test(value),
							message:
								'The username can only contain letters, numbers, and the following characters: "_", ".", "+", "-"',
						}}
					/>
					<TextField
						label="Email Address*"
						name={fieldNames.emailAddress}
						placeholder="Your email address"
						required
						email
						maxLength={255}
					/>
					<div className={styles['grid-2-md']}>
						<div className={styles['grid-2-md-cell']}>
							<DateField
								label="Date of Birth*"
								noun="date of birth"
								name={fieldNames.birthdate}
								required
								test={createMinAgeTest(18)}
								max={new Date()}
							/>
						</div>
						<div className={styles['grid-2-md-cell']}>
							<SelectField
								label="Country of Residence*"
								baseSchema={object().nullable()}
								name={fieldNames.country}
								noun="country of residence"
								isSearchable
								options={props.data.countriesOptions}
								placeholder="Choose your location"
								required
							/>
						</div>
					</div>

					<CheckboxFieldGroup
						label="Notification and Cookie Preferences"
						list={[
							{
								body: 'I wish to receive newsletters from LEGO World Builder',
								inputName: fieldNames.receiveNewsletters,
							},
							{
								body:
									'I wish to allow LEGO World Builder to use cookies that will enhance my website browsing experience and measure website performance',
								inputName: fieldNames.acceptCookies,
							},
						]}
					/>
					<CheckboxFieldGroup
						label="Terms of Service Agreement*"
						list={[
							{
								required: 'You must agree to the terms of service',
								body: (
									<>
										I have read and understand the 
										<a
											href="https://worldbuilder.tongal.com/termsofservice"
											target="_blank"
											rel="noopener noreferrer"
										>
											Terms of Service
										</a>
									</>
								),
								inputName: fieldNames.acceptTerms,
							},
						]}
					/>
					<div className={styles['button-area']}>
						<SubmitButton>Submit</SubmitButton>
						{props.data.errorMessage && (
							<ErrorMessage>{props.data.errorMessage}</ErrorMessage>
						)}
					</div>
				</Form>
			</SectionLayout>
		</>
	);

export default AuthAdditionalInformationPageTemplate;

/**
 * @todo Write tests. Need to find a way to mock a browser window and resize.
 */

import { useEffect, useState } from 'react';

interface Size {
	width: number;
	height: number;
}

function getSize(): Size {
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
}

/**
 * Hook which gets the current window size. It will re-render the component when
 * the window is resized.
 */
export function useWindowSize(): Size {
	const [size, setSize] = useState(() => getSize());
	useEffect(() => {
		const handler = (): void => setSize(getSize());
		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
	});
	return size;
}

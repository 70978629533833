import React, {
	useContext,
	useRef,
	useEffect,
	useState,
	useCallback,
	useMemo,
} from 'react';
import { Prompt } from 'react-router-dom';

/**
 * Fix to allow the prompt to be hidden from anywhere in the app. This is
 * considered dangerous because it is a flag that will then silence all change
 * prompts from showing. We should only set this to true if we are absolutely
 * sure the user is going to leave the page immediately.
 *
 * If for some reason you must stay on the page, reset this flag to false as
 * soon as possible.
 */
export const NAVIGATION_PROMPT_GLOBAL_OVERRIDES = {
	dangerouslyAllowToLeavePage: false,
};

const UseNavigationPromptContext = React.createContext({
	register: (key: object): void => {
		throw new Error('Unimplemented operation "register"');
	},
	unregister: (key: object): void => {
		throw new Error('Unimplemented operation "unregister"');
	},
});

/**
 * A hook to prevent navigation via routes or manual broswer navigation (back,
 * refresh, etc). Pass in a boolean for whether or not to block navigation. This
 * will often be used as a `hasUnsavedChanges` flag.
 *
 * @example
 * function MyComponent() {
 *   useNavigationPrompt(hasUnsavedChanges);
 * }
 *
 * @todo Pass custom error messages (this will only work for navigation within
 * our site and on certain browsers for external navigation))
 */
const useNavigationPrompt = (shouldBlock: boolean): void => {
	const key = useRef({});
	const context = useContext(UseNavigationPromptContext);

	const cleanup = useRef<() => void>();
	useMemo(() => {
		cleanup.current?.();
		if (shouldBlock) {
			context.register(key);
			cleanup.current = () => {
				context.unregister(key);
			};
		}
	}, [context, shouldBlock]);
	useEffect(() => () => cleanup.current?.(), []);
};

/**
 * This provider enables the use of the `useNavigationPrompt` hook. It must be
 * placed under a router.
 */
const UseNavigationPromptProvider: React.FC = ({ children }) => {
	const shouldBlockRef = useRef(false);
	const [activeBlocks] = useState(() => new Set<object>());

	const updateShouldBlock = useCallback(() => {
		shouldBlockRef.current = activeBlocks.size > 0;
	}, [activeBlocks]);

	const register = useCallback(
		(key: object) => {
			activeBlocks.add(key);
			updateShouldBlock();
		},
		[activeBlocks, updateShouldBlock]
	);

	const unregister = useCallback(
		(key: object) => {
			activeBlocks.delete(key);
			updateShouldBlock();
		},
		[activeBlocks, updateShouldBlock]
	);

	const context = useMemo(() => ({ register, unregister }), [
		register,
		unregister,
	]);

	const promptMessageGetter = useCallback(
		() => !shouldBlockRef.current || 'Changes you made may not be saved.',
		[]
	);

	useEffect(() => {
		const onLeave = (event: BeforeUnloadEvent): string | void => {
			if (
				shouldBlockRef.current &&
				!NAVIGATION_PROMPT_GLOBAL_OVERRIDES.dangerouslyAllowToLeavePage
			) {
				event.preventDefault();
				event.returnValue = '';
				return '';
			}
		};

		window.addEventListener('beforeunload', onLeave);
		return () => {
			window.removeEventListener('beforeunload', onLeave);
		};
	}, []);

	return (
		<UseNavigationPromptContext.Provider value={context}>
			<Prompt message={promptMessageGetter} />
			{children}
		</UseNavigationPromptContext.Provider>
	);
};

export default useNavigationPrompt;
export { UseNavigationPromptProvider };

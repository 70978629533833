import { useRef, useCallback, Ref } from 'react';
import { send } from '../trackingService';
import { ElementClickTrackingEvent } from '../TrackingEvent';

type ClickTrackingProps = Pick<
	ElementClickTrackingEvent,
	'elementName' | 'elementCategory' | 'actionName'
>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ClickRef = Extract<Ref<any>, Function>;

/**
 * Hook to add click tracking to a specific element. It's very important that
 * you memoize the object being passed in
 *
 * @example
 * const elemRef = useClickTracking({
 *   name: `${item.name} Click`,
 *   elementCategory: 'Main Menu',
 *   elementName: 'Sign-in Button',
 * });
 * return <button ref={elemRef} ... />
 */
export default function useClickTracking({
	actionName,
	elementCategory,
	elementName,
}: ClickTrackingProps): ClickRef {
	const onClick = useCallback(() => {
		send({
			type: 'ELEMENT_CLICK',
			elementCategory,
			elementName,
			actionName,
		});
	}, [elementCategory, elementName, actionName]);

	const cleanupRef = useRef<() => void>();
	return useCallback<ClickRef>(
		element => {
			cleanupRef.current?.();
			element?.addEventListener('click', onClick);
			cleanupRef.current = () => element?.removeEventListener('click', onClick);
		},
		[onClick]
	);
}

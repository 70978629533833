import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import CookieService from 'src/service/CookieService';

interface AuthExtendPageComponent {
	(): JSX.Element;
}

function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

const AuthExtendPage: AuthExtendPageComponent = () => {
	const query = useQuery();
	const history = useHistory();
	const token = query.get('token');
	const returnTo = query.get('returnTo');

	useEffect(() => {
		(async () => {
			// If we want to make a request for the BE to set a cookie, we can do that here
			// await fetch(
			// 	`${process.env.REACT_APP_BE_CORE_API}/auth/extend?token=${token}`,
			// 	{
			// 		method: 'GET',
			// 		cache: 'no-cache',
			// 	}
			// );

			if (token) {
				CookieService.setCooke('lwb-extended-access', token);
			}

			history.replace(returnTo || '/');
		})();
	}, [history, returnTo, token]);
	return <></>;
};

export default AuthExtendPage;

import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import PageErrorRoute from 'src/common/PageErrorRoute';
import uuidParam from 'src/common/utils/uuidParam';

import useProfileViewTest from 'src/pages/Profile/auth/useProfileViewTest';
import { send } from 'src/tracking/trackingService';
import authService from 'src/auth/authService';
import callOnce from 'src/common/callOnce';
import AuthCallbackPage from './AuthCallbackPage';
import AuthCannyPage from './AuthCannyPage';

import { ErrorPage404 } from './Errors';
import PageErrorBoundary from './PageErrorBoundary';
import AuthAdditionalInformationPage from './AuthAdditionalInformationPage';
import AuthExtendPage from './AuthExtendPage';
import UpdateCreativePromptsPageRoute from './UpdateCreativePromptsPage/route/UpdateCreativePromptsPageRoute';
import AuthLoginPage from './AuthLoginPage/AuthLoginPage';
import AuthRegisterPage from './AuthRegisterPage/AuthRegisterPage';

const About = loadable(() => import('./About'));
const AuthVerifyPage = loadable(() => import('./AuthVerifyPage'));
const Discover = loadable(() => import('./Discover'));
const NowInDevelopment = loadable(() => import('./NowInDevelopment'));
const EmailUnverified = loadable(() => import('./Errors/EmailUnverified'));
const HomePage = loadable(() => import('./HomePage'));
const Profile = loadable(() => import('./Profile'));
const Settings = loadable(() => import('./Settings'));
const LiveActionLandingPage = loadable(() =>
	import('./landing/LiveActionLandingPage')
);
const NinjagoLandingPage = loadable(() =>
	import('./landing/NinjagoLandingPage')
);
// const FortniteLandingPage = loadable(() =>
// 	import('./landing/FortniteLandingPage')
// );

const LegoDreamzzzLandingPage = loadable(() =>
	import('./landing/LegoDreamzzzLandingPage')
);

const PitchFestLandingPage = loadable(() =>
	import('./landing/PitchFestLandingPage')
);
const ComicsProgramLandingPage = loadable(() =>
	import('./landing/ComicsProgramLandingPage')
);
const TermsOfServicePage = loadable(() => import('./TermsOfServicePage'));
const WorldRouter = loadable(() => import('./world/WorldRouter'));
const CreateRouter = loadable(() => import('./Create/CreateRouter'));

const initAuthService = callOnce(() => {
	authService.init();
});

const InnerRouter: React.FC = () => {
	const history = useHistory();
	useEffect(() => {
		send('PAGEVIEW');
		return history.listen(() => send('PAGEVIEW'));
	}, [history]);

	useEffect(() => {
		initAuthService();
	}, []);

	return (
		<PageErrorBoundary>
			<Switch>
				<Route exact path={['/', '/index.html']} component={HomePage} />
				<Route path="/about" component={About} />
				<Route exact path="/auth/unverified" component={EmailUnverified} />
				<Route path="/create" component={CreateRouter} />

				<Route exact path="/discover" component={Discover} />
				<Route exact path="/now-in-development" component={NowInDevelopment} />
				<PageErrorRoute
					test={useProfileViewTest}
					exact
					path={`/portfolio/:username`}
					render={({
						match: {
							params: { username },
						},
					}) => <Profile username={username} />}
				/>
				<Route exact path="/settings" component={Settings} />
				<Route exact path="/termsofservice" component={TermsOfServicePage} />
				<Route exact path="/ninjago" component={NinjagoLandingPage} />
				{/* <Route exact path="/legofortnite" component={FortniteLandingPage} /> */}
				<Route exact path="/dreamzzz" component={LegoDreamzzzLandingPage} />
				<Route exact path="/live-action" component={LiveActionLandingPage} />
				<Route exact path="/pitch-to-lego" component={PitchFestLandingPage} />
				{process.env.REACT_APP_ENABLE_COMICS_PROGRAM === 'true' && (
					<Route exact path="/comics" component={ComicsProgramLandingPage} />
				)}
				<Route
					path={`/world/${uuidParam('worldId')}`}
					component={WorldRouter}
				/>
				<Route exact path="/feedback" component={AuthCannyPage} />
				<Route
					exact
					path="/auth/additional-information"
					component={AuthAdditionalInformationPage}
				/>
				<UpdateCreativePromptsPageRoute />
				<Route component={ErrorPage404} />
			</Switch>
		</PageErrorBoundary>
	);
};

const Router: React.FC = () => {
	return (
		// Allow the user to be provisioned before sending to the regular router,
		// which will initialize the user
		<Switch>
			<Route exact path="/callback" component={AuthCallbackPage} />
			<Route exact path="/auth/verify" component={AuthVerifyPage} />
			<Route exact path="/auth/extend" component={AuthExtendPage} />
			<Route exact path="/auth/login" component={AuthLoginPage} />
			<Route exact path="/auth/register" component={AuthRegisterPage} />
			<Route component={InnerRouter} />
		</Switch>
	);
};

export default Router;

import { Machine, State as XState } from 'xstate';

import { BaseEvent, MakeSchema } from 'src/common/xstate';

export type Schema = MakeSchema<{
	sidebarOpen: {};
	sidebarClosed: {};
}>;

export interface Context {}

export type Event =
	| { type: 'SIDEBAR_OPEN' }
	| { type: 'SIDEBAR_CLOSED' }
	| BaseEvent;

export type State = XState<Context, Event>;

export default Machine<Context, Schema, Event>({
	initial: 'sidebarClosed',
	states: {
		sidebarOpen: {
			on: { SIDEBAR_CLOSED: 'sidebarClosed' },
		},
		sidebarClosed: {
			on: { SIDEBAR_OPEN: 'sidebarOpen' },
		},
	},
});

/**
 * @todo Write tests. Need to find a way to mock a browser window and resize.
 */

import { useMemo } from 'react';

import { screens } from 'src/theme';

import { useWindowSize } from './useWindowSize';

type Screens = typeof screens;
type Responsive = keyof Screens | 'none';

type ResponsiveSizes = {
	[K in Responsive]: number;
};

export const responsiveSizes: ResponsiveSizes = {
	sm: parseInt(screens.sm, 10),
	md: parseInt(screens.md, 10),
	lg: parseInt(screens.lg, 10),
	none: 0,
};

/**
 * Hook to add programmatic responsiveness to a component. If we pass a specific
 * threshold size, this will return a boolean indicating whether or not it has
 * passed that size. If we don't pass anything, it will return the current
 * responsive size.
 */
function useResponsive(): Responsive;
function useResponsive(threshold: Responsive): boolean;
function useResponsive(threshold?: Responsive): Responsive | boolean {
	const { width } = useWindowSize();
	const responsiveAndSize = useMemo(
		() =>
			Object.entries(responsiveSizes)
				.filter(([, size]) => width >= size)
				.sort(([, aSize], [, bSize]) => bSize - aSize)[0] as
				| [Responsive, number]
				| undefined,
		[width]
	);
	if (threshold) {
		return width >= responsiveSizes[threshold];
	} else if (responsiveAndSize) {
		return responsiveAndSize[0];
	} else {
		return 'none';
	}
}

export { useResponsive };

import gql from 'graphql-tag';

const PostAuthAdditionalInfoMutation = gql`
	mutation PostAuthAdditionalInfo(
		$sessionToken: String!
		$state: String!
		$input: AuthAdditionalInfoInput!
	) {
		postAuthAdditionalInfo(
			sessionToken: $sessionToken
			state: $state
			input: $input
		)
			@rest(
				type: "AuthAdditionalInfoResponse"
				path: "user/additional-info?sessionToken={args.sessionToken}&state={args.state}"
				method: "POST"
				bodyKey: "input"
			) {
			redirect
		}
	}
`;

export default PostAuthAdditionalInfoMutation;

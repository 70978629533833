import {
	InMemoryCache,
	NormalizedCacheObject,
	ApolloClient,
	ApolloClientOptions,
	ApolloError,
	Resolvers,
	ServerError,
	ServerParseError,
	QueryHookOptions,
	QueryResult,
	useQuery,
	MutationHookOptions,
	MutationTuple,
	useMutation,
} from '@apollo/client';

import { DocumentNode } from 'graphql';
import log from 'src/common/logger';

// TODO: write tests for this file. See ticket:
// https://tongal.atlassian.net/browse/LWB-140

const clients: ApolloClient<NormalizedCacheObject>[] = [];
const addedResolvers: Resolvers[] = [];
const getDefaultOpts = (): ApolloClientOptions<NormalizedCacheObject> => ({
	cache: new InMemoryCache(),
	credentials: 'include',
});

/**
 * Create an apollo client with the ability to extend the default config.
 *
 * Resolvers are appended, whereas all other options will overwrite.
 * @param opts Apollo options to extend the config
 */
export function createClient(
	opts: Partial<ApolloClientOptions<NormalizedCacheObject>> = {}
): ApolloClient<NormalizedCacheObject> {
	const resolvers = [...addedResolvers];
	if (opts.resolvers instanceof Array) {
		resolvers.push(...opts.resolvers);
	} else if (opts.resolvers) {
		resolvers.push(opts.resolvers);
	}
	const client = new ApolloClient({
		...getDefaultOpts(),
		...opts,
		resolvers,
	});
	clients.push(client);
	return client;
}

/**
 * A helper to determine if a value has a property. Adds a type guard for use in
 * conditional code blocks.
 */
function hasProperty<P extends string>(
	value: unknown,
	prop: P
): value is { [K in P]: unknown } {
	return typeof value === 'object' && !!value && prop in value;
}

type AllExplicit =
	| object
	| string
	| number
	| boolean
	| null
	| undefined
	| unknown[];
interface ResolversOrDefaults {
	[key: string]: {
		// This allows us to provide either a value _or_ a specific resolver. We
		// will be able to simplify as well as make it less restrictive once negated
		// types are supported:
		// https://github.com/microsoft/TypeScript/projects/9#card-16842305
		[field: string]: Resolvers[string][string] | AllExplicit;
	};
}

function normalizeDefaults(
	resolversOrDefaults: ResolversOrDefaults
): Resolvers {
	return Object.entries(resolversOrDefaults).reduce(
		(curField, [key, resolvers]) =>
			Object.assign(curField, {
				[key]: Object.entries(resolvers).reduce(
					(curResolver, [field, resolverOrDefault]) =>
						Object.assign(curResolver, {
							[field]:
								typeof resolverOrDefault === 'function'
									? resolverOrDefault
									: (value: unknown) => {
											if (hasProperty(value, field)) {
												return value[field];
											}
											return resolverOrDefault;
									  },
						}),
					{}
				),
			}),
		{}
	);
}

/**
 * Helper method to add resolvers to clients.
 *
 * This helps when creating resolvers in a distributed fashion, or in modules
 * loaded asynchronously. This will ensure the resolver is available on all
 * clients created through this helper whether or not they have been created yet.
 * @param resolvers
 */
export function addResolvers(resolversOrDefaults: ResolversOrDefaults): void {
	const resolvers = normalizeDefaults(resolversOrDefaults);
	addedResolvers.push(resolvers);
	clients.forEach(client => client.addResolvers(resolvers));
}

/**
 * A hack to figure out if the networkError is an instance of ServerError or ServerParserError.
 *
 * Both of those have a `statusCode`, which we can use to handle network errors in a more fine
 * grained way.
 */
function hasStatusCode(
	networkError: Error | ServerError | ServerParseError
): networkError is ServerError | ServerParseError {
	return (
		(networkError as ServerError | ServerParseError).statusCode !== undefined
	);
}

/**
 * Function that takes an error and does something with it.
 */
interface ApolloErrorHandler<T> {
	(error: ServerError | ServerParseError): T;
}

/**
 * Defines the type signatures of the handlers of ApolloErrors
 */
interface ApolloErrorHandlers<T> {
	error401Handler: ApolloErrorHandler<T>;
	error403Handler: ApolloErrorHandler<T>;
	error500Handler: ApolloErrorHandler<T>;
	defaultHandler: ApolloErrorHandler<T>;
}

/**
 * Takes a single error handler object or single function and fills out a full
 * error handler object.
 * @param handlers Partial handlers, or a single one to apply to all.
 */
function fillErrorHandlers<T>(
	handlers?: Partial<ApolloErrorHandlers<T>> | ApolloErrorHandler<T>
): ApolloErrorHandlers<T | void> {
	if (typeof handlers === 'function') {
		return {
			error401Handler: handlers,
			error403Handler: handlers,
			error500Handler: handlers,
			defaultHandler: handlers,
		};
	} else {
		const {
			error401Handler = error => log.error(`[401 Network error]: ${error}`),
			error403Handler = error => log.error(`[403 Network error]: ${error}`),
			error500Handler = error => log.error(`[500 Network error]: ${error}`),
			defaultHandler = error => log.error(`[Network error]: ${error}`),
		}: ApolloErrorHandlers<T | void> = (handlers || {}) as ApolloErrorHandlers<
			T
		>;
		return {
			error401Handler,
			error403Handler,
			error500Handler,
			defaultHandler,
		};
	}
}

/**
 * In the event of a network error, handle that error with the default
 * implementations or the supplied handler methods
 */
function handleNetworkError<T>(
	networkError: Error | ServerError | ServerParseError,
	{
		error401Handler,
		error403Handler,
		error500Handler,
		defaultHandler,
	}: ApolloErrorHandlers<T>
): T | void {
	if (hasStatusCode(networkError)) {
		const errorCode: number = networkError.statusCode;
		switch (errorCode) {
			case 401:
				return error401Handler(networkError);
			case 403:
				return error403Handler(networkError);
			case 500:
				return error500Handler(networkError);
			default:
				return defaultHandler(networkError);
		}
	}
	return undefined;
}

/**
 * Handle an Appollo error using a supplied set of error handlers. If only one
 * handler is provided (not an object), it will be used for all errors. If a
 * single error handler is provided or the handler object is complete, we know
 * the return type of `T`, otherwise, the return type may be `void`.
 * @todo Figure out if one of `graphQLErrors` or `networkError` must be
 * provided. That may remove the overloads because we couldn't guarentee the
 * return type of `T`.
 */
export function handleApolloError<T>(
	error: ApolloError,
	handlers: ApolloErrorHandlers<T> | ApolloErrorHandler<T>
): T;
export function handleApolloError<T>(
	error: ApolloError | undefined,
	handlers?: Partial<ApolloErrorHandlers<T>> | ApolloErrorHandler<T>
): T | void;
export function handleApolloError<T>(
	error?: ApolloError,
	handlers?: Partial<ApolloErrorHandlers<T>> | ApolloErrorHandler<T>
): T | void {
	if (error) {
		const filledHandlers = fillErrorHandlers(handlers);
		if (error.graphQLErrors && error.graphQLErrors.length) {
			// TODO: Do stuff
			throw new Error('Not implemented');
		}

		if (error.networkError) {
			return handleNetworkError(error.networkError, filledHandlers);
		}
	}
	return undefined;
}

/**
 * Helper to create typed `useQuery` hooks to be able to simply import a
 * single dependency and have it already be typed. Example:
 * ```ts
 * const useGetThing = buildQueryHook<
 * 	GetThingData,
 * 	GetThingVariables
 * >(GET_THING_QUERY);
 * ```
 */
export function buildQueryHook<TData, TVariables>(
	query: DocumentNode
): (
	options?: QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables> {
	return options => useQuery<TData, TVariables>(query, options);
}

/**
 * Helper to create typed `useMutation` hooks to be able to simply import a
 * single dependency and have it already be typed. Example:
 * ```ts
 * const useUpdateThing = buildMutationHook<
 * 	UpdateThingData,
 * 	UpdateThingVariables
 * >(UPDATE_THING_MUTATION);
 * ```
 */
export function buildMutationHook<TData, TVariables>(
	mutation: DocumentNode
): (
	options?: MutationHookOptions<TData, TVariables>
) => MutationTuple<TData, TVariables> {
	return options => useMutation<TData, TVariables>(mutation, options);
}

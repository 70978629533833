import React, { Ref, FormHTMLAttributes } from 'react';
import { Formik, FormikConfig, FormikValues, FormikProps } from 'formik';
import useNavigationPrompt from 'src/hooks/useNavigationPrompt';

export interface FormProps<V extends FormikValues>
	extends Omit<
			FormikConfig<V>,
			'component' | 'render' | 'validate' | 'validationSchema'
		>,
		Pick<FormHTMLAttributes<HTMLFormElement>, 'action' | 'method' | 'target'> {
	alertLosingWork?: boolean;
	children: React.ReactNode;
}

type InnerFormProps<V extends FormikValues> = FormikProps<V> &
	Pick<
		FormProps<V>,
		'alertLosingWork' | 'children' | 'action' | 'method' | 'target'
	>;

const InnerForm = React.forwardRef(
	<V extends FormikValues>(
		{
			children,
			handleSubmit,
			handleReset,
			dirty,
			isSubmitting,
			status,
			submitCount,
			alertLosingWork,
			action,
			target,
			/**
			 * The POST here is to make all forms not default to GET. If a user
			 * gets a form and has JS disabled, the form will submit. Using GET,
			 * their text entered will be put into the URL. This could mean some
			 * PII in our logs we don't want them to be in.
			 */
			method = 'POST',
		}: InnerFormProps<V>,
		ref?: Ref<HTMLFormElement>
	) => {
		useNavigationPrompt(!!(alertLosingWork && dirty));
		return (
			<form
				ref={ref}
				onSubmit={handleSubmit}
				onReset={handleReset}
				method={method}
				action={action}
				target={target}
			>
				{children}
			</form>
		);
	}
) as <V extends FormikValues>(
	props: InnerFormProps<V> & { ref?: Ref<HTMLFormElement> }
) => JSX.Element;

const Form = React.forwardRef(
	<V extends FormikValues = FormikValues>(
		{
			alertLosingWork,
			action,
			method,
			children,
			target,
			validateOnMount,
			...props
		}: FormProps<V>,
		ref?: Ref<HTMLFormElement>
	): JSX.Element => (
		<Formik {...props} validateOnMount={validateOnMount ?? true}>
			{formProps => (
				<InnerForm
					alertLosingWork={alertLosingWork}
					action={action}
					method={method}
					target={target}
					{...formProps}
					ref={ref}
				>
					{children}
				</InnerForm>
			)}
		</Formik>
	)
) as <V extends FormikValues>(
	props: FormProps<V> & { ref?: Ref<HTMLFormElement> }
) => JSX.Element;

export default Form;

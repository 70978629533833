type SetCookieValue = string | number | boolean;

interface CookieServiceInterface {
	getCookie: (name: string) => string;
	setCooke: (
		name: string,
		value: SetCookieValue,
		numberOfDaysToExpire?: number
	) => void;
	deleteCookie: (name: string) => void;
}

/**
 * @since 1.0
 * @summary Cookie Service that allows you to get, add, delete a cookie
 * @returns CookieService
 */
const CookieService = (): CookieServiceInterface => {
	/**
	 *
	 * @param name {string} The name of the cookie
	 * @function getCookie
	 */
	const getCookie = (name: string): string => {
		const cookieName = name + '';
		const decodedCookieValue = decodeURIComponent(document.cookie);

		const splitCookie = decodedCookieValue.split(';');

		for (let i = 0; i < splitCookie.length; i++) {
			let cookieBit = splitCookie[i];
			while (cookieBit.charAt(0) === ' ') {
				cookieBit = cookieBit.substring(1);
			}

			if (cookieBit.indexOf(cookieName) === 0) {
				return cookieBit.substring(cookieName.length + 1, cookieBit.length);
			}
		}

		return '';
	};

	/**
	 *
	 * @param name {string} The name of the cookie
	 * @param value {SetCookieValue} The value of the cookie
	 * @param numberOfDaysToExpire {number} The number of days before the cookie expires
	 */
	const setCooke = (
		name: string,
		value: SetCookieValue,
		numberOfDaysToExpire: number | undefined = undefined
	): void => {
		const cookieDate = new Date();
		cookieDate.setTime(
			cookieDate.getTime() + (numberOfDaysToExpire ?? 0) * 24 * 60 * 60 * 1000
		);
		const expires = `expires=${cookieDate.toISOString()}`;

		if (typeof numberOfDaysToExpire === 'undefined') {
			document.cookie = `${name}=${value}; path=/`;
		} else {
			document.cookie = `${name}=${value}; ${expires}; path=/`;
		}
	};

	/**
	 *
	 * @param name {string} The name of the cookie
	 */
	const deleteCookie = (name: string): void => {
		document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
	};

	return {
		getCookie,
		setCooke,
		deleteCookie,
	};
};

export default CookieService();

import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';

import { createClient } from 'src/common/graphql';
import authService from 'src/auth/authService';
import logger from 'src/common/logger';
import modalService from 'src/modal/modalService';
import createModalRenderer from 'src/modal/createModalRenderer';
import Confirmation from 'src/components/Confirmation';
import DynamicConfirmationProps from 'src/components/Confirmation/DynamicConfirmationProps';
import { NAVIGATION_PROMPT_GLOBAL_OVERRIDES } from 'src/hooks/useNavigationPrompt';
import CookieService from 'src/service/CookieService';

let refreshModalPromise: Promise<void>;

const apolloClient = createClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError, operation }) => {
			const context = {
				'Operation Name': operation.operationName,
				Variables: JSON.stringify(operation.variables, null, 2),
			};
			if (graphQLErrors) {
				/**
				 * Prevent sentry reports for some expected Access Denied errors
				 */
				const filteredAccessDeniedErrors = graphQLErrors.filter(
					({ message }) =>
						!(
							[
								'GetWorld',
								'GetWorldElement',
								'GetWorldElementContribution',
							].includes(operation.operationName) &&
							message.includes('Access Denied')
						)
				);

				filteredAccessDeniedErrors.forEach(({ message }) => {
					logger.error(message, { context });
				});
			}
			if (networkError) {
				logger.error(networkError, { context });
			}
		}),
		setContext(async (_, { headers }) => {
			let token = null;
			try {
				token = await authService.getToken();
			} catch (error) {
				if (
					error.error === 'login_required' &&
					authService.state.isAuthenticated
				) {
					if (!refreshModalPromise) {
						refreshModalPromise = modalService.openModal({
							closeButton: false,
							interactiveOverlay: false,
							render: createModalRenderer(
								Confirmation,
								DynamicConfirmationProps({
									cancellationButtonText: '',
									confirmationButtonText: 'OK',
									confirmationMethod: () => {
										NAVIGATION_PROMPT_GLOBAL_OVERRIDES.dangerouslyAllowToLeavePage = true;
										authService.signIn();
										// Keep the dialog open while we refresh
										return new Promise(() => {});
									},
									title: 'Session Expired',
									message:
										'Your session has expired. Please log in again to continue creating and exploring.',
								})
							),
						});
					}

					// await the modal so we don't send the network request
					await refreshModalPromise;
				}
			}

			const extendedAccessCookie = CookieService.getCookie(
				'lwb-extended-access'
			);

			return {
				headers: {
					...headers,
					authorization: token ? `Bearer ${token}` : '',
					...(extendedAccessCookie
						? { 'Lwb-Extended-Access': extendedAccessCookie }
						: {}),
				},
			};
		}),
		new RestLink({
			uri: `${process.env.REACT_APP_BE_CORE_API}/`,
			endpoints: {
				'lego-be-core': `https://${process.env.REACT_APP_BE_CORE_API}/`,
				auth0: `https://${process.env.REACT_APP_AUTH_DOMAIN}/`,
			},
		}),
		new HttpLink({
			uri: `${process.env.REACT_APP_BE_CORE_API}/graphql`,
		}),
	]),
});

export default apolloClient;

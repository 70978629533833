import React, { useCallback } from 'react';
import ModalRenderProps from 'src/modal/ModalRenderProps';
import styles from './Confirmation.module.scss';
import Information from './Information';

interface ButtonTextType {
	confirm: string;
	cancel: string;
}

/**
 * Props that can be passed to the `Confirmation` component.
 */
interface ConfirmationProps {
	/**
	 * Title of the confirmation
	 */
	title: string;

	/**
	 * Main body of the confirmation
	 */
	message: React.ReactNode;

	/**
	 * Call-to-action button text for "confirm" and "cancel" actions
	 */
	button?: ButtonTextType;

	/**
	 * Function to be called when the "confirm" action is clicked
	 */
	confirm?: () => void | Promise<void>;

	/**
	 * Function to be called when the "cancel" action is clicked
	 */
	cancel?: () => void;

	/**
	 * Styles to override
	 */
	styleOverrides?: Partial<typeof styles>;

	closeModal: ModalRenderProps['closeModal'];
}

/**
 * `Confirmation` component interface. This includes all possible overloads.
 */
interface ConfirmationComponent {
	(props: ConfirmationProps): JSX.Element;
}

const defaultButton: Readonly<ButtonTextType> = {
	confirm: 'Yes',
	cancel: 'No',
};

/**
 * @since 1.0.0
 * @summary Confirmation module used to display a message and
 * two CTA's, confirm and cancel.
 * @param {React.ReactNode} message
 * @param {object} button
 * @param {function} confirm
 * @param {function} cancel
 * @param {object} styleOverrides
 * @module Confirmation
 *
 * @todo We should figure out a better way to integrate this with modals. If a
 * modal is closed through the "X" button, this will circumvent the `onCancel`
 * logic which may not be desired. One solution would be to be able to wait on
 * the openModal call, something like `openModal().catch(onCancel)`.
 */
const Confirmation: ConfirmationComponent = ({
	title,
	message,
	confirm: onConfirm,
	cancel: onCancel,
	button,
	styleOverrides,
	closeModal,
}: ConfirmationProps) => {
	const styling: typeof styles = {
		...styles,
		...styleOverrides,
	};
	const { confirm: confirmText, cancel: cancelText } = button || defaultButton;

	const confirmAndClose = useCallback(async () => {
		await onConfirm?.();
		closeModal();
	}, [closeModal, onConfirm]);

	const cancelAndClose = useCallback(async () => {
		await onCancel?.();
		closeModal();
	}, [closeModal, onCancel]);

	return (
		<>
			<Information title={title} message={message} />
			<div className={styling['confirmation-cta']}>
				{cancelText && (
					<>
						<button
							className={styling['confirmation-cta-cancel']}
							onClick={cancelAndClose}
							type="button"
						>
							{cancelText}
						</button>{' '}
					</>
				)}
				<button
					className={styling['confirmation-cta-confirm']}
					onClick={confirmAndClose}
					type="submit"
					data-qa="confirm-action-modal"
				>
					{confirmText}
				</button>
			</div>
		</>
	);
};

export default Confirmation;

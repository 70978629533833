import { setLocale, TestOptions } from 'yup';

export { FieldInfo, FieldInfoError, FieldLabel } from './Field.style';
export { default } from './Field';
export { normalCharTest } from './normalCharTest';
export { nonEmojiFieldTest } from './nonEmojiFieldTest';
export { settingsEmailConfirmationTest } from './settingsEmailConfirmationTest';

/**
 * Props that every custom Field must implement. These props should be passed
 * down into the `<Field>` as well.
 */
export interface BaseFieldProps<T = unknown> {
	/**
	 * The name of the field. This is used as the property key for the form field
	 * values. It will also be used in error messages, unless `noun` is provided.
	 */
	name: string;

	/**
	 * This is used for error messages to describe the field. If not provided,
	 * error messages will use the `name` instead.
	 */
	noun?: string;

	/**
	 * The label of the field. This goes above the field and should be a
	 * human-understandable label.
	 */
	label: string;

	/**
	 * Whether or not this field must be provided in order to submit the form.
	 */
	required?: boolean;

	/**
	 * Custom (yup library-compatible) test or tests that will be run during validation.
	 * See: https://github.com/jquense/yup for more information on
	 * the mixed.test() methods provided by yup for details on how to implement tests.
	 */
	test?: TestOptions | [TestOptions];
}

/* eslint-disable no-template-curly-in-string */
/**
 * Sets localized strings for yup validation messages
 */
setLocale({
	mixed: {
		required: 'You must enter a ${label}',
		notType: "The ${label} isn't the correct type",
	},
	string: {
		length: 'The ${label} must be ${length} characters',
		min: 'The ${label} must be at least ${min} characters',
		max: 'The ${label} must be at most ${max} characters',
		email: 'The ${label} must be a valid email',
		url: 'The ${label} must be a valid URL',
		matches: 'The ${label} is not in the correct format',
	},
	number: {
		min: 'The ${label} must be at least ${min}',
		max: 'The ${label} must be at most ${max}',
	},
	array: {
		min: 'You must select at least ${min} ${label}',
		max: 'You must select no more than ${max} ${label}',
	},
});
/* eslint-enable no-template-curly-in-string */

import React, { useMemo } from 'react';
import { string } from 'yup';
import {
	isValid,
	format,
	parseISO,
	isBefore,
	isAfter,
	toDate as dateFnsToDate,
} from 'date-fns';

import { forArrayOrSingle } from 'src/common/utils';
import Field, { BaseFieldProps } from 'src/components/Field';
import { Input } from 'src/elements';

// After typescript upgrade:
// type Digit = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 0;
// type DateFormat = `${Digit}${Digit}${Digit}${Digit}-${Digit}${Digit}-${Digit}${Digit}`;
type DateFormat = string;

interface DateFieldProps extends BaseFieldProps<string> {
	/**
	 * The minimum date allowed.
	 */
	min?: DateFormat | Date;

	/**
	 * The maximum date allowed.
	 */
	max?: DateFormat | Date;

	/**
	 * Number of days to step when pressing arrow keys.
	 */
	step?: number;

	/**
	 * Placeholder text that will display when the field is empty
	 */
	placeholder?: string;
}

function toDate(from: string | number | Date) {
	if (typeof from === 'string') {
		return parseISO(from);
	} else {
		return dateFnsToDate(from);
	}
}

/**
 * A `Field` used for incremental numeric values. This uses `type=date`, and
 * will fall back to a text input.
 */
export default function DateField({
	name,
	noun = name,
	label,
	max,
	min,
	required,
	step,
	test,
	placeholder = 'yyyy-mm-dd',
}: DateFieldProps): JSX.Element {
	let schema = string()
		.label(noun)
		.matches(/^\d{4}-\d{2}-\d{2}$/, { excludeEmptyString: true })
		.test(
			'valid-date',
			({ label }) => `The ${label} is not a valid date`,
			value => !value || isValid(toDate(value))
		);
	if (typeof min !== 'undefined')
		schema = schema.test(
			'min-date',
			({ label }) =>
				`The ${label} must be on or after ${format(
					toDate(min),
					'MMMM do, yyyy'
				)}`,
			value => !value || !isBefore(parseISO(value), toDate(min))
		);
	if (typeof max !== 'undefined')
		schema = schema.test(
			'max-date',
			({ label }) =>
				`The ${label} must be on or before ${format(
					toDate(max),
					'MMMM do, yyyy'
				)}`,
			value => !value || !isAfter(parseISO(value), toDate(max))
		);

	if (required) schema = schema.required();
	forArrayOrSingle(test, t => {
		schema = schema.test(t);
	});

	const minString = useMemo(() => {
		if (min) {
			return format(toDate(min), 'yyyy-MM-dd');
		}
	}, [min]);

	const maxString = useMemo(() => {
		if (max) {
			return format(toDate(max), 'yyyy-MM-dd');
		}
	}, [max]);

	return (
		<Field
			name={name}
			noun={noun}
			label={label}
			required={required}
			schema={schema}
			render={({ field, lastError, error }) => (
				<Input
					{...field}
					type="date"
					min={minString}
					max={maxString}
					step={step}
					fixed={lastError && !error}
					invalid={!!error}
					pattern="^\d{4}-\d{2}-\d{2}$"
					placeholder={placeholder}
					// Temp hack to keep browsers from making this weird heights. We
					// should move to a custom date picker so we don't need this.
					style={{ height: '4.3rem' }}
				/>
			)}
		/>
	);
}

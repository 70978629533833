/**
 * Takes a single element or array of elements and calls a function on each
 * item.
 */
export function forArrayOrSingle<T>(
	arrayOrSingle: T | T[] | undefined,
	fn: (val: T) => unknown
): void {
	if (arrayOrSingle instanceof Array) {
		arrayOrSingle.forEach(item => fn(item));
	} else if (arrayOrSingle) {
		fn(arrayOrSingle);
	}
}

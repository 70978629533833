/**
 * Build a param to put in `<Route>` components' `path` property to match a
 * UUID. The optional `prop` param will determine
 *
 * @param paramName The param name to use, default `id`.
 * @example
 * <Route
 *   path={`/stuff/${uuidParam()}`}
 *   render={({ match: { params: { id } } }) => <>{id}</>}
 * />
 */
const uuidParam = (paramName = 'id'): string =>
	`:${paramName}([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})`;

export default uuidParam;

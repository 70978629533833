import React from 'react';
import loadable from '@loadable/component';
import PageErrorRoute from 'src/common/PageErrorRoute';
import useUpdateCreativePromptsTest from './useUpdateCreativePromptsTest';

const UpdateCreativePromptsPage = loadable(() =>
	import('../UpdateCreativePromptsPage')
);

const UpdateCreativePromptsPageRoute: React.FC = () => {
	return (
		<PageErrorRoute
			test={useUpdateCreativePromptsTest}
			exact
			path="/creative-prompts"
			render={() => <UpdateCreativePromptsPage />}
		/>
	);
};

export default UpdateCreativePromptsPageRoute;

import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0Client = new Auth0Client({
	domain: process.env.REACT_APP_AUTH_DOMAIN,
	client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
	audience: process.env.REACT_APP_AUTH_AUDIENCE,
	// We should investigate enabling this
	// useRefreshTokens: true,
});

export default auth0Client;

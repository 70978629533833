/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Determine if a variable is a promise. Something is a promise if it is
 * "then"-able, meaning it has a `.then()` function.
 */
const isPromise = <T extends any>(obj: any): obj is PromiseLike<T> =>
	!!obj &&
	(typeof obj === 'object' || typeof obj === 'function') &&
	typeof obj.then === 'function';

export default isPromise;

import { TestOptions } from 'yup';
import emojiRegex from 'emoji-regex';

/**
 * A test for fields to ensure emojis were not entered
 */
export const nonEmojiFieldTest: TestOptions = {
	// eslint-disable-next-line no-template-curly-in-string
	message: 'The ${label} must not contain emojis',
	name: 'non-emoji',
	test: (value: unknown) =>
		typeof value === 'string' && !emojiRegex().test(value),
};

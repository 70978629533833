import SelectFieldTemplate from './SelectField';

/**
 * A `Field` used for selecting a value from a list of values. This uses
 * `react-select` to implement the dropdown for consistent behavior with
 * MultiSelectField.
 */
const SelectField = SelectFieldTemplate;

export default SelectField;

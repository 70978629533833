import { useQueryParam, withDefault, NumberParam } from 'use-query-params';
import { useState } from 'react';

interface PagedRequest<T> {
	items: T[];
	pageSize: number;
}

interface PagedResult<T> {
	page: number;
	setPage: (page: number) => void;
	pagedItems: T[];
	pageCount: number;
}

interface CalculatePageRequest<T>
	extends PagedRequest<T>,
		Pick<PagedResult<T>, 'setPage'> {
	wantedPage: number;
}

const calculatePage = <T>({
	items,
	pageSize,
	wantedPage,
	setPage,
}: CalculatePageRequest<T>) => {
	const pageCount = Math.ceil(items.length / pageSize);
	const page = Math.max(1, Math.min(wantedPage, pageCount));
	const pagedItems = items.slice((page - 1) * pageSize, page * pageSize);

	return { page, pagedItems, pageCount, setPage };
};

interface PagedQueryParamRequest<T> extends PagedRequest<T> {
	param?: string;
}

/**
 * Helper for paging a list of known items. This won't be useful for async
 * integrating with queries. This will persist the current page to the URL
 * query.
 */
export const usePagedQueryParam = <T>({
	param = 'page',
	...props
}: PagedQueryParamRequest<T>): PagedResult<T> => {
	const [wantedPage, setPage] = useQueryParam(
		param,
		withDefault(NumberParam, 1)
	);
	return calculatePage({ ...props, wantedPage, setPage });
};

/**
 * Helper for paging a list of known items. This won't be useful for async
 * integrating with queries.
 */
export const usePaged = <T>(props: PagedRequest<T>): PagedResult<T> => {
	const [wantedPage, setPage] = useState(1);
	return calculatePage({ ...props, wantedPage, setPage });
};

import gql from 'graphql-tag';

const GetAuthAdditionalInfoInitialFormStateQuery = gql`
	query GetAuthAdditionalInfoInitialFormState(
		$sessionToken: String!
		$state: String!
	) {
		getAuthAdditionalInfoInitialFormState(
			sessionToken: $sessionToken
			state: $state
		)
			@rest(
				type: "AuthAdditionalInfoInitialFormState"
				path: "user/additional-info?sessionToken={args.sessionToken}&state={args.state}"
				method: "GET"
			) {
			userName
			emailAddress
			birthdate
			countryName
			countryNumericCode
		}
	}
`;

export default GetAuthAdditionalInfoInitialFormStateQuery;

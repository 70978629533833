import tw from 'tailwind.macro';

import styled from 'src/theme';

export const FieldInfoRemaining = styled.div<{ tooMany?: boolean }>`
	${tw`pb-2`}
	${({ tooMany }) =>
		tooMany &&
		tw`
		text-red
	`}
	& > svg {
		${tw`mr-1`}
	}
`;

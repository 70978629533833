import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import Image, { AVATAR_CDN_URL } from 'src/components/Image';
import styles from './UserAvatar.module.scss';

/**
 * Props that can be passed to the `UserAvatar` template component.
 */
interface UserAvatarTemplateProps {
	/**
	 * ImageUrl is determined based on if the response from AWS returns a 200
	 */
	imageUrl: string;

	/**
	 *  The additional classname for the container
	 */
	variant: 'small' | 'medium' | 'large' | 'full';

	/**
	 * This value determines the pending update status of the image
	 */
	updateInProgress?: boolean;

	/**
	 * Function to be passed in as <Image onLoad>
	 */
	onLoad?: ComponentPropsWithoutRef<typeof Image>['onLoad'];
}

/**
 * `UserAvatar` component interface. This includes all possible overloads.
 */
interface UserAvatarTemplateComponent {
	(props: UserAvatarTemplateProps): JSX.Element;
}

const UserAvatarTemplate: UserAvatarTemplateComponent = ({
	imageUrl,
	variant,
	updateInProgress,
	onLoad,
}) => (
	<div className={classNames(styles['avatar-image'], styles[variant])}>
		<Image
			src={imageUrl}
			alt=""
			aria-label="User Avatar"
			cdnUrl={AVATAR_CDN_URL}
			onLoad={onLoad}
			width={
				variant === 'small'
					? 42
					: variant === 'medium'
					? 75
					: variant === 'large'
					? 250
					: undefined
			}
			aspectRatio={1}
			className={classNames('', {
				[styles['update-in-progress']]: updateInProgress,
			})}
		/>

		{updateInProgress && (
			<div className={styles['loading']}>
				<div className={styles['loading-inner-content']}>
					<span>
						<FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
					</span>
					<span> Loading...</span>
				</div>
			</div>
		)}
	</div>
);

export default UserAvatarTemplate;

import React from 'react';
import { MixedSchema, object } from 'yup';
import { FormikHelpers } from 'formik';
import { forArrayOrSingle } from 'src/common/utils';
import Field, { BaseFieldProps } from '../Field';
import SelectInput from '../SelectInput';
import {
	SelectInputTemplateProps,
	SelectInputOption,
} from '../SelectInput/SelectInput';
import styles from './SelectField.module.scss';

/**
 * Props that can be passed to the `SelectField` template component.
 */
interface SelectFieldTemplateProps<TValue>
	extends Omit<
			SelectInputTemplateProps<TValue>,
			| 'value'
			| 'onChange'
			| 'invalid'
			| 'fixed'
			| 'menuIsOpen'
			| 'containerOpen'
			| 'name'
		>,
		BaseFieldProps<SelectInputOption<TValue>> {
	/**
	 * Info to render under the field.
	 */
	info?: React.ReactNode;

	/**
	 * The base yup schema.
	 */
	baseSchema?: MixedSchema<unknown>;

	/**
	 * Custom error message to show when no option selected
	 */
	requiredMessage?: string;

	/**
	 * Shows the field inline if there is no current value selected.
	 */
	inlineIfEmpty?: boolean;

	onChange?: (
		value: SelectInputOption<unknown>,
		helpers: FormikHelpers<Record<string, unknown>>
	) => void;
}

/**
 * `SelectField` component interface. This includes all possible overloads.
 */
interface SelectFieldTemplateComponent {
	<TValue>(props: SelectFieldTemplateProps<TValue>): JSX.Element;
}

const SelectFieldTemplate: SelectFieldTemplateComponent = ({
	name,
	noun = name,
	label,
	placeholder,
	required,
	// eslint-disable-next-line no-template-curly-in-string
	requiredMessage = 'You must choose a ${label}',
	test,
	info,
	defaultInputValue,
	defaultValue,
	onInputChange,
	getOptionLabel,
	getOptionValue,
	onChange,
	baseSchema,
	inlineIfEmpty,
	formatOptionLabel,
	...selectProps
}) => {
	let schema = (baseSchema || object()).label(noun);
	if (required) schema = schema.required(requiredMessage);
	forArrayOrSingle(test, t => {
		schema = schema.test(t);
	});

	return (
		<Field
			name={name}
			noun={noun}
			label={label}
			required={required}
			schema={schema}
			//@ts-ignore
			onChange={onChange}
			renderInfo={() =>
				info ? <div className={styles['info-note']}>{info}</div> : <></>
			}
			render={({ value, setValue, setTouched, error, lastError }) => (
				<SelectInput
					{...selectProps}
					name={name}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					value={getOptionValue ? getOptionValue(value) : (value as any)}
					placeholder={placeholder}
					onChange={value =>
						setValue(getOptionValue ? getOptionValue(value) : value)
					}
					onBlur={() => {
						// Without this, it may validate a previous value, probably due to
						// onBlur firing before or possibly just after onChange, and the
						// value not being saved in formik yet.
						setTimeout(() => setTouched(true, false));
					}}
					invalid={!!error}
					containerOpen={(!value && inlineIfEmpty) || undefined}
					menuIsOpen={(!value && inlineIfEmpty) || undefined}
					fixed={lastError && !error}
					defaultInputValue={defaultInputValue}
					defaultValue={defaultValue}
					onInputChange={value => onInputChange?.(value)}
					getOptionLabel={getOptionLabel}
					getOptionValue={getOptionValue}
					formatOptionLabel={formatOptionLabel}
				/>
			)}
		/>
	);
};

export default SelectFieldTemplate;

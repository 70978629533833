/**
 * Mapping of keyboard key codes
 * @todo fill in remainder of list as-needed
 */
export enum Key {
	ENTER = 13,
	ESC = 27,
	SPACE = 32,
	ARROW_LEFT = 37,
	ARROW_UP = 38,
	ARROW_RIGHT = 39,
	ARROW_DOWN = 40,
}

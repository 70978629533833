/**
 * Debounce a function by a certain amount of time. The returned function will
 * be call the `fn` function `time` milliseconds after the last call of the
 * returned function.
 * @example
 * const expensiveFn = () => { ... };
 * elem.onchange = debounce(250, expensiveFn);
 */
export default function debounce<Fn extends (...args: any) => any>(
	time: number,
	fn: Fn
): DebouncedFn<Fn> {
	let timeout: number;

	const debounced: DebouncedFn<Fn> = function(...args) {
		const functionCall = (): unknown => fn(...args);

		clearTimeout(timeout);
		timeout = setTimeout(functionCall, time);
	};
	debounced.cancel = () => {
		clearTimeout(timeout);
	};
	return debounced;
}

interface DebouncedFn<Fn extends (...args: any) => any> {
	(...args: Parameters<Fn>): void;
	cancel: () => void;
}

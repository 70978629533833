import gql from 'graphql-tag';

/**
 * Query to get a Single Sign-On token
 */

const GetSSOtokenQuery = gql`
	query GetSSOtoken($userId: UUID!) {
		cannyWebToken(userId: $userId)
	}
`;

export default GetSSOtokenQuery;

import { buildQueryHook } from 'src/common/graphql';
import {
	GetUserAvatar,
	GetUserAvatarVariables,
} from 'src/schema/generated/GetUserAvatar';
import GetUserAvatarQuery from './GetUserAvatarQuery';

/**
 * A hook to get a user's avatar image URL
 */
const useGetUserAvatar = buildQueryHook<GetUserAvatar, GetUserAvatarVariables>(
	GetUserAvatarQuery
);

export default useGetUserAvatar;

import React from 'react';

import styles from 'src/theme/buttons.module.scss';

type ButtonProps = JSX.IntrinsicElements['button'] & {
	white?: boolean;
	nav?: boolean;
	muted?: boolean;
	link?: boolean;
};

export const Button = React.forwardRef(
	({ white, muted, nav, link, ...props }: ButtonProps, ref): JSX.Element => {
		let className = props.className || '';
		if (nav) className += ` ${styles.nav}`;
		if (link) className += ` ${styles.link}`;
		else if (white) className += ` ${styles.white}`;
		else if (muted) className += ` ${styles.muted}`;
		else className += ` ${styles.normal}`;
		return React.createElement('button', { ...props, ref, className });
	}
);

import styled from 'src/theme';

export const Container = styled.div`
	box-sizing: border-box;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 28px;
	width: 740px; /* 684px + padding */
	@media (min-width: 768px) {
		width: 936px; /* 852px + padding */
		padding: 0 42px;
	}
	@media (min-width: 992px) {
		width: 1172px; /* 1032px + padding */
		padding: 0 70px;
	}
	@media (min-width: 1200px) {
		width: 1568px; /* 1400px + padding */
		padding: 0 84px;
	}
`;

import { Machine, State as XState } from 'xstate';

import { BaseEvent, MakeSchema } from 'src/common/xstate';

export type Schema = MakeSchema<{
	nonAdmin: {};
	admin: {};
}>;

export interface Context {}

export type Event = { type: 'GRANT_ROLE' | 'REVOKE_ROLE' } | BaseEvent;

export type State = XState<Context, Event>;

export default Machine<Context, Schema, Event>({
	initial: 'nonAdmin',
	states: {
		nonAdmin: {
			on: { GRANT_ROLE: 'admin' },
		},
		admin: {
			on: { REVOKE_ROLE: 'nonAdmin' },
		},
	},
});

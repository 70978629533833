import React from 'react';
import { Link } from 'react-router-dom';

import { NavButton } from 'src/elements';
import SectionLayout from 'src/components/SectionLayout';
import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
	subtitle: string;
	image: string;
	content: React.ReactNode;
	showHomeButton?: boolean;
}

interface ErrorPageComponent {
	(props: ErrorPageProps): JSX.Element;
}

export const ErrorPage: ErrorPageComponent = ({
	subtitle,
	image,
	content,
	showHomeButton,
}) => (
	<div className={styles['error-page']}>
		<SectionLayout type="HERO2">
			<div className={styles.wrapper}>
				<div className={styles['image-wrapper']}>
					<img src={image} alt="" />
				</div>

				<h3 className={styles.subtitle}>{subtitle}</h3>
				{content}

				{showHomeButton && (
					<Link component={NavButton} to="/" className={styles.button}>
						Back to Home
					</Link>
				)}
			</div>
		</SectionLayout>
	</div>
);

export default ErrorPage;

/**
 * Function to create a new object containing only specific keys from the source
 * object. It is the implementation of the Typescript `Pick<T, K>` type.
 * @param object Source object to grab values from
 * @param keys Keys to grab values for
 */
export default function pick<T, K extends keyof T>(
	object: T,
	...keys: K[]
): Pick<T, K> {
	return keys.reduce<Partial<Pick<T, K>>>(
		(partial, key) => ({
			...partial,
			[key]: object[key],
		}),
		{}
	) as Pick<T, K>;
}

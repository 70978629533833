import React, { useCallback, useState, useRef, Ref, useMemo } from 'react';
import { Field as FormikField, FormikHelpers, useFormikContext } from 'formik';
import { Schema, ValidationError } from 'yup';

import { FieldInfo, FieldInfoError, FieldLabel } from './Field.style';
import { BaseFieldProps } from './';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WithoutFirstArg<F extends (...args: any[]) => any> = F extends (
	arg0: infer H,
	...args: infer T
) => infer R
	? (...args: T) => R
	: never;

interface UnknownValues {
	[key: string]: unknown;
}

type Context = FormikHelpers<UnknownValues>;

/**
 * Props accessible to the `render` and `renderInfo` functions of the field
 * props.
 */
interface FieldRenderProps {
	/**
	 * Place this on a normal input to control & bind the field to the form
	 * without needing to call `setValue` or `setTouched`. Typed as `{}` to
	 * disourage the use of accessing this manually.
	 * ```ts
	 * <input {...field} />
	 * ```
	 */
	field: {}; // Formik::FieldProps

	/**
	 * The current value of the field
	 */
	value: unknown;

	/**
	 * A method to set the current value, with optional validation. This is useful
	 * for implementing custom fields.
	 */
	setValue: WithoutFirstArg<Context['setFieldValue']>;

	/**
	 * The current error, if set.
	 */
	error?: ValidationError;

	/**
	 * The previous error, if any. This is used to determine if broken fields have
	 * been fixed.
	 */
	lastError?: ValidationError;

	/**
	 * Whether or not the field has been touched and left. It will not be `true`
	 * until the user has unfocused the field.
	 */
	touched: boolean;

	/**
	 * A method to set the current `touched` state, with optional validation. This
	 * is useful for implementing custom fields.
	 */
	setTouched: WithoutFirstArg<Context['setFieldTouched']>;

	/**
	 * If `options` is provided, this is the current option value being rendered.
	 */
	optionValue?: string;

	/**
	 * If `options` is provided, this is the current option label being rendered.
	 */
	optionLabel?: string;
}

/**
 * Props to pass in to the `<Field>` component.
 */
interface FieldProps
	extends Pick<BaseFieldProps, 'name' | 'noun' | 'label' | 'required'> {
	/**
	 * The Yup validation schema that this field will be validated against. You
	 * must implement all validation logic, none will be added by this component.
	 * A simple example would be:
	 * ```ts
	 * let schema = yup.string(name)
	 * if (required) schema = schema.required();
	 * ```
	 */
	schema: Schema<unknown>;

	/**
	 * A key-value set of options for multiple-choice fields where each choice is
	 * rendered sequentially. The most common use case would be for a set of
	 * checkboxes or radio inputs. A single `select` would not use this value, as
	 * that still is a single component.
	 *
	 * The overall field label is not a `label` if this is provided because there
	 * are multiple inputs tied to a single field label.
	 */
	options?: Record<string, string>;

	/**
	 * If `true`, this will disable the wrapping `<label>` around the whole input.
	 */
	disableLabel?: boolean;

	/**
	 * A list of errors to not show as error messages. This is used if you're
	 * visually showing the user that the specific errors are present in some
	 * other way.
	 */
	muteErrors?: string[];

	/**
	 * The main render function to render the component. You can hook into a
	 * standard HTML element such as `<input>` by using `{...field }`, and can
	 * implement a custom component using the varioius `setX` methods.
	 */
	render: (props: FieldRenderProps) => React.ReactNode;

	/**
	 * The section below to render info for the component. This is useful for
	 * things like restrictions or other help text.
	 */
	renderInfo?: (props: FieldRenderProps) => React.ReactNode;

	/**
	 * Allows the entire info section to be hidden, whether or not there is any
	 * active message or error in it.
	 */
	hideInfo?: boolean;

	/**
	 * Used to call a function(s) or your choosing to set field values or whatever
	 * else you need to accomplish.
	 */
	onChange?: (
		value: unknown,
		helpers: Pick<Context, 'setFieldValue' | 'setStatus'>
	) => void;
}

/**
 * This uses a `yup` schema to validate a value asynchronously.
 * @param schema The schema to validate against
 * @param value The value to validate
 */
async function validate(
	schema: Schema<unknown>,
	value: unknown
): Promise<string | undefined> {
	try {
		await schema.validate(value);
	} catch (error) {
		return error;
	}
	return undefined;
}

/**
 * This generates all the props necessary to pass into the various render
 * functions that are passed into `Field`.
 * @param name The field name
 */
function useRenderProps(
	name: string,
	noun: string
): [FieldRenderProps, Ref<Element>] {
	const [lastError, setLastError] = useState<ValidationError>();
	const containerRef = useRef<Element>(null);

	const {
		setFieldValue,
		setFieldTouched,
		handleBlur: onBlur,
		handleChange,
		values: formikValues,
		touched: formikTouched,
		errors: formikErrors,
	} = useFormikContext<UnknownValues>();

	const touched = !!formikTouched[name];
	const formikError = formikErrors[name];
	const value = formikValues[name];
	const error = touched
		? ((formikError as unknown) as ValidationError)
		: undefined;
	const sendEvent = useCallback((): void => {
		containerRef.current?.dispatchEvent(
			new CustomEvent('fieldchange', {
				bubbles: true,
				detail: noun,
			})
		);
	}, [noun]);
	const setValue: FieldRenderProps['setValue'] = useCallback(
		(...args) => {
			setFieldValue(name, ...args);
			sendEvent();
		},
		[name, sendEvent, setFieldValue]
	);
	const onChange: typeof handleChange = useCallback(
		(fieldOrEvent: string | React.ChangeEvent) => {
			handleChange(fieldOrEvent);
			sendEvent();
		},
		[handleChange, sendEvent]
	);
	const setTouched = useMemo(() => setFieldTouched.bind(null, name), [
		name,
		setFieldTouched,
	]);

	if (lastError && !touched) {
		setLastError(undefined);
	}
	if (error && lastError !== error) {
		setLastError(error);
	}

	const renderProps = {
		value,
		setValue,
		touched,
		setTouched,
		field: {
			name,
			value,
			checked: typeof value === 'boolean' ? value : undefined,
			onBlur,
			onChange,
		},
		error,
		lastError,
	};

	return [renderProps, containerRef];
}

/**
 * Generic field component that allows you to render various field types within
 * it using a `render` prop.
 */
export default function Field({
	name,
	noun = name,
	label,
	schema,
	options,
	disableLabel,
	muteErrors,
	render,
	renderInfo,
	hideInfo = false,
	onChange,
}: FieldProps): JSX.Element {
	const [renderProps, containerRef] = useRenderProps(name, noun);
	const WrapperElement: keyof JSX.IntrinsicElements =
		options || disableLabel ? 'div' : 'label';
	const fieldValidator = useCallback(
		(value: unknown) => validate(schema, value),
		[schema]
	);

	/**
	 * TODO We are not entirely happy with this solution.  It seems that
	 * Formik is very restirctive in how it wants to be used.  This code
	 * was written to allow a feild from the form values to be updated
	 * programaticallly via the onChange() function below
	 * We overrode renderprops to get this to work.  It still gets called
	 * with value and shouldValidate via the original stored in originalSetValue
	 * But the override now calls our custom onChange() function
	 */
	const { setFieldValue, setStatus } = useFormikContext();
	const originalSetValue = renderProps?.setValue;
	renderProps.setValue = (value, shouldValidate) => {
		originalSetValue(value, shouldValidate);
		onChange?.(value, { setFieldValue, setStatus });
	};

	return (
		<>
			<WrapperElement
				ref={
					containerRef as React.Ref<HTMLDivElement> &
						React.Ref<HTMLLabelElement>
				}
			>
				{label && <FieldLabel>{label}</FieldLabel>}
				{options ? (
					Object.entries(options).map(([optionValue, optionLabel]) => (
						<FormikField
							name={name}
							key={optionValue}
							validate={fieldValidator}
						>
							{() =>
								render({
									...renderProps,
									optionValue,
									optionLabel,
								})
							}
						</FormikField>
					))
				) : (
					<FormikField
						name={name}
						onChange={() => {
							onChange?.(renderProps?.value, { setFieldValue, setStatus });
						}}
						validate={fieldValidator}
					>
						{() => render(renderProps)}
					</FormikField>
				)}
			</WrapperElement>
			{!hideInfo && (
				<FieldInfo>
					{renderInfo && renderInfo(renderProps)}
					<FieldInfoError data-qa={`${name}-error`}>
						{renderProps.error &&
							(!muteErrors || !muteErrors.includes(renderProps.error.type)) &&
							`* ${renderProps.error.message}`}
					</FieldInfoError>
				</FieldInfo>
			)}
		</>
	);
}

import tw from 'tailwind.macro';

import styled from 'src/theme';

/**
 * The main label above the field
 */
export const FieldLabel = styled.div`
	${tw`
		font-heading
		font-medium
		text-blue
		leading-none
		my-8
		uppercase
	`}
	font-size: 28px;
	letter-spacing: 1.17px;
`;

/**
 * A section below the field containing info about the field
 */
export const FieldInfo = styled.div`
	${tw`
		pt-3
		pb-4
		text-sm
		text-gray-dark
	`}
`;

/**
 * A section within the info section that contains field errors
 */
export const FieldInfoError = styled.div`
	min-height: 1.2em;
	${tw`
		text-red
		font-bold
		italic
	`}
`;

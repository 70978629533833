import logger from 'src/common/logger';
import { ContainerComponentType } from 'src/common/utils/withHooks';

interface DynamicConfirmationReturn {
	title: string;
	message: JSX.Element | ContainerComponentType<unknown> | string;
	button: {
		confirm: string;
		cancel: string;
	};
	confirm: () => void;
	cancel: () => void;
}

interface DynamicConfirmationProperties {
	title: string;
	message: JSX.Element | ContainerComponentType<unknown> | string;
	confirmationButtonText: string;
	cancellationButtonText: string;
	confirmationMethod: () => void;
	cancellationMethod?: () => void;
}
/**
 * DynamicConfirmationProps:
 * @since 0.1
 * @summary This is used to set the values for the props of the confirmation modal with dynamic content.
 * @param config {object} The properties needed for the confirmation modal body
 * @function DynamicConfirmationProps
 * @returns DynamicConfirmationProperties {object}
 * @example
 * DynamicConfirmationProps({
			message: 'Your message here',
			confirmationButtonText: 'Yes',
			cancellationButtonText: 'No',
			confirmationMethod: myCoolMethod,
			closeModal: closeModal, //Use the closeModal from useModal() hook
		})
 */
const DynamicConfirmationProps = (
	config: DynamicConfirmationProperties
): DynamicConfirmationReturn => {
	const {
		title,
		message,
		confirmationButtonText,
		cancellationButtonText,
		confirmationMethod,
		cancellationMethod,
	} = config;
	return {
		title: title,
		message: message,
		button: {
			confirm: confirmationButtonText,
			cancel: cancellationButtonText,
		},
		confirm: async () => {
			try {
				await confirmationMethod();
			} catch (err) {
				//TODO Use a real error message service like toastr
				logger.error('Confirmation modal error \n', err);
			}
		},
		cancel: () => {
			cancellationMethod?.();
		},
	};
};

export default DynamicConfirmationProps;

import { TestOptions, TestContext } from 'yup';
import SettingsFormValues from 'src/pages/Settings/settingsFormValues';

/**
 * Used on the Settings Page to compare the values between the
 * Email and Confrimation Email fields.
 */
export const settingsEmailConfirmationTest: TestOptions = {
	// eslint-disable-next-line no-template-curly-in-string
	message: 'The values for ${label} email must match',
	name: 'confirmation',
	test: function(this: TestContext, value: string) {
		return SettingsFormValues?.email === value;
	},
};

import React, {
	createContext,
	useState,
	ComponentProps,
	ComponentType,
	useCallback,
	useEffect,
} from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { useMachine } from 'src/hooks';

import stateMachine from './SideBar.state';
import sidebarStyles from './SideBar.module.scss';

interface SetSidebarChildContentInterface {
	<T extends ComponentType<any>>(component: T, props: ComponentProps<T>): void;
}

interface SideBarProviderPropsInterface {
	component: ComponentType<any>;
	props: any;
}

interface SidebarProps {
	<T extends ComponentType<any>>(props: ComponentProps<T>): void;
}

export interface ExternalSidebarProps {
	sidebarHeader: string;
	headerIcon: IconDefinition | undefined;
	sidebarSubheader: string | undefined;
}

export interface SidebarlProvderType {
	openSidebar: () => void;
	closeSidebar: () => void;
	sideBarIsOpen: boolean;
	setSidebarChildContent: SetSidebarChildContentInterface;
	getCurrentSidebarContent: () => SideBarProviderPropsInterface;
	sidebarHeader?: string;
	sidebarProps: ExternalSidebarProps;
	setSidebarProps: (
		headerIcon: IconDefinition,
		sidebarHeader: string,
		sidebarSubheader: string
	) => void;
}

const EmptyComponent: React.FC = () => <></>;

export const currentSidebarContent: SideBarProviderPropsInterface = {
	component: EmptyComponent,
	props: {},
};

/**
 * @summary Create the context with default values.
 */
export const SidebarContext = createContext<SidebarlProvderType>({
	openSidebar: () => {
		throw new Error('Attempted to call openSidebar with no SidebarProvider');
	},
	closeSidebar: () => {
		throw new Error('Attempted to call closeSidebar with no SidebarProvider');
	},
	sideBarIsOpen: false,
	setSidebarChildContent: () => {
		throw new Error(
			'Attempted to call setSidebarChildContent with no SidebarProvider'
		);
	},
	getCurrentSidebarContent: () => {
		throw new Error(
			'Attempted to call getCurrentSidebarContent with no SidebarProvider'
		);
	},
	sidebarHeader: '',
	sidebarProps: {
		headerIcon: faComment,
		sidebarHeader: '',
		sidebarSubheader: '',
	},
	setSidebarProps: (headerIcon, header, subHeader) => {},
});

/**
 * @summary This creates the provider from which the context
 * can be shared with the children
 * @param children {object}
 */
const SidebarProvider: React.FC = ({ children }) => {
	const [state, send] = useMachine(stateMachine);
	const history = useHistory();

	const [providerState, setState] = useState<SideBarProviderPropsInterface>(
		currentSidebarContent
	);
	const sideBarIsOpen = state.matches('sidebarOpen');

	const openSidebar = useCallback(() => {
		send({ type: 'SIDEBAR_OPEN' });
	}, [send]);

	const closeSidebar = useCallback(() => {
		send({ type: 'SIDEBAR_CLOSED' });
	}, [send]);

	const getCurrentSidebarContent = useCallback(() => providerState, [
		providerState,
	]);

	const setSidebarChildContent = useCallback<SetSidebarChildContentInterface>(
		(component, props) => {
			setState({
				props,
				component,
			});
		},
		[setState]
	);

	const [sidebarProps, setSidebarComponentProps] = useState<
		ExternalSidebarProps
	>({ headerIcon: faComment, sidebarHeader: '', sidebarSubheader: '' });
	const setSidebarProps = useCallback((icon, header, subHeader) => {
		setSidebarComponentProps({
			headerIcon: icon,
			sidebarHeader: header,
			sidebarSubheader: subHeader,
		});
	}, []);

	useEffect(() => {
		// This could be done in a more elegant way using refs and passing the
		// element directly, but this works so long as the only sidebar currently
		// mounted is the one we're intending to open. In many cases, this is
		// exactly what we're doing since we're consolidating all sidebars using
		// <SidebarDynamicContent>. If we mount only one of those per page, or at an
		// app-level, this will never be an issue.
		const elem = document.getElementsByClassName(
			// This should be whatever class has `overflow-y` scrollable.
			sidebarStyles['sidebar-child-content']
		)[0];
		if (elem && sideBarIsOpen) {
			disableBodyScroll(elem);
			return () => enableBodyScroll(elem);
		}
	}, [sideBarIsOpen, closeSidebar]);

	/**
	 * If the Sidebar is left open and the user leaves the page,
	 * close the sidebar and unsubscribe from the history listen event
	 */
	if (sideBarIsOpen) {
		const unlisten = history.listen(() => {
			closeSidebar();
			unlisten();
		});
	}

	/**
	 * Object used to pass the methods to the provider
	 */
	const sidebarContextProperties = {
		openSidebar,
		closeSidebar,
		sideBarIsOpen,
		setSidebarChildContent,
		getCurrentSidebarContent,
		sidebarProps,
		setSidebarProps,
	};

	return (
		<SidebarContext.Provider value={sidebarContextProperties}>
			{children}
		</SidebarContext.Provider>
	);
};

export default SidebarProvider;

import '@uppy/core/dist/style.css';
import { useEffect, useMemo } from 'react';
import AwsS3 from '@uppy/aws-s3';
import Uppy, {
	InternalMetadata,
	Plugin,
	UppyFile,
	UppyOptions,
} from '@uppy/core';

import logger from 'src/common/logger';
import authService from 'src/auth/authService';

import { UploadSubject } from './UploadSubject';

/**
 * Image dimension restrictions type.
 */
interface DimensionRestrictionOptions {
	minWidth: number;
	minHeight: number;
}
/**
 * Main Extended Uppy Options type. Used to send options to Uppy plugins.
 */
interface ExtUppyOptions {
	dimensionRestrictions?: DimensionRestrictionOptions;
}

/**
 * Type that intersects Uppy options with Extended uptions. Used to supply
 * options via useUploader hook.
 */
type UploaderOptionsExt = Partial<UppyOptions> & Partial<ExtUppyOptions>;

/**
 * Uppy File with additional metadata used to check if the file has been checked
 * for image dimensions already
 */
type ImageDimmensionCheckedFileMeta = InternalMetadata & {
	checkedForValidation?: boolean;
};

/**
 * An uppy plugin that, on file selection, verifies that:
 * 1. the file is a renderable image (based on browser abilities)
 * 2. the file complies with image dimension restrictions. This plugin first
 *    checks if the file has already been validated.  If not, then it
 *    immediately removes the file.  If the image complies with the dimension
 *    restrictions, the file is added back and marked as validated via its
 *    metadata.
 *
 * Required ExtUppyOptions properties: dimensionRestrictions:
 * DimensionRestrictionOptions
 */
class ImageDimensionValidator extends Plugin {
	private minDimensions: DimensionRestrictionOptions;

	private logContext = { uploader: 'ImageDimensionValidator' };

	public constructor(
		uppy: Uppy.Uppy,
		opts: Partial<UppyOptions> &
			Required<Pick<ExtUppyOptions, 'dimensionRestrictions'>>
	) {
		super(uppy, opts);
		this.id = 'CoverImageDimensionValidator';
		this.type = 'example';
		this.onFileAdded = this.onFileAdded.bind(this);
		this.minDimensions = opts.dimensionRestrictions;
	}

	public imageDimensionCheck(
		file: UppyFile,
		minDimensions: DimensionRestrictionOptions
	): void {
		logger.debug(`Validating image: ${file.name} size: ${file.size}`, {
			context: this.logContext,
		});

		const fileMeta: ImageDimmensionCheckedFileMeta = file.meta;

		if (!fileMeta.checkedForValidation) {
			this.uppy.removeFile(file.id);

			const fileReader = new FileReader();
			fileReader.readAsDataURL(file.data);
			fileReader.onload = () => {
				const image = new Image();
				image.onload = () => {
					logger.debug(`Image dimensions: ${image.width}w x${image.height}h`, {
						context: this.logContext,
					});
					if (
						image.width < minDimensions.minWidth ||
						image.height < minDimensions.minHeight
					) {
						this.uppy.info(`${file.name} not added. File must be at least \
						${minDimensions.minWidth}w x ${minDimensions.minHeight}h`);
					} else {
						this.uppy.addFile({
							...file,
							meta: {
								...file.meta,
								checkedForValidation: true,
							},
						});
					}
				};
				image.onerror = () => {
					this.uppy.info(`${file.name} is not an image.`);
				};
				image.src = fileReader.result as string;
			};
		}
	}

	public onFileAdded(file: UppyFile): void {
		logger.debug(`Handling file add: ${file.name}`, {
			context: this.logContext,
		});
		this.imageDimensionCheck.apply(this, [file, this.minDimensions]);
	}

	public install(): void {
		logger.debug('Registering for "file-added" Uppy event.', {
			context: this.logContext,
		});
		this.uppy.on('file-added', this.onFileAdded);
	}

	public uninstall(): void {
		logger.debug('Unregistering for "file-added" Uppy event.', {
			context: this.logContext,
		});
		this.uppy.off('file-added', this.onFileAdded);
	}
}

/**
 * log tags for useUploader. Extracted to allow usage in useMemo hooks.
 */
const uploaderLogContext = { uploader: 'Uppy' };

/**
 * React hook that configures and returns an `Uppy.Uppy` object.  This object
 * can then be passed into any number of Uppy React Components, such as the
 * Dashboard.
 *
 * UseUploader needs an `UploadSubject` and a set of `UppyOptions`. The
 * `UploadSubject` defines what exactly is being uploaded.  The `UppyOptions`
 * help fine grain what the Uploader allows the user to do
 *
 * See https://uppy.io/docs/uppy/#Options for more detailed info on the allowed
 * UppyOptions
 *
 * TODO: should convert to use currentUserContext. (still needs token from auth0
 * though)
 *
 * `useUploader` will reinstantiate multiple times unless it is called/wrapped
 * appropriately.  It is **highly** recommended useUploader be called from a
 * place where it won't change/cause a re-render.
 *
 * The uppy react docs specify that uppy should not be initialized in a render()
 * method or a functional component (since the body of a functional component is
 * a render method): https://uppy.io/docs/react/dashboard/#Initializing-Uppy
 *
 * It is also recommended to close our uppy instances when we're done with them.
 *
 * We can accomplish both by leveraging the `useRef` and `useEffect` hooks.
 *
 * We use `useRef` to instantiate an Uppy instance that will persist across
 * renders.  It holds a mutable memoized object in the `.current` variable of
 * the reference.  If the `.current` is ever updated, it will not trigger a
 * rerender.
 *
 * We use `useEffect` to close the instance when we're done with it.  The body
 * of a `useEffect` call can optionally return a function.  That function will
 * act as the cleanup method.
 *
 * More inormation about `useRef` and `useMemo` can be found here:
 * https://www.codebeast.dev/react-memoize-hooks-useRef-useCallback-useMemo/
 *
 * The official react docs for `useRef` and `useEffect` can be found here:
 * https://reactjs.org/docs/hooks-reference.html#basic-hooks
 *
 * Example recommended usage with proper instantiation and cleanup:
 * ```typescript
 * import '@uppy/dashboard/dist/style.css';
 * import FileUploader from 'src/components/FileUploader';
 * import { useUploader } from 'src/hooks';
 *
 * ...
 * ...
 * ...
 *
 * const miscUploader = useUploader('MISC', {
 *    id: 'static-unique-uploader-identifier',
 *    autoProceed: false,
 *    allowMultipleUploads: true,
 * });
 * const uppyRef = useRef(useUploader(UploadSubject.MISC, {
 *   id: 'static-unique-uploader-identifier',
 *   restrictions: {
 *     maxNumberOfFiles: 1,
 *   },
 *   autoProceed: false,
 *   allowMultipleUploads: true,
 *   dimensionRestrictions: {
 *     minWidth: 500,
 *     minHeight: 500,
 *   },
 * }));
 *
 * useEffect(() => (() => uppyRef.current.close()), [uppyRef]);
 *
 * ...
 * ...
 * ...
 *
 * <Dashboard uppy={miscUploader} />
 * ```
 */

const uppy = Uppy;

export function useUploader(
	subject: UploadSubject,
	options?: UploaderOptionsExt,
	events?: { onComplete: () => void }
): Uppy.Uppy {
	const uppyRef = useMemo(() => {
		logger.debug('Instantiating Uppy.', { context: uploaderLogContext });
		const uppyInstance = uppy(options)
			.use(AwsS3, {
				limit: 2,
				timeout: 60000,
				async getUploadParameters(file) {
					let token = null;
					try {
						token = await authService.getToken();
					} catch (error) {
						// todo: if we get a 'needs refresh' error, we should handle
					}

					const response = await fetch(
						`${process.env.REACT_APP_BE_CORE_API}/api/upload`,
						{
							method: 'post',
							headers: {
								accept: 'application/json',
								'content-type': 'application/json',
								authorization: token ? `Bearer ${token}` : '',
							},
							body: JSON.stringify({
								extension: file.extension,
								subject,
							}),
						}
					);
					const data = await response.json();
					return {
						method: data.method,
						url: data.url,
						fields: data.fields,
					};
				},
			})
			.on('upload-success', (file, data) => {
				logger.debug(data.uploadURL, { context: uploaderLogContext });
			})
			.on('complete', () => {
				events?.onComplete();
			});
		if (options && 'dimensionRestrictions' in options) {
			uppyInstance.use(ImageDimensionValidator, options);
		}
		return uppyInstance;
	}, [subject, options, events]);

	useEffect(
		() => () => {
			logger.debug('closing Uppy', { context: uploaderLogContext });
			uppyRef.close();
		},
		[uppyRef]
	);
	return uppyRef;
}

import getUniqueId from 'src/common/getUniqueId';
import Modal from './Modal';
import modalEventService from './modalEventService';
import ModalController from './ModalController';

export const activeModals: ModalController[] = [];

interface ModalService {
	openModal(modal: Modal): Promise<void>;
}

/**
 * Service to control all application modals. This service should be used in
 * tandem with a `<ModalRenderer>` which is responsible for actually rendering
 * the active modals. This service is responsible for creating & maintaining the
 * modal list.
 */
const modalService: ModalService = {
	openModal: modal =>
		new Promise(resolve => {
			const modalWrapper = {
				key: `modal-${getUniqueId()}`,
				modal,
				closeModal: () => {
					const index = activeModals.indexOf(modalWrapper);
					if (index >= 0) {
						activeModals.splice(index, 1);
						modalEventService.send('LIST_MODIFIED');
					}
					resolve();
				},
			};
			activeModals.push(modalWrapper);
			modalEventService.send('LIST_MODIFIED');
		}),
};

export default modalService;

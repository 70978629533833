import React from 'react';
import { StyleSheetManager } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import 'src/common/logger'; // force logger init before App
import 'src/tracking/trackingService'; // force tracking service init

import App from 'src/components/App';
import { CurrentUserContextProvider } from 'src/hooks';
import { theme, ThemeProvider } from 'src/theme';
import SideBarProvider from 'src/components/SideBar/SideBarProvider';
import FileUploaderProvider from 'src/components/FileUploader/FileUploaderProvider';
import 'src/theme/global.scss';
import { UseNavigationPromptProvider } from './hooks/useNavigationPrompt';
import routerHistory from './router/routerHistory';
import PrerenderIoAgent from './common/utils/prerenderIoAgent';
import apolloClient from './common/apolloClient';

library.add(faPaperPlane);

const { isBot } = PrerenderIoAgent;

const root = (
	<Router history={routerHistory}>
		<QueryParamProvider ReactRouterRoute={Route}>
			<UseNavigationPromptProvider>
				<ThemeProvider theme={theme}>
					<ApolloProvider client={apolloClient}>
						<CurrentUserContextProvider>
							<FileUploaderProvider>
								<SideBarProvider>
									<StyleSheetManager disableCSSOMInjection={isBot()}>
										<App />
									</StyleSheetManager>
								</SideBarProvider>
							</FileUploaderProvider>
						</CurrentUserContextProvider>
					</ApolloProvider>
				</ThemeProvider>
			</UseNavigationPromptProvider>
		</QueryParamProvider>
	</Router>
);

type OptimizeContainer = Record<string, boolean> & {
	start?: number;
	timeout?: number;
	end?: (() => void) | null;
};

/**
 * Adds a class to the <html> element that hides the page until the Optimize
 * container is loaded and the experiment is ready. Once Optimize is ready
 * (or 4 seconds has passed), react application will be rendered
 *
 * @param {number} timeOutMillis The max time (in milliseconds) the page will be hidden.
 * @param {Object} optimizeContainers An object whose keys are Optimize container IDs.
 */
const waitForOptimize = function(
	timeOutMillis: number,
	optimizeContainers: OptimizeContainer
) {
	// Creates a function and assigns it to a local variable `renderApp`. Once
	// Optimize is loaded it will call this function, which will Render the react
	// application
	const renderApp = () => {
		ReactDOM.render(root, document.getElementById('root'));
	};
	// Keeps track of the exact time that the snippet executes.
	optimizeContainers.start = new Date().getTime();

	// Assign the `renderApp` function to the `end` property of the Optimize container
	// object.  Once Optimize is loaded it will call this function, which will Render
	// the react application
	optimizeContainers.end = renderApp;

	// Initalizes the dataLayer as an empty array if it's not already initialized
	// and assigns the passed Optimize container object to the dataLayer's `hide`
	// property. This makes the function defined above accessible globally at the
	// path `window.dataLayer.hide.end`, so it can be called by Optimize.
	const stubbDataLayer = Object.assign([], {
		hide: {
			end: null,
		},
	});

	(window.dataLayer =
		window.dataLayer || stubbDataLayer).hide = optimizeContainers;

	// Creates a timeout that will call the page-showing function after the
	// timeout amount (defaulting to 4 seconds), in the event that Optimize has
	// not already loaded. This ensures your page will not stay hidden in the
	// event that Optimize takes too long to load.

	// If google_optimize is already loaded, then there's no need to delay anything
	// We can render the app immediately
	if (window.google_optimize) {
		renderApp();
		optimizeContainers.end = null;
	} else {
		// Check every 500 ms if google_optimize is ready.  If it is, we can render
		// the react app earlier than the max timeout of timeOutMillis
		const interval = setInterval(function() {
			if (window.google_optimize) {
				clearInterval(interval);

				renderApp();
				optimizeContainers.end = null;
			}
		}, 500);

		// Only wait for google_optimize to load for a maximum amount of time of
		// timeOutMillis.  At this point, render the app, even if google_optimize
		// has not yet loaded
		setTimeout(function() {
			clearInterval(interval);

			renderApp();
			optimizeContainers.end = null;
		}, timeOutMillis);

		optimizeContainers.timeout = timeOutMillis;
	}
};

const containers: OptimizeContainer = {};
containers[process.env.REACT_APP_OPTIMIZE_ID] = true;

waitForOptimize(4000, containers);

import React from 'react';

/**
 * Props that can be passed to the `CharacterLimitMessage` component.
 */
interface CharacterLimitMessageProps {
	/**
	 * the number of characters remaining before the CharacterLimitMessage should indicate
	 * an overage.
	 */
	remaining: number;
}

/**
 * `CharacterLimitMessage` component interface. This includes all possible overloads.
 */
interface CharacterLimitMessageComponent {
	(props: CharacterLimitMessageProps): JSX.Element;
}

/**
 * Displays a message relating to a character limit. Used on text fields to
 * show when users approach and exceed a field character limit.
 */
const CharacterLimitMessage: CharacterLimitMessageComponent = ({
	remaining,
}: CharacterLimitMessageProps) => {
	return remaining >= 0 ? (
		<>
			<strong>{remaining}</strong>
			<em> characters remaining</em>
		</>
	) : (
		<>
			<em>
				Oops! You have <strong>{Math.abs(remaining)} </strong>
				too many characters.
			</em>
		</>
	);
};

export default CharacterLimitMessage;

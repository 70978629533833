import React from 'react';
import { Props, components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleDown,
	faTimes,
	faCircleNotch,
} from '@fortawesome/pro-solid-svg-icons';

/**
 * Component to completely remove a select feature
 */
const Empty = (): JSX.Element => <></>;

/**
 * Props to be passed into a react-select `<Select>` component to style it,
 * adding custom colors, icons, etc.
 */
const selectInputStylingProps: Pick<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Props<any>,
	'classNamePrefix' | 'theme' | 'components'
> = {
	classNamePrefix: 'react-select',
	components: {
		IndicatorSeparator: Empty,
		DropdownIndicator: props => (
			<components.DropdownIndicator {...props}>
				<FontAwesomeIcon icon={faAngleDown} transform={{ size: 33 }} />
			</components.DropdownIndicator>
		),
		ClearIndicator: props => (
			<components.ClearIndicator {...props}>
				<FontAwesomeIcon icon={faTimes} transform={{ size: 25 }} />
			</components.ClearIndicator>
		),
		// It won't allow us to pass children of this like it does with the other
		// indicators, so we replace the component completely.
		LoadingIndicator: () => (
			<div className="react-select__indicator react-select__loading-indicator">
				<FontAwesomeIcon icon={faCircleNotch} spin transform={{ size: 20 }} />
			</div>
		),
		MultiValueRemove: props => (
			<components.MultiValueRemove {...props}>
				<FontAwesomeIcon icon={faTimes} />
			</components.MultiValueRemove>
		),
		Input: props => (
			<div className="react-select__Input">
				<components.Input {...props} />
			</div>
		),
	},
};

export default selectInputStylingProps;

import tw from 'tailwind.macro';

import { css, styled } from 'src/theme';

interface InputProps {
	type?: 'text' | 'number' | 'date';
	fixed?: boolean;
	invalid?: boolean;
}

export const InputBaseCss = css`
	transition: 150ms;
	box-sizing: border-box;
	${tw`
		block
		appearance-none
		border border-solid
		bg-white border-blue
		text-black
		p-6
		rounded
		outline-none
		w-full
	`}
	&:focus {
		${tw`
			border-blue
		`}
	}
`;

export const Input = styled.input<InputProps>`
	${InputBaseCss}
	${props =>
		props.fixed &&
		css`
			&,
			&:focus {
				${tw`border-green`}
			}
		`}
	${props =>
		props.invalid &&
		css`
			&,
			&:focus {
				${tw`border-red`}
			}
		`}
`;
